import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const EditorLogo = (props: IIcon) => {
  return (
    <Icon viewBox="0 0 32 32" width="16" height="16" fill="none" {...props}>
      <path
        d="M29.736,8.238c-0,-3.297 -2.677,-5.974 -5.974,-5.974l-15.524,0c-3.297,0 -5.974,2.677 -5.974,5.974l0,15.524c0,3.297 2.677,5.974 5.974,5.974l15.524,-0c3.297,-0 5.974,-2.677 5.974,-5.974l-0,-15.524Z"
        fill="none"
        stroke="#fff"
        strokeWidth={3}
      />
      <g>
        <path
          d="M5.952,26.358c-0.499,0.016 -0.783,-0.588 -0.439,-0.948c1.556,-1.628 2.646,-4.465 3.209,-6.332c0.809,-2.685 2.24,-5.039 6.719,-5.039l1.959,0.84l0.84,1.96c0,-0 0.56,0.56 0.56,2.449c-0,5.007 -5.513,6.84 -12.848,7.07Z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          d="M23.857,12.378l0.001,0.001l-5.618,4.46l-2.799,-2.8l4.46,-5.617l0.001,0.001l-0.352,0.443l3.863,3.863l0.444,-0.351Zm-3.955,-3.955l2.206,-2.782l4.53,4.53l-2.781,2.207l-3.955,-3.955Z"
          fill="#fff"
          fillOpacity={0.9}
        />
      </g>
    </Icon>
  );
};
