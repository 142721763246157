import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import emptyImage from '../../../../assets/img/coming-soon-books.webp';
import { BookCard } from '../../../../components/BookCard';
import { EmptyContentMessage } from '../../../../components/EmptyContentMessage';
import { Loader } from '../../../../components/Loader';
import { useAddToCart } from '../../../../hooks/useAddToCart';
import { useSaveToCollection } from '../../../../hooks/useSaveToCollection';
import { favoriteShowSuccessMessage, useSetFavorite } from '../../../../hooks/useSetFavorite';
import { ADD_TO_CART } from '../../../../models/ICart';
import { ITopProduct } from '../../../../models/IHome';
import { IProduct, PRODUCT_PAGE } from '../../../../models/IProducts';
import { FAVORITE, IResFavoriteWithStatus } from '../../../../models/IShared';
import { useGetTopProductsQuery } from '../../../../services/home';
import { changeObjByFavoriteSingleArray } from '../../../../utils/changeObjByFavorite';
import { PAGES_ENUM } from '../../../../utils/constants';
import { HomeSectionLayout } from '../HomeSectionLayout';
import styles from './Books.module.scss';
import { BooksCarousel } from './BooksCarousel';

export const Books = () => {
  const { t } = useTranslation();

  const {
    data: dataTopProducts = { results: [] },
    isLoading,
    isFetching,
  } = useGetTopProductsQuery('');
  const [data, setData] = useState(dataTopProducts.results);
  const isData = data?.length > 0;

  useEffect(() => {
    if (isFetching) return;
    setData(dataTopProducts.results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const { setProductFavorite: setFavorite, resProductFavorite } = useSetFavorite();
  const onFavorite = (product: IProduct) => async () => {
    const res = (await setFavorite({
      id: product.pk,
      body: { type: FAVORITE.PRODUCT, favorite: !product.is_favorite },
      typePage: PRODUCT_PAGE.PRODUCTS,
    })) as IResFavoriteWithStatus;

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      setData(pre => changeObjByFavoriteSingleArray({ pre, item: product }));
      favoriteShowSuccessMessage(!product.is_favorite, t);
    }
  };

  // Add to cart start

  const { addToCart } = useAddToCart({});
  const onAddToShoppingCart = (course: IProduct) => () =>
    addToCart({ id: course.pk, body: { type: ADD_TO_CART.PRODUCT } });

  // Add to cart end

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const onChangeStatusCollectionInData = (obj: { [key: string]: any }) => {
    const { id, isTrue } = obj;

    setData(pre =>
      pre.map((product: ITopProduct) =>
        product.pk === id ? { ...product, in_collection: isTrue } : product
      )
    );
  };

  const { onSaveToCollection } = useSaveToCollection({ func: onChangeStatusCollectionInData });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  if (isLoading) return <Loader />;

  return (
    <HomeSectionLayout
      title={t('home.books.title')}
      description={t('home.books.description')}
      buttonLink={isData ? PAGES_ENUM.PRODUCTS : ''}
      buttonText={t('home.books.buttonText')}
    >
      {isData ? (
        <>
          <BooksCarousel>
            {data?.map((product: ITopProduct) => (
              <BookCard
                onFavorite={onFavorite(product)}
                onAddToShoppingCart={onAddToShoppingCart(product)}
                onCollection={onSaveToCollection(product)}
                key={product.pk}
                card={product}
                link={`${PAGES_ENUM.PRODUCTS}/${product?.pk}`}
                isDisabled={resProductFavorite.isLoading}
              />
            ))}
          </BooksCarousel>
          <div className={styles.bookContentDesktop}>
            {data?.map((product: ITopProduct) => (
              <BookCard
                onFavorite={onFavorite(product)}
                onAddToShoppingCart={onAddToShoppingCart(product)}
                onCollection={onSaveToCollection(product)}
                key={product.pk}
                card={product}
                link={`${PAGES_ENUM.PRODUCTS}/${product?.pk}`}
                isDisabled={resProductFavorite.isLoading}
              />
            ))}
          </div>
        </>
      ) : (
        <EmptyContentMessage title={t('home.books.emptyContentMessage.title')} image={emptyImage} />
      )}
    </HomeSectionLayout>
  );
};
