import { createApi, fetchBaseQuery, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react';

import * as I from '../models/IAuth';

export const AUTH_API_REDUCER_KEY = 'authApi';

export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ['Auth'],
  endpoints: builder => ({
    login: builder.mutation<I.IAuthResponse, I.IAuthLoginBody>({
      query: body => ({
        url: '/user/login/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponse;
      },
    }),

    register: builder.mutation<I.IAuthResponse, I.IAuthRegisterBody>({
      query: body => ({
        url: '/user/register/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponse;
      },
    }),

    googleLogin: builder.mutation<I.IAuthResponse, I.IAuthGoogleLoginBody>({
      query: body => ({
        url: '/user/google-callback-login/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponse;
      },
    }),

    googleRegister: builder.mutation<I.IAuthResponse, I.IAuthGoogleRegisterBody>({
      query: body => ({
        url: '/user/google-callback-signup/',
        method: 'POST',
        body,
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as I.IAuthResponse;
      },
    }),

    logout: builder.query<unknown, unknown>({
      query: () => ({
        url: '/user/logout/',
        method: 'POST',
        headers: {
          authorization: `token ${localStorage.getItem('auth') || sessionStorage.getItem('auth')}`,
        },
      }),
      transformResponse: (data: object, meta: FetchBaseQueryMeta | undefined) => {
        return { ...data, status: meta?.response?.status } as { status: number };
      },
    }),

    sendResetCode: builder.mutation<I.ISendResetCodeResponse, I.ISendResetCodeBody>({
      query: body => ({
        url: '/user/send-reset-code/',
        method: 'POST',
        body,
      }),
    }),

    checkResetCode: builder.mutation<I.ICheckCodeResponse, I.ICheckCodeBody>({
      query: body => ({
        url: '/user/check-code/',
        method: 'POST',
        body,
      }),
    }),

    resetPassword: builder.mutation<I.IResetPasswordResponse, I.IResetPasswordBody>({
      query: body => ({
        url: '/user/reset-password/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useGoogleLoginMutation,
  useGoogleRegisterMutation,
  useLazyLogoutQuery,
  useSendResetCodeMutation,
  useCheckResetCodeMutation,
  useResetPasswordMutation,
} = authApi;
