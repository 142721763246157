import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components/Button';
import styles from './FormControlButtons.module.scss';

type Props = {
  isEditionMode: boolean;
  isEditButtonHidden?: boolean;
  onCancel: () => void;
  onEdit: () => void;
  className?: string;
  onDeleteAccountBtnClick?: () => void;
};

export const FormControlButtons: React.FC<Props> = ({
  isEditionMode,
  isEditButtonHidden = false,
  onCancel,
  onEdit,
  className,
  onDeleteAccountBtnClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.formButtons, className)}>
      {isEditionMode || isEditButtonHidden ? (
        <>
          <Button type="reset" className={styles.formCancelButton} isOrange onClick={onCancel}>
            {t('myProfile.cancelButtonText')}
          </Button>
          <Button type="submit" className={styles.formSaveButton}>
            {t('myProfile.saveButtonText')}
          </Button>
        </>
      ) : (
        <>
          <Button type="button" className={styles.formEditButton} onClick={onEdit}>
            {t('myProfile.editButtonText')}
          </Button>
          {typeof onDeleteAccountBtnClick === 'function' && (
            <Button
              type="button"
              className={styles.childFormDeleteBtn}
              onClick={onDeleteAccountBtnClick}
            >
              {t('deleteAccount.deleteChildBtnText')}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
