import * as I from '../models/IHome';
import { rootApi } from './rootApi';

export const homeApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getTopProducts: builder.query<I.IResponse<I.ITopProduct[]>, unknown>({
      query: () => '/shop/get-top-products/',
      keepUnusedDataFor: 0,
    }),

    getTopCourses: builder.query<I.IResponse<I.ITopCourse[]>, unknown>({
      query: () => '/courses/get-top-courses/',
      keepUnusedDataFor: 0,
    }),

    getHomeOurStaff: builder.query<I.IResponse<I.IHomeOurStaff[]>, unknown>({
      query: () => '/user/get-home-staff/',
      keepUnusedDataFor: 600,
    }),

    getSchoolFeatures: builder.query<I.ISchoolFeatures[], unknown>({
      query: () => '/settings-info/get-school-features/',
      keepUnusedDataFor: 600,
    }),

    getFeatures: builder.query<I.IFeatures[], unknown>({
      query: () => '/settings-info/get-features/',
      keepUnusedDataFor: 600,
    }),

    getHomePosts: builder.query<I.IResponse<I.ITopPost[]>, unknown>({
      query: () => '/blog/get-top-posts/',
      keepUnusedDataFor: 600,
    }),

    getHomeWebinars: builder.query<I.IResponse<I.ITopWebinar[]>, unknown>({
      query: () => '/blog/get-top-webinars/',
      keepUnusedDataFor: 600,
    }),

    getPageComments: builder.query<I.IHomePageComments[], unknown>({
      query: () => '/settings-info/get-main-page-comments/',
      keepUnusedDataFor: 600,
    }),

    getPartners: builder.query<I.IPartners[], unknown>({
      query: () => '/settings-info/get-partners/',
      keepUnusedDataFor: 600,
    }),

    getWelcomeText: builder.query<I.IWelcomeTextItem[], unknown>({
      query: () => '/settings-info/get-welcome-text/',
      keepUnusedDataFor: 600,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTopProductsQuery,
  useGetTopCoursesQuery,
  useGetHomeOurStaffQuery,
  useGetSchoolFeaturesQuery,
  useGetFeaturesQuery,
  useGetHomePostsQuery,
  useGetHomeWebinarsQuery,
  useGetPageCommentsQuery,
  useGetPartnersQuery,
  useGetWelcomeTextQuery,
} = homeApi;
