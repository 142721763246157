import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../utils/baseQuery';

export const rootApi = createApi({
  reducerPath: 'rootApi',
  tagTypes: [],
  baseQuery: baseQuery,
  endpoints: () => ({}),
});
