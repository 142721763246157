import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const ArrowIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="24" {...props}>
      <path
        d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"
        fill="rgba(113,113,113,1)"
      ></path>
    </Icon>
  );
};
