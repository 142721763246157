import cn from 'classnames';
import { FC } from 'react';

import styles from './Spinner.module.scss';

type Props = {
  className?: string;
};

export const Spinner: FC<Props> = ({ className }) => {
  return <div className={cn(styles.spinner, className)}></div>;
};
