import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const OpenedLockIcon = (props: IIcon) => {
  return (
    <Icon width="17" height="17" fill="none" {...props}>
      <g clipPath="url(#clip0_1522_340)">
        <path
          d="M13.4293 7.25823V4.83961C13.4293 2.48014 11.5097 0.560547 9.15025 0.560547C6.79075 0.560547 4.87119 2.48014 4.87119 4.83961H5.98747C5.98747 3.09564 7.40628 1.67683 9.15025 1.67683C10.8942 1.67683 12.3131 3.09564 12.3131 4.83961V7.2582H3.38281V16.5605H14.9177V7.25823H13.4293ZM13.8014 15.4443H4.49909V8.37452H13.8014L13.8014 15.4443Z"
          fill="black"
        />
        <path
          d="M9.70856 12.3499V10.4219H8.59228V12.3499C8.47684 12.481 8.40625 12.6525 8.40625 12.8405C8.40625 13.2508 8.74009 13.5847 9.15044 13.5847C9.56078 13.5847 9.89462 13.2508 9.89462 12.8405C9.89459 12.6525 9.82397 12.481 9.70856 12.3499Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1522_340">
          <rect width="16" height="16" fill="white" transform="translate(0.21875 0.560547)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
