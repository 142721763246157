import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const ShareIcon = (props: IIcon) => {
  return (
    <Icon width="25" height="29" {...props}>
      <path
        d="M20.4114 19.0987C19.0902 19.0987 17.8999 19.6692 17.0738 20.5768L9.64303 15.9746C9.84142 15.4667 9.95144 14.9151 9.95144 14.338C9.95144 13.7608 9.84142 13.2092 9.64303 12.7015L17.0738 8.0991C17.8999 9.00667 19.0902 9.57742 20.4114 9.57742C22.9001 9.57742 24.9248 7.55275 24.9248 5.064C24.9248 2.57524 22.9001 0.550781 20.4114 0.550781C17.9226 0.550781 15.898 2.57545 15.898 5.06421C15.898 5.64127 16.0082 6.19288 16.2064 6.70073L8.77586 11.3029C7.94971 10.3954 6.7594 9.82461 5.43823 9.82461C2.94947 9.82461 0.924805 11.8495 0.924805 14.338C0.924805 16.8268 2.94947 18.8515 5.43823 18.8515C6.7594 18.8515 7.94971 18.2809 8.77586 17.3731L16.2064 21.9753C16.0082 22.4832 15.898 23.0348 15.898 23.6121C15.898 26.1006 17.9226 28.1253 20.4114 28.1253C22.9001 28.1253 24.9248 26.1006 24.9248 23.6121C24.9248 21.1233 22.9001 19.0987 20.4114 19.0987ZM17.5437 5.06421C17.5437 3.48301 18.8302 2.19656 20.4114 2.19656C21.9926 2.19656 23.279 3.48301 23.279 5.06421C23.279 6.6454 21.9926 7.93185 20.4114 7.93185C18.8302 7.93185 17.5437 6.6454 17.5437 5.06421ZM5.43823 17.2057C3.85683 17.2057 2.57037 15.9192 2.57037 14.338C2.57037 12.7568 3.85683 11.4704 5.43823 11.4704C7.01942 11.4704 8.30567 12.7568 8.30567 14.338C8.30567 15.9192 7.01942 17.2057 5.43823 17.2057ZM17.5437 23.6119C17.5437 22.0307 18.8302 20.7442 20.4114 20.7442C21.9926 20.7442 23.279 22.0307 23.279 23.6119C23.279 25.1931 21.9926 26.4795 20.4114 26.4795C18.8302 26.4795 17.5437 25.1931 17.5437 23.6119Z"
        fill="#717171"
      />
    </Icon>
  );
};
