import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const UserIcon = (props: IIcon) => {
  return (
    <Icon width="14" height="17" fill="none" {...props}>
      <path
        d="M8.38203 8.26562H5.61644C2.89644 8.26562 0.683594 10.4785 0.683594 13.1985V16.4595H13.3149V13.1985C13.3149 10.4785 11.102 8.26562 8.38203 8.26562Z"
        fill="#00394f"
      />
      <path
        d="M7.11528 0.310547C5.01194 0.310547 3.30078 2.02173 3.30078 4.12507C3.30078 6.22838 5.01194 7.93957 7.11528 7.93957C9.21862 7.93957 10.9298 6.22838 10.9298 4.12504C10.9298 2.02173 9.21862 0.310547 7.11528 0.310547Z"
        fill="#00394f"
      />
    </Icon>
  );
};
