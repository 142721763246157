import cn from 'classnames';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { IMenuHeader } from '../../../../../models/IShared';
import styles from './MobileNavigationItem.module.scss';

type Props = {
  item: IMenuHeader;
  onCloseNavigation: () => void;
};

export const MobileNavigationItem: React.FC<Props> = ({ item, onCloseNavigation }) => {
  const [isExpanded, setisExpanded] = useState(false);
  const { name, children } = item;
  const hasChildren = children && children.length > 0;

  return (
    <li
      className={cn(
        styles.item,
        { [styles.arrow]: hasChildren },
        { [styles.arrowRotated]: isExpanded }
      )}
      onClick={() => setisExpanded(!isExpanded)}
      tabIndex={0}
    >
      {name}
      {isExpanded && hasChildren && (
        <ul className={styles.sublist}>
          {children.map((child: any) => (
            <li key={child.pk} onClick={onCloseNavigation}>
              <NavLink to={child.link || ''}>{child.name}</NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
