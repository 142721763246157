import cn from 'classnames';
import React from 'react';

import { MaxWidthContainer } from '../MaxWidthContainer';
import styles from './CoursesProductsLayout.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const CoursesProductsLayout: React.FC<Props> = ({ children, className }) => {
  return (
    <div className={cn(className, styles.coursesProductsLayout)}>
      <MaxWidthContainer>
        <div className={styles.coursesProductsContent}>{children}</div>
      </MaxWidthContainer>
    </div>
  );
};
