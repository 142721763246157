import React from 'react';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { CreateCollectionModal } from '../Modal/modals/CollectionModals/CreateCollectionModal';
import { DeleteCollectionMaterialModal } from '../Modal/modals/CollectionModals/DeleteCollectionMaterialModal';
import { DeleteCollectionModal } from '../Modal/modals/CollectionModals/DeleteCollectionModal';
import { EditCollectionModal } from '../Modal/modals/CollectionModals/EditCollectionModal';
import { SaveToCollectionsModal } from '../Modal/modals/CollectionModals/SaveToCollectionsModal';
import { CreateAccount } from '../Modal/modals/CreateAccount/CreateAccount';
import { CreateAccountChildModal } from '../Modal/modals/CreateAccountChildModal/CreateAccountChildModal';
import { ModalVideo } from '../Modal/modals/PostModalVideo/ModalVideo';
import { AddChildModal } from './modals/AddChildModal';
import { EditPublicCollectionModal } from './modals/CollectionModals/EditPublicCollectionModal';
import { DeleteAccountModal } from './modals/DeleteAccountModal';
import { ProductProgressWarningModal } from './modals/ProductProgressWarningModal';
import { SharePageModal } from './modals/SharePageModal/SharePageModal';
import { ShowCertificateModal } from './modals/ShowCertificateModal';
import { VideoLessonProgressWarningModal } from './modals/VideoLessonProgressWarningModal';
import { selectModal } from './ModalSlice';
import { ModalTypes } from './types/enums/ModalTypes';

const MODAL_COMPONENTS = {
  [ModalTypes.CREATE_ACCOUNT]: CreateAccount,
  [ModalTypes.CREATE_ACCOUNT_CHILD_MODAL]: CreateAccountChildModal,
  [ModalTypes.VIDEO]: ModalVideo,
  [ModalTypes.CREATE_COLLECTION]: CreateCollectionModal,
  [ModalTypes.DELETE_COLLECTION]: DeleteCollectionModal,
  [ModalTypes.DELETE_COLLECTION_MATERIAL]: DeleteCollectionMaterialModal,
  [ModalTypes.EDIT_COLLECTION]: EditCollectionModal,
  [ModalTypes.EDIT_PUBLIC_COLLECTION]: EditPublicCollectionModal,
  [ModalTypes.SAVE_TO_COLLECTIONS]: SaveToCollectionsModal,
  [ModalTypes.ADD_CHILD]: AddChildModal,
  [ModalTypes.SHOW_CERTIFICATE_MODAL]: ShowCertificateModal,
  [ModalTypes.SHARE_PAGE_MODAL]: SharePageModal,
  [ModalTypes.PRODUCT_PROGRESS_WARNING_MODAL]: ProductProgressWarningModal,
  [ModalTypes.VIDEO_LESSON_PROGRESS_WARNING_MODAL]: VideoLessonProgressWarningModal,
  [ModalTypes.DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
};

export const ModalsRoot: React.FC = () => {
  const { modalType, modalProps } = useTypedSelector(selectModal);

  if (!modalType) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[modalType];

  return <ModalComponent {...modalProps} />;
};
