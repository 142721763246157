import React from 'react';

import styles from './ProductImgSlider.module.scss';

type Props = {
  src: string;
  onClick: () => void;
  imageAlt: string;
};

export const ProductDetailImg: React.FC<Props> = ({ src, onClick, imageAlt }) => {
  return (
    <div className={styles.wrapperProductDetailImg} onClick={onClick}>
      <img src={src} className={styles.productDetailImg} alt={imageAlt} />
    </div>
  );
};
