import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const LogoutIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <g clipPath="url(#clip0_1211_645)">
        <path
          d="M1.91592 1.73828L0.366549 2.04223C0.160395 2.08265 0.0117188 2.26335 0.0117188 2.47343V14.1917C0.0117188 14.4018 0.160395 14.5825 0.366549 14.6229L1.91595 14.9269L1.91592 1.73828Z"
          fill="#00394f"
        />
        <path
          d="M7.89062 6.04673H9.82415V2.47264C9.82415 2.22995 9.6274 2.0332 9.38471 2.0332H7.89062V6.04673Z"
          fill="#00394f"
        />
        <path
          d="M7.89062 10.6172V14.6307H9.38471C9.6274 14.6307 9.82415 14.434 9.82415 14.1913V10.6172H7.89062Z"
          fill="#00394f"
        />
        <path
          d="M14.8697 8.02004L13.0922 6.2608C12.8147 5.98618 12.3436 6.18272 12.3436 6.57312V6.92616C11.9303 6.92616 8.3883 6.92616 7.89062 6.92616V9.73856H12.3436V10.0916C12.3436 10.482 12.8147 10.6785 13.0922 10.4039L14.8697 8.64468C15.0435 8.47272 15.0435 8.192 14.8697 8.02004Z"
          fill="#00394f"
        />
        <path
          d="M7.01156 1.27158C7.01156 0.995237 6.759 0.78715 6.48752 0.84038L2.79297 1.56516V15.0986C6.75033 15.8749 6.5094 15.8316 6.57213 15.8316C6.81426 15.8316 7.01156 15.6353 7.01156 15.3922C7.01156 13.7616 7.01156 2.70429 7.01156 1.27158ZM6.0741 8.8006C6.0741 9.04329 5.87735 9.24004 5.63466 9.24004C5.39197 9.24004 5.19522 9.04329 5.19522 8.8006V7.86314C5.19522 7.62045 5.39197 7.4237 5.63466 7.4237C5.87735 7.4237 6.0741 7.62045 6.0741 7.86314V8.8006Z"
          fill="#00394f"
        />
      </g>
      <defs>
        <clipPath id="clip0_1211_645">
          <rect width="15" height="15" fill="#00394f" transform="translate(0.0078125 0.832031)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
