import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const MoveToCollectionIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <g clipPath="url(#clip0_1522_47)">
        <path
          d="M12.0982 15.9893L11.2143 15.1054L13.5849 12.7349H3.75C1.68225 12.7349 0 11.0527 0 8.98491V7.29741H1.25V8.98491C1.25 10.3634 2.3715 11.4849 3.75 11.4849H13.5849L11.2143 9.11438L12.0982 8.23047L15.9776 12.1099L12.0982 15.9893ZM16 8.67241H14.75V7.01616C14.75 5.63766 13.6285 4.51616 12.25 4.51616H2.41512L4.78569 6.88672L3.90181 7.77059L0.022375 3.89116L3.90181 0.0117188L4.78569 0.895594L2.41512 3.26616H12.25C14.3177 3.26616 16 4.94841 16 7.01616V8.67241Z"
          fill="#717171"
        />
      </g>
      <defs>
        <clipPath id="clip0_1522_47">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
