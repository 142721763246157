import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const useCanonicalTags = () => {
  const location = useLocation();

  useEffect(() => {
    const currentDomain = window.location.origin;
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', `${currentDomain}${location.pathname}`);
    } else {
      const newCanonicalLink = document.createElement('link');

      newCanonicalLink.setAttribute('rel', 'canonical');
      newCanonicalLink.setAttribute('href', `${currentDomain}${location.pathname}`);
      document.head.appendChild(newCanonicalLink);
    }
  }, [location.pathname]);
};
