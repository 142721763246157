import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const BookIcon = (props: IIcon) => {
  return (
    <Icon width="13" height="17" fill="none" {...props}>
      <path
        d="M2.24219 13.4375H12.3984V0.5625H2.24219C1.12216 0.5625 0.210938 1.47372 0.210938 2.59375V14.5H0.211187C0.211031 14.5104 0.210938 14.5208 0.210938 14.5312C0.210938 15.6513 1.12216 16.5625 2.24219 16.5625H12.3984V15.625H2.24219C1.63909 15.625 1.14844 15.1343 1.14844 14.5312C1.14844 13.9282 1.63909 13.4375 2.24219 13.4375ZM3.33594 3.375H9.27344V6.1875H3.33594V3.375Z"
        fill="#00394f"
      />
    </Icon>
  );
};
