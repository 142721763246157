import cn from 'classnames';
import React from 'react';

import styles from './CoursesAndBooksSidebar.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const CoursesAndBooksSidebar: React.FC<Props> = ({ children, className }) => {
  return <div className={cn(styles.sidebar, className)}>{children}</div>;
};
