import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { useStartLessonMutation } from '../../../../services/videoLesson';
import { Button } from '../../../Button';
import { Modal } from '../../Modal';
import { deleteModal } from '../../ModalSlice';
import styles from '../ProductProgressWarningModal/ProductProgressWarningModal.module.scss';

type Props = {
  lessonId: string;
  token: string;
};

export const VideoLessonProgressWarningModal: React.FC<Props> = ({ lessonId, token }) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const [startLesson] = useStartLessonMutation();
  const editorLink = `${process.env.REACT_APP_EDITOR_HOST}?lessonId=${lessonId}&token=${token}`;

  const handleStartLessonBtnClick = async () => {
    try {
      const data = await startLesson(lessonId).unwrap();

      if (data?.status === true) {
        const linkElement = document.createElement('a');

        linkElement.setAttribute('target', '_blank');
        linkElement.setAttribute('rel', 'noopener noreferrer');
        linkElement.href = editorLink;
        linkElement.click();
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(deleteModal());
    }
  };

  const handleContinueButtonClick = () => {
    const linkElement = document.createElement('a');

    linkElement.setAttribute('target', '_blank');
    linkElement.setAttribute('rel', 'noopener noreferrer');
    linkElement.href = editorLink;
    linkElement.click();
    dispatch(deleteModal());
  };

  return (
    <Modal
      ariaLabel={t('videoLessonPage.lessonProgressWarningModal.modalAriaLabelText.title')}
      contentClassName={styles.progressModal}
    >
      <div className={styles.progressModalContent}>
        <div className={styles.progressModalTitleAndDescriptionWrapper}>
          <div className={styles.progressModalTitle}>
            {t('videoLessonPage.lessonProgressWarningModal.title')}
          </div>
          <p>{t('videoLessonPage.lessonProgressWarningModal.description')}</p>
        </div>
        <div className={styles.progressModalBtnsContainer}>
          <Button isBlue className={styles.progressModalBtn} onClick={handleStartLessonBtnClick}>
            {t('videoLessonPage.lessonProgressWarningModal.startAgainBtnText')}
          </Button>
          <Button onClick={handleContinueButtonClick} isOrange className={styles.progressModalBtn}>
            {t('videoLessonPage.continueTheTaskBtnText')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
