import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const CalendarIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <g>
        <path
          d="M15.6875 1.25H14.0625V1C14.0625 0.448594 13.6139 0 13.0625 0C12.5111 0 12.0625 0.448594 12.0625 1V1.25H10.6875V1C10.6875 0.448594 10.2389 0 9.6875 0C9.13609 0 8.6875 0.448594 8.6875 1V1.25H7.3125V1C7.3125 0.448594 6.86391 0 6.3125 0C5.76109 0 5.3125 0.448594 5.3125 1V1.25H3.9375V1C3.9375 0.448594 3.48891 0 2.9375 0C2.38609 0 1.9375 0.448594 1.9375 1V1.25H0.3125C0.139906 1.25 0 1.38991 0 1.5625V15.6875C0 15.8601 0.139906 16 0.3125 16H15.6875C15.8601 16 16 15.8601 16 15.6875V1.5625C16 1.38991 15.8601 1.25 15.6875 1.25ZM12.6875 1C12.6875 0.793219 12.8557 0.625 13.0625 0.625C13.2693 0.625 13.4375 0.793219 13.4375 1V2.375C13.4375 2.58178 13.2693 2.75 13.0625 2.75C12.8557 2.75 12.6875 2.58178 12.6875 2.375V1ZM9.3125 1C9.3125 0.793219 9.48072 0.625 9.6875 0.625C9.89428 0.625 10.0625 0.793219 10.0625 1V2.375C10.0625 2.58178 9.89428 2.75 9.6875 2.75C9.48072 2.75 9.3125 2.58178 9.3125 2.375V1ZM5.9375 1C5.9375 0.793219 6.10572 0.625 6.3125 0.625C6.51928 0.625 6.6875 0.793219 6.6875 1V2.375C6.6875 2.58178 6.51928 2.75 6.3125 2.75C6.10572 2.75 5.9375 2.58178 5.9375 2.375V1ZM2.5625 1C2.5625 0.793219 2.73072 0.625 2.9375 0.625C3.14428 0.625 3.3125 0.793219 3.3125 1V2.375C3.3125 2.58178 3.14428 2.75 2.9375 2.75C2.73072 2.75 2.5625 2.58178 2.5625 2.375V1ZM0.625 15.375V5.375H6.59375C6.76634 5.375 6.90625 5.23509 6.90625 5.0625C6.90625 4.88991 6.76634 4.75 6.59375 4.75H0.625V1.875H1.9375V2.375C1.9375 2.92641 2.38609 3.375 2.9375 3.375C3.48891 3.375 3.9375 2.92641 3.9375 2.375V1.875H5.3125V2.375C5.3125 2.92641 5.76109 3.375 6.3125 3.375C6.86391 3.375 7.3125 2.92641 7.3125 2.375V1.875H8.6875V2.375C8.6875 2.92641 9.13609 3.375 9.6875 3.375C10.2389 3.375 10.6875 2.92641 10.6875 2.375V1.875H12.0625V2.375C12.0625 2.92641 12.5111 3.375 13.0625 3.375C13.6139 3.375 14.0625 2.92641 14.0625 2.375V1.875H15.375V4.75H9.40625C9.23366 4.75 9.09375 4.88991 9.09375 5.0625C9.09375 5.23509 9.23366 5.375 9.40625 5.375H15.375V15.375H0.625Z"
          fill="#fff"
        />
        <path
          d="M13.9375 6.5H2.0625C1.88991 6.5 1.75 6.63991 1.75 6.8125V13.9375C1.75 14.1101 1.88991 14.25 2.0625 14.25H13.9375C14.1101 14.25 14.25 14.1101 14.25 13.9375V6.8125C14.25 6.63991 14.1101 6.5 13.9375 6.5ZM2.375 9.5H4.72272V11.25H2.375V9.5ZM5.34772 9.5H7.69147V11.25H5.34772V9.5ZM7.69147 8.875H5.34772V7.125H7.69147V8.875ZM8.31647 7.125H10.6602V8.875H8.31647V7.125ZM7.69147 11.875V13.625H5.34772V11.875H7.69147ZM8.31647 11.875H10.6602V13.625H8.31647V11.875ZM8.31647 11.25V9.5H10.6602V11.25H8.31647ZM11.2852 9.5H13.625V11.25H11.2852V9.5ZM13.625 8.875H11.2852V7.125H13.625V8.875ZM4.72272 7.125V8.875H2.375V7.125H4.72272ZM2.375 11.875H4.72272V13.625H2.375V11.875ZM11.2852 13.625V11.875H13.625V13.625H11.2852Z"
          fill="#fff"
        />
        <path
          d="M8 5.375C8.08219 5.375 8.16281 5.34156 8.22094 5.28344C8.27906 5.22531 8.3125 5.14469 8.3125 5.0625C8.3125 4.98031 8.27906 4.89969 8.22094 4.84156C8.16281 4.78344 8.08219 4.75 8 4.75C7.91781 4.75 7.83719 4.78344 7.77906 4.84156C7.72094 4.89969 7.6875 4.98031 7.6875 5.0625C7.6875 5.14469 7.72094 5.22531 7.77906 5.28344C7.83719 5.34156 7.91781 5.375 8 5.375Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_2249_203">
          <rect width="16" height="16" fill="#fff" />
        </clipPath>
      </defs>
    </Icon>
  );
};
