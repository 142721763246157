import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../Button';
import styles from './ReplyForm.module.scss';

type Props = {
  onCancel: () => void;
  userInitials: string;
  onSend?: () => void;
  onChangeText?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const ReplyForm: React.FC<Props> = ({ onCancel, userInitials, onSend, onChangeText }) => {
  const [review, setReview] = useState('');
  const { t } = useTranslation();

  const onWillSend = () => {
    onSend && onSend();
    setReview('');
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeText && onChangeText(e);
    setReview(e.target.value);
  };

  return (
    <div className={styles.replyFormContainer}>
      <form onSubmit={e => e.preventDefault()}>
        <label className={styles.replyFormLabel}>
          <div className={styles.replyFormTxt}>
            <div className={styles.replyFormAuthorImage}>{userInitials}</div>
            {t('comments.replyForm.writeComment')}:
          </div>
          <textarea className={styles.replyFormTextArea} onChange={onChange} value={review} />
        </label>
        <div className={styles.replyFormButtonsContainer}>
          <Button onClick={onWillSend} className={styles.replyFormSubmitBtn}>
            {t('comments.replyForm.send')}
          </Button>
          <Button onClick={onCancel} className={styles.replyFormCancelBtn} isOrange>
            {t('comments.replyForm.cancel')}
          </Button>
        </div>
      </form>
    </div>
  );
};
