import cn from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteIcon } from '../../../assets/svg/DeleteIcon';
import { EditIcon } from '../../../assets/svg/EditIcon';
import { LockIcon } from '../../../assets/svg/LockIcon';
import { MoveToCollectionIcon } from '../../../assets/svg/MoveToCollectionIcon';
import { setModal } from '../../../components/Modal/ModalSlice';
import { ModalTypes } from '../../../components/Modal/types/enums/ModalTypes';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useTypedDispatch } from '../../../hooks/useTypedDispatch';
import styles from './CollectionControlPanel.module.scss';

type Props = {
  className?: string;
  onClose: () => void;
  forwardedRef?: React.RefObject<HTMLElement>;
  collectionOpened?: boolean;
  itemId?: number;
  collectionId?: number;
  collectionName?: string;
  collectionDescription?: string;
  isCollectionItem?: boolean;
};

export const CollectionControlPanel: React.FC<Props> = ({
  forwardedRef,
  className,
  onClose,
  collectionOpened = false,
  collectionId,
  itemId,
  collectionName,
  collectionDescription,
  isCollectionItem = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const ref = useRef<HTMLDivElement | null>(null);

  const name = collectionName;
  const description = collectionDescription;

  const onMoveCollectionMaterial = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      setModal({
        modalType: ModalTypes.SAVE_TO_COLLECTIONS,
        modalProps: { id: itemId, typeModal: 'moveModal' },
      })
    );
  };

  const onDeleteCollectionMaterial = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      setModal({
        modalType: ModalTypes.DELETE_COLLECTION_MATERIAL,
        modalProps: { collectionId, itemId },
      })
    );
  };

  const onEditCollection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      setModal({
        modalType: ModalTypes.EDIT_COLLECTION,
        modalProps: { name, description, id: collectionId },
      })
    );
  };

  const onDeleteCollection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      setModal({ modalType: ModalTypes.DELETE_COLLECTION, modalProps: { id: collectionId } })
    );
  };

  const onOpenCollection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      setModal({
        modalType: ModalTypes.EDIT_PUBLIC_COLLECTION,
        modalProps: {
          id: collectionId,
          textSuccess: t('collections.modals.editPublicCollection.successNotificationText'),
          title: t('collections.modals.editPublicCollection.openTitle'),
          isPublic: true,
        },
      })
    );
  };

  const onCloseCollection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      setModal({
        modalType: ModalTypes.EDIT_PUBLIC_COLLECTION,
        modalProps: {
          id: collectionId,
          textSuccess: t('collections.modals.editPublicCollection.closeSuccessNotificationText'),
          title: t('collections.modals.editPublicCollection.closeTitle'),
          isPublic: false,
        },
      })
    );
  };

  useClickOutside(ref, forwardedRef, onClose);

  return (
    <div className={cn(styles.collectionControlPanel, className)} ref={ref}>
      <div className={styles.collectionControlPanelContainer}>
        {isCollectionItem ? (
          <>
            <button
              className={styles.collectionControlPanelItem}
              onClick={onMoveCollectionMaterial}
              aria-label="move"
            >
              <MoveToCollectionIcon />
              {t('collections.collectionManage.moveToCollectionText')}
            </button>
            <button
              className={styles.collectionControlPanelItem}
              onClick={onDeleteCollectionMaterial}
              aria-label="delete"
            >
              <DeleteIcon />
              {t('collections.collectionManage.removeFromCollectionText')}
            </button>
          </>
        ) : (
          <>
            <button
              className={styles.collectionControlPanelItem}
              onClick={onEditCollection}
              aria-label="edit"
            >
              <EditIcon />
              {t('collections.collectionManage.editCollectionText')}
            </button>
            <button
              className={styles.collectionControlPanelItem}
              onClick={onDeleteCollection}
              aria-label="delete"
            >
              <DeleteIcon />
              {t('collections.collectionManage.removeCollectionText')}
            </button>
            {collectionOpened ? (
              <button
                className={styles.collectionControlPanelItem}
                onClick={onCloseCollection}
                aria-label="lock"
              >
                <LockIcon />
                {t('collections.collectionManage.closeCollectionText')}
              </button>
            ) : (
              <button
                className={styles.collectionControlPanelItem}
                onClick={onOpenCollection}
                aria-label="unlock"
              >
                <LockIcon />
                {t('collections.collectionManage.openCollectionText')}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
