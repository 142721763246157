import cn from 'classnames';
import { useState } from 'react';

import { ISearchAges } from '../../../models/IShared';
import styles from './SearchPopup.module.scss';

interface ISelects {
  onSelect: (selected: number) => void;
  list: ISearchAges[];
  classNameItem?: string;
}

export const SelectsAges: React.FC<ISelects> = ({ onSelect, list, classNameItem }) => {
  const [selected, setSelected] = useState<number>();

  const clsn = (currSelected: number) =>
    cn(classNameItem, { [styles.active]: selected === currSelected });

  const onWillSelect = (currSelected: ISearchAges) => () => {
    setSelected(currSelected.id);
    onSelect(currSelected.id);
  };

  return (
    <div className={styles.ageList}>
      {list.map((item, idx) => (
        <div key={idx} className={clsn(item.id)} onClick={onWillSelect(item)}>
          {item.name}
        </div>
      ))}
    </div>
  );
};
