import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import defaultFacilityIcon from '../../../../../assets/img/facility-icon-1.png';
import { ISchoolFeatures } from '../../../../../models/IHome';
import styles from './FacilityCard.module.scss';

type Props = {
  card: ISchoolFeatures;
};

export const FacilityCard: React.FC<Props> = ({ card }) => {
  const { t } = useTranslation();
  const { id, background, icon, title, description } = card;
  const getCardClassNameById = (id: number) => {
    if (id === 1) return styles.facilityCardOne;
    if (id === 2) return styles.facilityCardTwo;

    return styles.facilityCardThree;
  };

  return (
    <div
      key={id}
      className={cn(styles.facilityCard, getCardClassNameById(id))}
      style={{ background: `url(${background}) center / contain no-repeat` }}
    >
      <div className={styles.facilityCardContent}>
        <div className={styles.facilityIcon}>
          <img src={icon || defaultFacilityIcon} alt={t('home.facility.iconAltText')} />
        </div>
        <h3 className={styles.facilityTitle}>{title}</h3>
        <p className={styles.facilityTxt}>{description}</p>
      </div>
    </div>
  );
};
