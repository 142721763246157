import { useEffect } from 'react';

import { setModal } from '../components/Modal/ModalSlice';
import { ModalTypes } from '../components/Modal/types/enums/ModalTypes';
import { ICourse } from '../models/ICourses';
import { IProduct, ISingleProduct } from '../models/IProducts';
import { RecommendationCourse, RecommendationProduct } from '../models/IShared';
import Emitter, { EMITTER_EVENTS } from '../utils/eventEmitter';
import { useTypedDispatch } from './useTypedDispatch';

type TItem = ICourse | IProduct | RecommendationCourse | RecommendationProduct | ISingleProduct;

interface IUseSaveToCollection {
  func: (obj: { [key: string]: any }) => void;
}

export const useSaveToCollection = ({ func }: IUseSaveToCollection) => {
  const dispatch = useTypedDispatch();

  useEmitter({ emitterEventsName: EMITTER_EVENTS.ADD_TO_COLLECTION_SUCCESS, func });

  const onSaveToCollection = (item: TItem) => () => {
    dispatch(
      setModal({
        modalType: ModalTypes.SAVE_TO_COLLECTIONS,
        modalProps: { id: item.pk, typeModal: 'saveModal' },
      })
    );
  };

  return { onSaveToCollection };
};

interface IUseEmitter {
  emitterEventsName: EMITTER_EVENTS.ADD_TO_COLLECTION_SUCCESS;
  func: (obj: { [key: string]: any }) => void;
}

const useEmitter = ({ emitterEventsName, func }: IUseEmitter) => {
  useEffect(() => {
    Emitter.on(emitterEventsName, value => {
      func(value);
    });

    return () => {
      Emitter.off(emitterEventsName);
    };
  }, []);
};

export default useEmitter;
