import { FacebookIcon } from '../../../../../../assets/svg/FacebookIcon';
import { InstagramIcon } from '../../../../../../assets/svg/InstagramIcon';
import { PinterestIcon } from '../../../../../../assets/svg/PinterestIcon';
import { TelegramIcon } from '../../../../../../assets/svg/TelegramIcon';
import { YoutubeIcon } from '../../../../../../assets/svg/YoutubeIcon';
import { MainFooterSocial } from './MainFooterSocial/MainFooterSocial';
import styles from './MainFooterSocials.module.scss';

const mainFooterSocials = [
  {
    id: 'telegram',
    link: 'https://t.me/dityMaybutnyogo',
    icon: TelegramIcon,
  },
  {
    id: 'facebook',
    link: 'https://www.facebook.com/ditymaybutnyogo/',
    icon: FacebookIcon,
  },
  {
    id: 'instagram',
    link: 'https://www.instagram.com/ditymaybutnyogo',
    icon: InstagramIcon,
  },
  {
    id: 'pinterest',
    link: 'https://www.pinterest.com/SkrinkaZnan/',
    icon: PinterestIcon,
  },
  {
    id: 'youtube',
    link: 'https://www.youtube.com/@ditymaybutnyogo',
    icon: YoutubeIcon,
  },
];

export const MainFooterSocials = () => {
  return (
    <div className={styles.mainFooterSocials}>
      {mainFooterSocials.map(social => {
        const { id, icon: Icon, link } = social;

        return (
          <MainFooterSocial key={id} icon={<Icon className={styles.socialIcon} />} link={link} />
        );
      })}
    </div>
  );
};
