import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const DeleteIcon = (props: IIcon) => {
  return (
    <Icon width="17" height="17" fill="none" {...props}>
      <path
        d="M14.4785 1.99219H11.4902V1.49414C11.4902 0.670272 10.82 0 9.99609 0H7.00781C6.18394 0 5.51367 0.670272 5.51367 1.49414V1.99219H2.52539C1.70152 1.99219 1.03125 2.66246 1.03125 3.48633V4.48242C1.03125 4.75748 1.25424 4.98047 1.5293 4.98047H2.06662L2.94275 15.6368C2.94285 15.6383 2.94299 15.6397 2.94312 15.6411C3.01358 16.4158 3.6533 17 4.43112 17H12.5728C13.3506 17 13.9903 16.4158 14.0608 15.6411C14.0609 15.6397 14.061 15.6383 14.0611 15.6368L14.9373 4.98047H15.4746C15.7497 4.98047 15.9727 4.75748 15.9727 4.48242V3.48633C15.9727 2.66246 15.3024 1.99219 14.4785 1.99219ZM6.50977 1.49414C6.50977 1.21952 6.73319 0.996094 7.00781 0.996094H9.99609C10.2707 0.996094 10.4941 1.21952 10.4941 1.49414V1.99219H6.50977V1.49414ZM13.0686 15.5526C13.0444 15.81 12.8315 16.0039 12.5728 16.0039H4.43112C4.1724 16.0039 3.9595 15.81 3.93526 15.5526L3.06607 4.98047H13.9378L13.0686 15.5526ZM14.9766 3.98438H14.4785H2.52539H2.02734V3.48633C2.02734 3.2117 2.25077 2.98828 2.52539 2.98828H6.01172H10.9922H14.4785C14.7531 2.98828 14.9766 3.2117 14.9766 3.48633V3.98438Z"
        fill="#717171"
      />
      <path
        d="M8.49805 5.97656C8.22299 5.97656 8 6.19955 8 6.47461V13.5137C8 13.7887 8.22299 14.0117 8.49805 14.0117C8.7731 14.0117 8.99609 13.7887 8.99609 13.5137V6.47461C8.99609 6.19955 8.7731 5.97656 8.49805 5.97656Z"
        fill="#717171"
      />
      <path
        d="M11.4902 5.97656C11.2152 5.97656 10.9922 6.19955 10.9922 6.47461V13.5137C10.9922 13.7887 11.2152 14.0117 11.4902 14.0117C11.7653 14.0117 11.9883 13.7887 11.9883 13.5137V6.47461C11.9883 6.19955 11.7653 5.97656 11.4902 5.97656Z"
        fill="#717171"
      />
      <path
        d="M5.51367 5.97656C5.23862 5.97656 5.01562 6.19955 5.01562 6.47461V13.5137C5.01562 13.7887 5.23862 14.0117 5.51367 14.0117C5.78873 14.0117 6.01172 13.7887 6.01172 13.5137V6.47461C6.01172 6.19955 5.78873 5.97656 5.51367 5.97656Z"
        fill="#717171"
      />
    </Icon>
  );
};
