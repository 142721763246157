import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const SearchIcon = (props: IIcon) => {
  return (
    <Icon width="30" height="30" fill="none" {...props}>
      <path
        d="M0.179988 11.7689C0.112193 18.2585 5.33673 23.5933 11.8263 23.6611C14.7658 23.6918 17.4684 22.6367 19.5518 20.8701L27.5508 29.037C27.8791 29.3723 28.417 29.3778 28.7523 29.0495C29.0875 28.7212 29.093 28.1833 28.7648 27.8481L20.7659 19.6812C22.5762 17.635 23.6878 14.9547 23.7185 12.0148C23.7863 5.52522 18.5618 0.190374 12.0722 0.122579C5.58263 0.0547839 0.247783 5.27932 0.179988 11.7689ZM22.0194 11.9971C21.9614 17.5498 17.3968 22.02 11.8441 21.962C6.29138 21.904 1.82111 17.3393 1.87911 11.7867C1.93712 6.23397 6.50178 1.7637 12.0545 1.8217C17.6072 1.87971 22.0774 6.44437 22.0194 11.9971Z"
        fill="white"
      />
    </Icon>
  );
};
