import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '../Dropdown';
import styles from './SortPanel.module.scss';

type Props = {
  className?: string;
  onSelect?: (selected: TSort) => void;
  selected?: TSort;
};

export enum SORT {
  FEATURED_COURSE = 1,
  POPULAR_COURSE = 2,
  RECENT_COURSE = 3,
  TRENDING_COURSE = 4,
  DEFAULT = '',
}

export type TSort =
  | SORT.FEATURED_COURSE
  | SORT.POPULAR_COURSE
  | SORT.RECENT_COURSE
  | SORT.TRENDING_COURSE
  | SORT.DEFAULT;

export const SortPanel: React.FC<Props> = ({ className, onSelect, selected }) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return [
      {
        text: t('sortingPanel.default'),
        value: SORT.DEFAULT,
      },
      {
        text: t('sortingPanel.featured'),
        value: SORT.FEATURED_COURSE,
      },
      {
        text: t('sortingPanel.popular'),
        value: SORT.POPULAR_COURSE,
      },
      {
        text: t('sortingPanel.recent'),
        value: SORT.RECENT_COURSE,
      },
      {
        text: t('sortingPanel.trending'),
        value: SORT.TRENDING_COURSE,
      },
    ];
  }, []);

  const lastSelectedOption = useMemo(() => {
    return options.find(item => {
      return item.value === +selected;
    });
  }, [selected, options]);

  const onChangeCustom = (value: TSort) => {
    onSelect && onSelect(value);
  };

  return (
    <div className={cn(styles.sortPanel, className)}>
      <p className={styles.sortPanelText}>{t('sortingPanel.title')}</p>
      <Dropdown data={options} onChange={onChangeCustom} selectedOption={lastSelectedOption} />
    </div>
  );
};
