import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const LoginIcon = (props: IIcon) => {
  return (
    <Icon width="17" height="16" fill="none" {...props}>
      <g clipPath="url(#clip0_1228_28)">
        <path
          d="M6.82422 0V4.21569L9.55434 6.94581L6.82422 9.67591V13.8916H10.1035V1.30062L14.0487 0L6.82422 0Z"
          fill="white"
        />
        <path
          d="M11.041 1.79934V16L16.499 14.2007V0L11.041 1.79934ZM13.1494 9.41334H12.2119V7.30494H13.1494V9.41334Z"
          fill="white"
        />
        <path
          d="M4.45039 3.16797V4.97003H0.498047V8.95344H4.45039V10.7223L8.22755 6.94516L4.45039 3.16797Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1228_28">
          <rect width="16" height="16" fill="white" transform="translate(0.498047)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
