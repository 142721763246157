export const enumToArray = <T>(enumObj: T): string[] => {
  return Object.values(enumObj);
};

export const getBackgroundAndBoxShadowByClassName = (className: string) => {
  switch (className) {
    case 'gradient-green':
      return {
        background: 'linear-gradient(145deg, #8ecf35, #23cc88)',
        boxShadow: '0px 10px 20px 0px rgba(35, 204, 136, 0.5)',
      };

    case 'gradient-blue':
      return {
        background: 'linear-gradient(145deg, #01cbad, #47a1f2)',
        boxShadow: '0px 10px 20px 0px rgba(71, 161, 242, 0.5)',
      };

    case 'gradient-orange':
      return {
        background: 'linear-gradient(145deg, #ff4f58, #ffb400)',
        boxShadow: '0px 10px 20px 0px rgba(255, 114, 58, 0.5)',
      };

    case 'gradient-pink':
      return {
        background: 'linear-gradient(145deg, #e85aca, #fc4e65)',
        boxShadow: '0px 10px 20px 0px rgba(232, 90, 202, 0.5)',
      };

    default:
      return {
        background: 'linear-gradient(145deg, #8ecf35, #23cc88)',
        boxShadow: '0px 10px 20px 0px rgba(35, 204, 136, 0.5)',
      };
  }
};
