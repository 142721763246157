import React from 'react';
import { Link } from 'react-router-dom';

import styles from './MainFooterListItem.module.scss';

type Props = {
  link: string;
  title: string;
  isExternal?: boolean;
};

export const MainFooterListItem: React.FC<Props> = ({ link, title, isExternal }) => {
  return (
    <li className={styles.mainFooterListItem}>
      {isExternal ? (
        <a href={link} className={styles.mainFooterLink} rel="noopener noreferrer" target="_blank">
          {title}
        </a>
      ) : (
        <Link to={link} className={styles.mainFooterLink}>
          {title}
        </Link>
      )}
    </li>
  );
};
