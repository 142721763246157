import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SingleInfo.module.scss';

type Props = {
  icon: React.ReactNode;
  title: string;
  infoLink?: string;
  infoText: string;
  isEmail?: boolean;
};

export const SingleInfo: React.FC<Props> = ({ icon: Icon, title, infoLink, infoText, isEmail }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.footerSingleInfoWrapper, 'col-xl-4 col-lg-4 col-md-12')}>
      <div className={styles.footerSingleInfo}>
        <div className={styles.footerInfoIconWrap}>{Icon}</div>
        <div className={styles.footerInfoTxtArea}>
          <p className={styles.footerInfoTitle}>{t(title)}</p>
          <span className={styles.footerInfoTxt}>
            {isEmail ? (
              <a className={styles.footerInfoLink} href={infoLink}>
                {infoText}
              </a>
            ) : (
              <a
                className={styles.footerInfoLink}
                href={infoLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                {infoText}
              </a>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
