import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { USER_TYPE } from '../../models/IAuth';
import { USER_ROLE } from '../../models/IShared';
import { RootState } from '..';

interface IUser {
  pk: number;
  username: string;
  profile_name: string;
  full_name: null | string;
  avatar: null | string;
}

export interface IAuthState {
  access_token: string | null;
  user_type: USER_TYPE | null;
  isRedirectToSignIn: boolean;
  isAuth: boolean;
  user: null | IUser;
  role: USER_ROLE | null;
  studentAge: null | number;
  homeStudying: boolean;
  refLink: string | null;
  isGoogleAuthenticate: boolean;
  accountAssociatedEmail: string | null;
  confirmationCode: string | null;
  childUsername: string | null;
}

const initialState: IAuthState = {
  access_token: null,
  user_type: null,
  isRedirectToSignIn: false,
  isAuth: false,
  user: null,
  role: null,
  studentAge: null,
  homeStudying: false,
  refLink: null,
  isGoogleAuthenticate: false,
  accountAssociatedEmail: null,
  confirmationCode: null,
  childUsername: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateToken(state, action: PayloadAction<string>) {
      state.access_token = action.payload;
    },

    setUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },

    updateUserData(state, action: PayloadAction<Partial<IUser>>) {
      state.user = { ...state.user, ...action.payload };
    },

    setUserRole(state, action: PayloadAction<USER_ROLE>) {
      state.role = action.payload;
    },

    setUserType(state, action: PayloadAction<USER_TYPE>) {
      state.user_type = action.payload;
    },

    setStudentAge(state, action: PayloadAction<number>) {
      state.studentAge = action.payload;
    },

    setHomeStudying(state, action: PayloadAction<boolean>) {
      state.homeStudying = action.payload;
    },

    setRefLink(state, action: PayloadAction<string>) {
      state.refLink = action.payload;
    },

    setRedirectToSignIn(state, action: PayloadAction<boolean>) {
      state.isRedirectToSignIn = action.payload;
    },

    setIsGoogleAuthenticate(state, action: PayloadAction<boolean>) {
      state.isGoogleAuthenticate = action.payload;
    },

    setAccountAssociatedEmail(state, action: PayloadAction<string>) {
      state.accountAssociatedEmail = action.payload;
    },

    setConfirmationCode(state, action: PayloadAction<string>) {
      state.confirmationCode = action.payload;
    },

    setChildUsername(state, action: PayloadAction<string>) {
      state.childUsername = action.payload;
    },

    clearStore(state) {
      state.access_token = null;
      state.isGoogleAuthenticate = false;
      state.user = null;
      state.user_type = null;
      state.studentAge = null;
      state.refLink = null;
      state.homeStudying = false;
      state.role = null;
    },
  },
});

export const selectUser = (state: RootState) => state.auth.user;
export const selectUserType = (state: RootState) => state.auth.user_type;
export const selectIsGoogleAuthenticate = (state: RootState) =>
  state.auth.isGoogleAuthenticate || JSON.parse(localStorage.getItem('googleAuth'));
export const selectIsAuth = (state: RootState) =>
  state.auth.access_token || localStorage.getItem('auth') || sessionStorage.getItem('auth');
export const selectIsRedirectToSignIn = (state: RootState) => state.auth.isRedirectToSignIn;
export const selectUserRole = (state: RootState) =>
  state.auth.role || localStorage.getItem('role') || sessionStorage.getItem('role');
export const selectStudentAge = (state: RootState) => state.auth.studentAge;
export const selectHomeStudying = (state: RootState) => state.auth.homeStudying;
export const selectRefLink = (state: RootState) => state.auth.refLink;
export const selectAccountAssociatedEmail = (state: RootState) => state.auth.accountAssociatedEmail;
export const selectConfirmationCode = (state: RootState) => state.auth.confirmationCode;
export const selectChildUsername = (state: RootState) => state.auth.childUsername;

export const authReducer = authSlice.reducer;

export const {
  updateToken,
  setUserType,
  setRedirectToSignIn,
  setUser,
  setUserRole,
  setStudentAge,
  setHomeStudying,
  setRefLink,
  setIsGoogleAuthenticate,
  clearStore,
  updateUserData,
  setAccountAssociatedEmail,
  setConfirmationCode,
  setChildUsername,
} = authSlice.actions;
