import { useTranslation } from 'react-i18next';

import emptyImage from '../../../../assets/img/coming-soon-webinars.webp';
import { EmptyContentMessage } from '../../../../components/EmptyContentMessage';
import { Loader } from '../../../../components/Loader';
import { ITopWebinar } from '../../../../models/IHome';
import { useGetHomeWebinarsQuery } from '../../../../services/home';
import { PAGES_ENUM } from '../../../../utils/constants';
import { HomeSectionLayout } from '../HomeSectionLayout';
import { OurWebinarCard } from './OurWebinarCard';
import styles from './OurWebinars.module.scss';
import { OurWebinarsCarousel } from './OurWebinarsCarousel/OurWebinarsCarousel';

export const OurWebinars = () => {
  const { data = { results: [] }, isLoading } = useGetHomeWebinarsQuery('');
  const isData = data.results.length > 0;
  const { t } = useTranslation();

  if (isLoading) return <Loader />;

  return (
    <HomeSectionLayout
      title={t('home.ourWebinars.title')}
      description={t('home.ourWebinars.description')}
      buttonLink={isData ? PAGES_ENUM.ONLINE_LESSONS : ''}
      buttonText={t('home.ourWebinars.buttonText')}
    >
      {isData ? (
        <>
          <OurWebinarsCarousel>
            {data.results.map((webinar: ITopWebinar) => (
              <OurWebinarCard key={webinar.pk} webinar={webinar} />
            ))}
          </OurWebinarsCarousel>

          <div className={styles.ourWebinarsContentDesktop}>
            {data.results.map((webinar: ITopWebinar) => (
              <OurWebinarCard key={webinar.pk} webinar={webinar} />
            ))}
          </div>
        </>
      ) : (
        <EmptyContentMessage
          title={t('home.ourWebinars.emptyContentMessage.title')}
          image={emptyImage}
        />
      )}
    </HomeSectionLayout>
  );
};
