import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorNode, handleError, handleSuccess } from '../utils/notifications';

export enum NOTIFICATION_TYPES {
  success,
  error,
}

interface IUseNotification {
  type: NOTIFICATION_TYPES.success | NOTIFICATION_TYPES.error;
  status: boolean;
  textSuccess?: string;
  error?: ErrorNode | null;
  afterCreate?: (...args: any[]) => void;
}

export const useNotification = ({
  type,
  status,
  textSuccess = 'Success',
  error,
  afterCreate,
}: IUseNotification) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!status) return;
    if (type === NOTIFICATION_TYPES.success) {
      handleSuccess(textSuccess);
      afterCreate?.();
    } else {
      const errorKey = error?.data?.error;
      let errorMessage: string;

      if (error?.status === 401) {
        handleError(t('errors.NOT_AUTHORIZED'));
      } else {
        errorMessage =
          t(`errors.${errorKey}`) !== `errors.${errorKey}`
            ? t(`errors.${errorKey}`)
            : t('errors.DEFAULT');
        handleError(errorMessage as string);
      }
      afterCreate?.();
    }
  }, [type, status, error]);
};
