import { Navigate, Outlet } from 'react-router-dom';

import { PAGES_ENUM } from '../utils/constants';

export const RequireAuth = () => {
  const authToken = localStorage.getItem('auth') || sessionStorage.getItem('auth');

  if (!authToken) {
    return <Navigate to={PAGES_ENUM.SIGN_IN} />;
  }

  return <Outlet />;
};
