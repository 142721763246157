import * as I from '../models/IMyProfile';
import { rootApi } from './rootApi';

export const myProfileApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getUserData: builder.query<I.IResUserData, unknown>({
      query: () => '/user/data/',
    }),

    updateUserData: builder.mutation<I.IResUpdateUserData, I.IReqUpdateUser>({
      query: body => ({
        url: `/user/update/`,
        method: 'PUT',
        body,
      }),
    }),

    createChild: builder.mutation<I.IResCreateChild, I.IReqCreateChild>({
      query: body => ({ url: `/user/register-child/`, method: 'POST', body }),
    }),

    getUserChildList: builder.query<I.IChild[], unknown>({
      query: () => '/user/children/',
    }),

    updateChild: builder.mutation<unknown, I.IReqUpdateChild>({
      query: ({ id, body }) => ({ url: `/user/update-children/${id}`, method: 'PUT', body }),
    }),

    updatePassword: builder.mutation<unknown, I.IReqUpdatePassword>({
      query: body => ({ url: `/user/change-password/`, method: 'POST', body }),
    }),

    sendDestroyLink: builder.mutation<I.ISendDestroyRes, I.ISendDestroyLinkBody>({
      query: body => ({
        url: '/user/send-destroy-link/',
        method: 'POST',
        body,
      }),
    }),

    destroyStudent: builder.mutation<unknown, string>({
      query: id => ({
        url: `/user/destroy-student/${id}`,
        method: 'DELETE',
      }),
    }),

    destroyParent: builder.mutation<unknown, string>({
      query: id => ({
        url: `/user/destroy-parent/${id}`,
        method: 'DELETE',
      }),
    }),

    destroyTeacher: builder.mutation<unknown, string>({
      query: id => ({
        url: `/user/destroy-teacher/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
  useGetUserChildListQuery,
  useUpdateChildMutation,
  useUpdatePasswordMutation,
  useCreateChildMutation,
  useSendDestroyLinkMutation,
  useDestroyStudentMutation,
  useDestroyParentMutation,
  useDestroyTeacherMutation,
} = myProfileApi;
