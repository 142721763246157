import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const PinterestIcon = (props: IIcon) => {
  return (
    <Icon width="13" height="18" fill="none" {...props}>
      <path
        d="M6.5 0C2.91588 0 0 3.02673 0 6.74712C0 7.19014 0.166036 7.60652 0.467811 7.91963C0.769453 8.23288 1.17072 8.40536 1.59751 8.40536C2.47823 8.40536 3.19477 7.66159 3.19503 6.74712C3.19503 5.83072 3.53874 4.96925 4.16293 4.32133C4.78712 3.67328 5.61717 3.3165 6.5 3.3165C8.32242 3.3165 9.80497 4.85541 9.80497 6.74712C9.80497 8.48694 8.55104 9.92848 6.93196 10.1488L7.52175 7.07108C7.6051 6.63657 7.52042 6.1945 7.28334 5.82619C7.04626 5.45787 6.68522 5.20738 6.26662 5.121C6.16356 5.09972 6.05838 5.089 5.954 5.089C5.19487 5.089 4.53641 5.65041 4.38824 6.42384L2.54981 16.0181C2.46659 16.4524 2.55126 16.8945 2.78821 17.2628C3.02529 17.6311 3.38633 17.8815 3.80493 17.9679C3.90799 17.9892 4.0133 18 4.11782 18C4.87695 18 5.53527 17.4385 5.68332 16.665L6.29083 13.4944H6.5C10.0841 13.4944 13 10.4675 13 6.74712C13 3.02673 10.0841 0 6.5 0Z"
        fill="white"
      />
    </Icon>
  );
};
