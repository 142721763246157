import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import emptyImage from '../../../../assets/img/coming-soon-courses.webp';
import { EmptyContentMessage } from '../../../../components/EmptyContentMessage';
import { Loader } from '../../../../components/Loader';
import { VideoCourseCard } from '../../../../components/VideoCourseCard';
import { useAddToCart } from '../../../../hooks/useAddToCart';
import { useSaveToCollection } from '../../../../hooks/useSaveToCollection';
import { favoriteShowSuccessMessage, useSetFavorite } from '../../../../hooks/useSetFavorite';
import { ADD_TO_CART } from '../../../../models/ICart';
import { COURSE_PAGE, ICourse } from '../../../../models/ICourses';
import { ITopCourse } from '../../../../models/IHome';
import { FAVORITE, IResFavoriteWithStatus } from '../../../../models/IShared';
import { useGetTopCoursesQuery } from '../../../../services/home';
import { changeObjByFavoriteSingleArray } from '../../../../utils/changeObjByFavorite';
import { PAGES_ENUM } from '../../../../utils/constants';
import { HomeSectionLayout } from '../HomeSectionLayout';
import styles from './OurCourses.module.scss';
import { OurCoursesCarousel } from './OurCoursesCarousel';

export const OurCourses = () => {
  const { t } = useTranslation();

  const {
    data: dataTopCourses = { results: [] },
    isFetching,
    isLoading,
  } = useGetTopCoursesQuery('');

  const [data, setData] = useState(dataTopCourses.results);
  const isData = data?.length > 0;

  useEffect(() => {
    if (isFetching) return;
    setData(dataTopCourses.results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const { setCourseFavorite } = useSetFavorite();
  const onFavorite = (course: ICourse) => async () => {
    const res = (await setCourseFavorite({
      id: course.pk,
      body: { type: FAVORITE.COURSE, favorite: !course.is_favorite },
      typePage: COURSE_PAGE.COURSES,
    })) as IResFavoriteWithStatus;

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      setData(pre => changeObjByFavoriteSingleArray({ pre, item: course }));
      favoriteShowSuccessMessage(!course.is_favorite, t);
    }
  };

  // Add to cart start

  const { addToCart } = useAddToCart({});
  const onAddToShoppingCart = (course: ICourse) => () =>
    addToCart({ id: course.pk, body: { type: ADD_TO_CART.COURSE } });

  // Add to cart end

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const onChangeStatusCollectionInData = (obj: { [key: string]: any }) => {
    const { id, isTrue } = obj;

    setData(pre =>
      pre.map((course: ITopCourse) =>
        course.pk === id ? { ...course, in_collection: isTrue } : course
      )
    );
  };

  const { onSaveToCollection } = useSaveToCollection({ func: onChangeStatusCollectionInData });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  if (isLoading) return <Loader />;

  return (
    <HomeSectionLayout
      title={t('home.ourCourses.title')}
      description={t('home.ourCourses.description')}
      buttonLink={isData ? PAGES_ENUM.COURSES : ''}
      buttonText={t('home.ourCourses.buttonText')}
    >
      {isData ? (
        <>
          <OurCoursesCarousel>
            {data?.map((course: ITopCourse) => (
              <VideoCourseCard
                key={course.pk}
                {...course}
                onFavorite={onFavorite(course)}
                is_favorite={course.is_favorite}
                onCollection={onSaveToCollection(course)}
                onAddToShoppingCart={onAddToShoppingCart(course)}
              />
            ))}
          </OurCoursesCarousel>

          <div className={styles.ourCoursesContentDesktop}>
            {data?.map((course: ITopCourse) => (
              <VideoCourseCard
                key={course.pk}
                {...course}
                onFavorite={onFavorite(course)}
                is_favorite={course.is_favorite}
                onCollection={onSaveToCollection(course)}
                onAddToShoppingCart={onAddToShoppingCart(course)}
              />
            ))}
          </div>
        </>
      ) : (
        <EmptyContentMessage
          title={t('home.ourCourses.emptyContentMessage.title')}
          image={emptyImage}
        />
      )}
    </HomeSectionLayout>
  );
};
