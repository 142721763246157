import { useState } from 'react';
import { useParams } from 'react-router';

import { REVIEW_PAGE } from '../models/IShared';
import { useCreatedReviewMutation, useGetReviewsQuery } from '../services/shared';
import { ErrorNode } from '../utils/notifications';
import { NOTIFICATION_TYPES, useNotification } from './useNotification';

export interface IUseComments {
  type: REVIEW_PAGE.BLOG | REVIEW_PAGE.COURSE | REVIEW_PAGE.PRODUCT | REVIEW_PAGE.LESSON;
}

export const useComments = ({ type }: IUseComments) => {
  const { id } = useParams();

  const { data: dataReviews = { count: 0, reviews: [] }, isFetching } = useGetReviewsQuery({
    id: id as string,
    type,
  });

  const onChangeReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => setReview(e.target.value);

  const [review, setReview] = useState('');

  const [createReview, resCreateReview] = useCreatedReviewMutation();

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resCreateReview.isSuccess,
    textSuccess: 'Ваш відгук надіслано на верифікацію',
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resCreateReview.isError,
    error: resCreateReview.error as ErrorNode,
  });

  const onCreateReview = (reviewId: string) => () => {
    // For comment other levels

    if (reviewId) {
      createReview({
        body: {
          text: review,
          content_type: type,
          object_id: id as string,
          parent: reviewId,
        },
      });

      return;
    }

    // For comment other levels

    // For comment first level

    createReview({
      body: {
        text: review,
        content_type: type,
        object_id: id as string,
      },
    });

    // For comment first level
    setReview('');
  };

  return { dataReviews, onChangeReview, onCreateReview, isFetching };
};
