import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import defPreview from '../../../../assets/img/class.png';
import { IPopularCourse } from '../../../../models/ICourses';
import { CourseMaterialInfo } from '../../../CourseMaterialInfo';
import styles from './PopularCourse.module.scss';

type Props = {
  course: IPopularCourse;
  link: string;
};

export const PopularCourse: React.FC<Props> = ({ course, link }) => {
  const { preview_popular, title, subject_title, ages, lessons_amount, practice_amount } = course;
  const { t } = useTranslation();

  return (
    <li className={styles.popularCourse}>
      <Link to={link} className={styles.popularCourseImageContainer}>
        <img
          className={styles.popularCourseImage}
          width="75"
          height="83"
          src={preview_popular || defPreview}
          alt={t('videoCourseCard.coursePreviewImageAltText')}
        />
      </Link>

      <div className={styles.popularCourseInfo}>
        <Link to={link} className={styles.popularCourseTitleContainer}>
          <h4 className={styles.popularCourseTitle}>{title}</h4>
        </Link>
        {(!!lessons_amount || !!practice_amount) && (
          <div className={styles.popularCourseMaterialInfo}>
            {!!lessons_amount && (
              <CourseMaterialInfo
                text={[
                  ` ${t('videoCourseCard.lessons')}`,
                  ` ${t('videoCourseCard.lessons2')}`,
                  ` ${t('videoCourseCard.lessons3')}`,
                ]}
                containerClassName={styles.popularCourseMaterialContainer}
                amount={lessons_amount}
                textClassName={styles.popularCourseMaterialText}
                circleClassName={styles.popularCourseMaterialCircle}
              />
            )}
            {!!practice_amount && (
              <CourseMaterialInfo
                text={[
                  ` ${t('videoCourseCard.practice')}`,
                  ` ${t('videoCourseCard.practice2')}`,
                  ` ${t('videoCourseCard.practice3')}`,
                ]}
                containerClassName={styles.popularCourseMaterialContainer}
                amount={practice_amount}
                textClassName={styles.popularCourseMaterialText}
                circleClassName={styles.popularCourseMaterialCircle}
              />
            )}
          </div>
        )}
        <div className={styles.popularCourseSubjectAndAgeInfo}>
          <div className={styles.popularCourseSubjectTitle}>
            {subject_title?.name}
          </div>
          <div className={styles.popularCourseAgeInfo}>
            {ages?.name}
          </div>
        </div>
      </div>
    </li>
  );
};
