import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { SearchIcon } from '../../assets/svg/SearchIcon';
import { useIsMounted } from '../../hooks/useIsMounted';
import { useSetSearchParamsNotSavedInHistory } from '../../hooks/useSetSearchParamsNotSavedInHistory';
import { useUrlParams } from '../../hooks/useUrlParams';
import styles from './SearchWidget.module.scss';

export const SearchWidget = () => {
  const { params } = useUrlParams();
  const [searchParams] = useSearchParams();
  const { setSearchParamsNotSavedInHistory } = useSetSearchParamsNotSavedInHistory();
  const initSearch = searchParams.get('search') || '';
  const [value, setValue] = useState(initSearch);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isMounted.current) return;
    const timeout = setTimeout(() => {
      setSearchParamsNotSavedInHistory({ ...params, search: value, page: '1' });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const isMounted = useIsMounted();

  useEffect(() => {
    setValue(initSearch);
  }, [initSearch]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;

    setValue(search);
  };

  return (
    <div className={styles.searchWidget}>
      <input
        className={styles.searchWidgetInput}
        name="search widget"
        placeholder={t('searchWidgetPlaceholderText')}
        onChange={onSearch}
        value={value}
      />
      <SearchIcon className={styles.searchWidgetIcon} />
    </div>
  );
};
