import pen from '../../assets/svg/loader-pen.svg';
import logo from '../../assets/svg/logo.svg';
import styles from './Loader.module.scss';

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.loaderContent}>
        <img className={styles.loaderLogo} src={logo} alt="Logo" />
        <div className={styles.loaderStroke}>
          <img className={styles.loaderPen} src={pen} alt="Pen" width="60" height="60" />
        </div>
      </div>
    </div>
  );
};
