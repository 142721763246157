import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const VideoLessonIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="14" fill="none" {...props}>
      <path
        d="M4.25 10.3125C4.50888 10.3125 4.71875 10.1026 4.71875 9.84375C4.71875 9.58487 4.50888 9.375 4.25 9.375C3.99112 9.375 3.78125 9.58487 3.78125 9.84375C3.78125 10.1026 3.99112 10.3125 4.25 10.3125Z"
        fill="#00394f"
      />
      <path d="M6.59375 4.03906V6.27206L8.82672 5.15556L6.59375 4.03906Z" fill="#00394f" />
      <path
        d="M14.5938 0H1.40625C0.630813 0 0 0.630813 0 1.40625V11.7188C0 12.4942 0.630813 13.125 1.40625 13.125H14.5938C15.3692 13.125 16 12.4942 16 11.7188V1.40625C16 0.630813 15.3692 0 14.5938 0ZM5.65625 3.28125C5.65625 3.11875 5.74047 2.96812 5.87872 2.88253C6.0165 2.79694 6.18862 2.79006 6.33466 2.86194L10.0847 4.73694C10.2435 4.81659 10.3438 4.97862 10.3438 5.15625C10.3438 5.33388 10.2435 5.49591 10.0847 5.57556L6.33466 7.45056C6.18669 7.52403 6.01434 7.51431 5.87872 7.42997C5.74047 7.34438 5.65625 7.19375 5.65625 7.03125V3.28125ZM13.625 10.3125H5.56991C5.37575 10.8569 4.86031 11.25 4.25 11.25C3.63969 11.25 3.12425 10.8569 2.93009 10.3125H2.375C2.11591 10.3125 1.90625 10.1028 1.90625 9.84375C1.90625 9.58466 2.11591 9.375 2.375 9.375H2.93009C3.12425 8.83056 3.63969 8.4375 4.25 8.4375C4.86031 8.4375 5.37575 8.83056 5.56991 9.375H13.625C13.8841 9.375 14.0938 9.58466 14.0938 9.84375C14.0938 10.1028 13.8841 10.3125 13.625 10.3125Z"
        fill="#00394f"
      />
    </Icon>
  );
};
