import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './LinkButton.module.scss';

type Props = {
  link?: string;
  isTargetBlank?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
  children: React.ReactNode;
  className?: string;
  to?: string;
  isBlue?: boolean;
  isOrange?: boolean;
  download?: boolean;
};

export const LinkButton: React.FC<Props> = ({
  link,
  isTargetBlank,
  onClick,
  ariaLabel,
  children,
  className,
  to = '',
  isBlue,
  isOrange,
  download,
}) => {
  const classes = cn(styles.LinkButton, { [styles.Blue]: isBlue }, { [styles.Orange]: isOrange });
  const target = isTargetBlank ? '_blank' : undefined;

  return link ? (
    <a
      href={link}
      rel={isTargetBlank ? 'noopener noreferrer' : undefined}
      target={target}
      onClick={onClick}
      aria-label={ariaLabel}
      className={cn(classes, className)}
      download={download}
    >
      {children}
    </a>
  ) : (
    <Link to={to} className={cn(classes, className)}>
      {children}
    </Link>
  );
};
