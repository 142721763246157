import cn from 'classnames';
import React, { useState } from 'react';

import { Carousel } from '../../../../../components/Carousel';
import { IHomeCarousel } from '../../../../../models/IHome';
import styles from './TestimonialsCarousel.module.scss';

export const TestimonialsCarousel: React.FC<IHomeCarousel> = ({ children }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    dots: true,
    arrows: false,
    vertical: true,
    verticalSwiping: false,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    infinite: true,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots: React.ReactNode) => <ul>{dots}</ul>,
    customPaging: (i: number) => (
      <div className={cn(styles.customDot, { [styles.customDotActive]: activeSlide === i })} />
    ),
  };

  return (
    <Carousel
      className={styles.testimonialsCarouselContainer}
      settings={settings}
      onlyActiveSlidesVisible={false}
    >
      {children}
    </Carousel>
  );
};
