import cn from 'classnames';
import { useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';

import { ArrowIcon } from '../../assets/svg/ArrowIcon';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useKeyDownListener } from '../../hooks/useKeyDownListener';
import styles from './Dropdown.module.scss';
import { IData, IDropdownList } from './IDropdownList';

export const Dropdown: React.FC<IDropdownList> = ({
  data,
  onChange,
  selectedOption,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(selectedOption || data?.[0]);
  const ref = useRef<HTMLDivElement | null>(null);

  const collapseDropdown = () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  };

  useClickOutside(ref, null, collapseDropdown);
  useKeyDownListener(collapseDropdown);

  if (!data?.length) return null;

  const onSelectedItemClick = () => {
    setIsExpanded(!isExpanded);
  };

  const onItemClick = (item: IData) => {
    setSelectedItem(item);
    setIsExpanded(!isExpanded);
    onChange(item.value);
  };

  const handleKeyPressOnSelectedItem = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSelectedItemClick();
    }
  };

  const handleKeyPressOnItem = (event: React.KeyboardEvent<HTMLDivElement>, item: IData) => {
    if (event.key === 'Enter') {
      onItemClick(item);
    }
  };

  return (
    <div className={cn(styles.dropdown, className)} ref={ref} aria-label="dropdown">
      <FocusLock autoFocus={true} disabled={!isExpanded}>
        <div
          className={styles.dropdownSelectedInstance}
          onClick={onSelectedItemClick}
          onKeyDown={handleKeyPressOnSelectedItem}
          tabIndex={0}
          role="button"
          aria-expanded={isExpanded}
          aria-haspopup="true"
          aria-controls="dropdown-items-container"
        >
          <div className={styles.dropdownSelectedItem}>{selectedItem.text}</div>
          <ArrowIcon
            className={cn(styles.dropdownIcon, { [styles.dropdownIconRotated]: isExpanded })}
          />
        </div>

        <div
          className={cn(styles.dropdownItemsContainer, { [styles.hidden]: !isExpanded })}
          role="menu"
          id="dropdown-items-container"
        >
          {data.map((item, index) => (
            <div
              key={index}
              className={cn(styles.dropdownItem, {
                [styles.dropdownItemSelected]: selectedItem.text === item.text,
              })}
              tabIndex={0}
              onClick={() => onItemClick(item)}
              onKeyDown={e => handleKeyPressOnItem(e, item)}
              role="button"
            >
              {item.text}
            </div>
          ))}
        </div>
      </FocusLock>
    </div>
  );
};
