import React from 'react';
import { Outlet } from 'react-router';

import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';

export const PageLayout: React.FC = () => (
  <React.Fragment>
    <Header />
    <Outlet />
    <Footer />
  </React.Fragment>
);
