/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import emptyImage from '../../assets/img/coming-soon-books.webp';
import { Banner } from '../../components/Banner';
import { BookCard } from '../../components/BookCard/BookCard';
import { Categories } from '../../components/Categories';
import { CoursesAndBooksSidebar } from '../../components/CoursesAndBooksSidebar';
import { CoursesProductsLayout } from '../../components/CoursesProductsLayout';
import { EmptyContentMessage } from '../../components/EmptyContentMessage';
import { GearButton } from '../../components/GearButton';
import { Loader } from '../../components/Loader';
import { Pagination } from '../../components/Pagination';
import { PopularProducts } from '../../components/Popular/PopularProducts';
import { PopularTags } from '../../components/PopularTags/PopularTags';
import { PriceRange } from '../../components/PriceRange';
import { SearchWidget } from '../../components/SearchWidget';
import { SidePanel } from '../../components/SidePanel';
import { SortPanel } from '../../components/SortPanel';
import { useAddToCart } from '../../hooks/useAddToCart';
import { useFilter } from '../../hooks/useFilter';
import { NOTIFICATION_TYPES, useNotification } from '../../hooks/useNotification';
import { usePagination } from '../../hooks/usePagination';
import { useSaveToCollection } from '../../hooks/useSaveToCollection';
import { favoriteShowSuccessMessage } from '../../hooks/useSetFavorite';
import { ADD_TO_CART } from '../../models/ICart';
import { IProduct, PRODUCT_PAGE } from '../../models/IProducts';
import { FAVORITE, IResFavoriteWithStatus } from '../../models/IShared';
import {
  useGetPopularProductsQuery,
  useGetProductsMinMaxPriceQuery,
  useGetProductsQuery,
  useSetProductsFavoriteMutation,
} from '../../services/products';
import { useGetPopularTagsQuery } from '../../services/shared';
import { changeObjByFavoriteSingleArray } from '../../utils/changeObjByFavorite';
import { PAGES_ENUM } from '../../utils/constants';
import { ErrorNode } from '../../utils/notifications';
import styles from './Products.module.scss';

export const Products = () => {
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(false);
  const { t } = useTranslation();

  const [setFavorite, resFavorite] = useSetProductsFavoriteMutation();

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resFavorite.isError,
    error: resFavorite.error as ErrorNode,
  });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Filter start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { filter, filterData } = useFilter();
  const { data: productsMinMaxPrice } = useGetProductsMinMaxPriceQuery('');

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Filter end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Pagination start<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { page, onPageChange } = usePagination();

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Pagination end<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const {
    data: dataProducts = { results: [], count: 0, num_pages: 1 },
    isFetching,
    isLoading,
  } = useGetProductsQuery({ ...filterData.params, page }, { refetchOnMountOrArgChange: true });

  const [data, setData] = useState(dataProducts.results);

  useEffect(() => {
    if (isFetching) return;
    setData(dataProducts.results);
  }, [isFetching]);

  const { data: dataPopularProducts = { results: [] } } = useGetPopularProductsQuery('');

  const { data: dataPopularTags = { results: [] } } = useGetPopularTagsQuery('');

  const handlePanelClose = () => {
    setIsSidePanelVisible(false);
  };

  const onFavorite = (product: IProduct) => async () => {
    const res = (await setFavorite({
      id: product.pk,
      body: { type: FAVORITE.PRODUCT, favorite: !product.is_favorite },
      typePage: PRODUCT_PAGE.PRODUCTS,
    })) as IResFavoriteWithStatus;

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      setData(pre => changeObjByFavoriteSingleArray({ pre, item: product }));
      favoriteShowSuccessMessage(!product.is_favorite, t);
    }
  };

  // Add to cart start

  const { addToCart } = useAddToCart({});
  const onAddToShoppingCart = (course: IProduct) => () =>
    addToCart({ id: course.pk, body: { type: ADD_TO_CART.PRODUCT } });

  // Add to cart end

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const onChangeStatusCollectionInData = (obj: { [key: string]: any }) => {
    const { id, isTrue } = obj;

    setData(pre =>
      pre.map(product => (product.pk === id ? { ...product, in_collection: isTrue } : product))
    );
  };

  const { onSaveToCollection } = useSaveToCollection({ func: onChangeStatusCollectionInData });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const searchWidgetComponent = <SearchWidget />;

  const priceRangeComponent = (
    <PriceRange
      min={productsMinMaxPrice?.min_price}
      max={productsMinMaxPrice?.max_price}
      currMinPrice={filterData?.prices[0]}
      currMaxPrice={filterData?.prices[1]}
      onChangePrice={filter.onChangePrice}
    />
  );

  const popularTagsComponent = (
    <PopularTags className={styles.productsTags} tags={dataPopularTags?.results} />
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <Banner title={t('products.bannerTitle')} />
      <GearButton className={styles.gearButtonHidden} onClick={() => setIsSidePanelVisible(true)} />

      <SidePanel onClose={handlePanelClose} isVisible={isSidePanelVisible}>
        {searchWidgetComponent}
        <Categories filter={filter} filterData={filterData} />
        {priceRangeComponent}
        {popularTagsComponent}
      </SidePanel>
      <CoursesProductsLayout>
        <CoursesAndBooksSidebar>
          <div className={styles.productsSearchWidgetContainer}>{searchWidgetComponent}</div>
          <div className={styles.productsCategoriesContainer}>
            <Categories filter={filter} filterData={filterData} />
          </div>
          <div className={styles.priceRangeContainer}>{priceRangeComponent}</div>
          <div className={styles.popularTagsContainer}>{popularTagsComponent}</div>
          <PopularProducts data={dataPopularProducts?.results} />
        </CoursesAndBooksSidebar>
        <div className={styles.productsCardsContainer}>
          {data?.length > 0 ? (
            <>
              <SortPanel onSelect={filter.onChangeSort} selected={filterData.filter} />
              <div className={styles.productsCards}>
                {data?.map(product => (
                  <BookCard
                    onFavorite={onFavorite(product)}
                    onAddToShoppingCart={onAddToShoppingCart(product)}
                    onCollection={onSaveToCollection(product)}
                    key={product.pk}
                    card={product}
                    link={`${PAGES_ENUM.PRODUCTS}/${product.pk}`}
                    isDisabled={resFavorite.isLoading}
                  />
                ))}
              </div>
              <Pagination
                pageCount={dataProducts.num_pages}
                onPageChange={onPageChange}
                initialPage={page - 1}
              />
            </>
          ) : (
            <EmptyContentMessage
              title={t('products.emptyContentMessage.title')}
              image={emptyImage}
            />
          )}
        </div>
      </CoursesProductsLayout>
    </>
  );
};
