import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const PurchaseHistoryIcon = (props: IIcon) => {
  return (
    <Icon width="15" height="16" fill="none" {...props}>
      <path
        d="M11.9851 7.99941C11.9851 5.53135 9.97325 3.52344 7.50037 3.52344C5.02749 3.52344 3.01562 5.53135 3.01562 7.99941C3.01562 10.4675 5.02746 12.4754 7.50034 12.4754C9.97322 12.4754 11.9851 10.4675 11.9851 7.99941ZM8.83624 9.33267C8.74453 9.4242 8.62433 9.46998 8.50416 9.46998C8.38399 9.46998 8.26375 9.42423 8.17208 9.33267L7.16829 8.33084C7.08021 8.24294 7.03071 8.12372 7.03071 7.99941V5.99578C7.03071 5.73691 7.24099 5.52703 7.50037 5.52703C7.75975 5.52703 7.97003 5.73691 7.97003 5.99578V7.80522L8.83624 8.66977C9.01965 8.85283 9.01965 9.14961 8.83624 9.33267Z"
        fill="#00394f"
      />
      <path
        d="M14.2919 4.83823C13.4639 3.06591 12.0074 1.71277 10.1823 1.01512L10.4432 0.893688C10.6783 0.784283 10.78 0.505381 10.6704 0.270759C10.5607 0.0361372 10.2812 -0.0653927 10.0462 0.0440433L8.75966 0.642785C8.64678 0.695316 8.5594 0.790471 8.51678 0.907282C8.4742 1.02409 8.4798 1.15303 8.53247 1.26571L9.13241 2.54976C9.2121 2.72032 9.38149 2.82051 9.55836 2.82051C9.6248 2.82051 9.69234 2.80635 9.75656 2.77647C9.99164 2.66707 10.0933 2.38817 9.98372 2.15355L9.86398 1.89727C13.1802 3.17681 14.8809 6.88873 13.6595 10.2382C12.8659 12.4143 11.0308 13.9793 8.75067 14.4245C8.49612 14.4742 8.33011 14.7204 8.37992 14.9745C8.42376 15.1982 8.62023 15.3534 8.84032 15.3534C8.87022 15.3534 8.90056 15.3505 8.93099 15.3446C10.1769 15.1013 11.3493 14.5424 12.3216 13.7284C13.3242 12.8889 14.0921 11.7929 14.5422 10.5588C15.227 8.68093 15.1381 6.64933 14.2919 4.83823Z"
        fill="#00394f"
      />
      <path
        d="M5.8658 13.4501C5.75619 13.2154 5.47674 13.1139 5.24163 13.2234C5.00655 13.3328 4.90485 13.6117 5.01447 13.8463L5.1342 14.1026C1.81797 12.823 0.11727 9.11107 1.33873 5.76168C2.13236 3.58549 3.96741 2.02051 6.24754 1.57533C6.5021 1.52564 6.66811 1.2794 6.61829 1.02531C6.56851 0.771217 6.32181 0.605625 6.06719 0.655281C4.82131 0.898528 3.64889 1.45739 2.67657 2.27145C1.67394 3.1109 0.906078 4.20692 0.456019 5.44103C-0.228775 7.31888 -0.139884 9.35051 0.706285 11.1616C1.53433 12.9339 2.99083 14.287 4.81596 14.9847L4.55505 15.1061C4.31994 15.2156 4.21824 15.4945 4.32786 15.7291C4.40754 15.8996 4.57693 15.9999 4.75381 15.9998C4.82025 15.9998 4.88779 15.9857 4.95197 15.9558L6.23853 15.357C6.3514 15.3045 6.43879 15.2094 6.4814 15.0926C6.52398 14.9757 6.51838 14.8468 6.46571 14.7341L5.8658 13.4501Z"
        fill="#00394f"
      />
    </Icon>
  );
};
