import { useTranslation } from 'react-i18next';

import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { selectHomeStudying, setHomeStudying } from '../../../../redux/Auth';
import { Modal } from '../../Modal';
import { deleteModal } from '../../ModalSlice';
import styles from './CreateAccountChildModal.module.scss';
import { Selects } from './Selects';

export const CreateAccountChildModal = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const isStudyingAtHome = useTypedSelector(selectHomeStudying);

  const handleHomeStudyingClick = () => {
    if (isStudyingAtHome === true) {
      dispatch(setHomeStudying(false));
    } else {
      dispatch(setHomeStudying(true));
    }
  };

  const onCloseModal = () => {
    dispatch(deleteModal());
  };

  const onFinish = () => {
    onCloseModal();
  };

  return (
    <Modal ariaLabel={t('modal.createAccountChild.title')}>
      <div className={styles.modal}>
        <div className={styles.modalTitle}>{t('modal.createAccountChild.title')}</div>
        <div className={styles.modalSubtitle}>{t('modal.createAccountChild.subtitle')}</div>
        <Selects />
        <div className={styles.wrapperCheckbox}>
          <span className={styles.termscheck}>
            <input
              type="checkbox"
              id="terms_modal_child"
              checked={isStudyingAtHome}
              onChange={handleHomeStudyingClick}
            />
            <label htmlFor="terms_modal_child">
              <span></span>
              {t('modal.createAccountChild.checkboxText')}
            </label>
          </span>
        </div>
        <div className={styles.text}>
          <span>&#8226;</span>
          {t('modal.createAccountChild.text')}
        </div>
        <div className={styles.wrapperBtn} onClick={onFinish}>
          <button className={styles.modalBtn}>{t('modal.createAccountChild.btnText')}</button>
        </div>
      </div>
    </Modal>
  );
};
