import { useTranslation } from 'react-i18next';

import { Loader } from '../../../../components/Loader';
import { Partners } from '../../../../components/Partners/Partners';
import { useGetPartnersQuery } from '../../../../services/home';
import { HomeSectionLayout } from '../HomeSectionLayout';

export const OurPartners = () => {
  const { data = [], isLoading } = useGetPartnersQuery('');
  const { t } = useTranslation();

  if (isLoading) return <Loader />;

  return (
    <HomeSectionLayout
      title={t('home.ourPartners.title')}
      description={t('home.ourPartners.description')}
    >
      {data.length > 0 && <Partners list={data} />}
    </HomeSectionLayout>
  );
};
