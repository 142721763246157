import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const ShoppingCartIcon = (props: IIcon) => {
  return (
    <Icon width="18" height="18" fill="none" {...props}>
      <path
        d="M1.44922 9.22266L2.62516 16.2785C2.71716 16.8304 3.19466 17.235 3.7542 17.235H14.41C14.9797 17.235 15.4626 16.8161 15.5431 16.2522L16.5472 9.22266H1.44922ZM6.13096 14.3734C6.13096 14.6896 5.87479 14.9458 5.55864 14.9458C5.24248 14.9458 4.98632 14.6896 4.98632 14.3734V10.9396C4.98632 10.6234 5.24248 10.3673 5.55864 10.3673C5.87479 10.3673 6.13096 10.6234 6.13096 10.9396V14.3734ZM9.56481 14.3734C9.56481 14.6896 9.30864 14.9458 8.99249 14.9458C8.67634 14.9458 8.42017 14.6896 8.42017 14.3734V10.9396C8.42017 10.6234 8.67634 10.3673 8.99249 10.3673C9.30864 10.3673 9.56481 10.6234 9.56481 10.9396V14.3734ZM12.9987 14.3734C12.9987 14.6896 12.7425 14.9458 12.4263 14.9458C12.1102 14.9458 11.854 14.6896 11.854 14.3734V10.9396C11.854 10.6234 12.1102 10.3673 12.4263 10.3673C12.7425 10.3673 12.9987 10.6234 12.9987 10.9396V14.3734Z"
        fill="#00394f"
      />
      <path
        d="M17.2031 5.7953H15.5324L10.5492 0.812215C10.3257 0.588682 9.96351 0.588682 9.73994 0.812215C9.51641 1.03558 9.51641 1.39811 9.73994 1.6215L13.9138 5.7953H4.08619L8.25999 1.62147C8.48352 1.39811 8.48352 1.03558 8.25999 0.812181C8.03645 0.588648 7.67426 0.588648 7.4507 0.812181L2.46765 5.7953H0.796929C0.480844 5.7953 0.224609 6.05153 0.224609 6.36762V7.51222C0.224609 7.82828 0.480844 8.08454 0.796929 8.08454H17.2031C17.5192 8.08454 17.7754 7.82831 17.7754 7.51222V6.36762C17.7754 6.05153 17.5192 5.7953 17.2031 5.7953Z"
        fill="#00394f"
      />
    </Icon>
  );
};
