import cn from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { IMenuHeader } from '../../../../../models/IShared';
import styles from './DesktopNavigationitem.module.scss';

type Props = {
  item: IMenuHeader;
};

export const DesktopNavigationitem: React.FC<Props> = ({ item }) => {
  const { name, children } = item;
  const hasChildren = children && children.length > 0;

  return (
    <li className={cn(styles.item, { [styles.arrow]: hasChildren })} tabIndex={0}>
      {name}
      {hasChildren && (
        <ul className={styles.sublist}>
          {children.map(child => (
            <li key={child?.pk}>
              <NavLink to={child?.link ?? ''} className={styles.sublistItem}>
                {child?.name}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
