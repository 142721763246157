import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const RecommendedBookIcon = (props: IIcon) => {
  return (
    <Icon width="32" height="38" fill="none" {...props}>
      <path
        d="M5.80469 29.8457V0.75H30.1265C30.4622 0.75 30.6722 1.00065 30.6722 1.23481V29.8457H5.80469ZM13.3812 20.5891H23.0956C24.191 20.5891 25.1412 19.7341 25.1412 18.6043C25.1412 17.4746 24.191 16.6195 23.0956 16.6195H13.3812C12.2859 16.6195 11.3356 17.4746 11.3356 18.6043C11.3356 19.7341 12.2859 20.5891 13.3812 20.5891ZM11.9935 13.9762H24.4834C25.5787 13.9762 26.529 13.1211 26.529 11.9914C26.529 10.8616 25.5787 10.0066 24.4834 10.0066H11.9935C10.8982 10.0066 9.94792 10.8616 9.94792 11.9914C9.94792 13.1211 10.8982 13.9762 11.9935 13.9762Z"
        stroke="#00394F"
        strokeWidth="1.5"
      />
      <path
        d="M5.49085 29.845H4.46568C3.43907 29.845 2.5509 30.443 1.86767 31.0359C1.68697 31.1927 1.50563 31.3636 1.32812 31.5385V4.54125C1.32812 2.33337 2.83521 0.750023 4.46678 0.75C4.46679 0.75 4.4668 0.75 4.46681 0.75L5.49085 0.750048V29.845Z"
        stroke="#00394F"
        strokeWidth="1.5"
      />
      <path
        d="M4.67129 36.5345C2.82851 36.5345 1.32812 35.0341 1.32812 33.1913C1.32812 31.3458 2.82879 29.8457 4.67136 29.8457H30.6734V36.1712C30.6734 36.3718 30.5108 36.5345 30.3102 36.5345H4.67129Z"
        stroke="#00394F"
        strokeWidth="1.5"
      />
    </Icon>
  );
};
