import uk from 'date-fns/locale/uk';
import { FieldAttributes, useField } from 'formik';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import styles from './FormDatePicker.module.scss';

registerLocale('uk', uk);

export const FormDatePicker: React.FC<FieldAttributes<any>> = ({ label, ...props }) => {
  const { i18n } = useTranslation();
  const [field, meta, helpers] = useField<Date | undefined>(props);
  const isShowError = meta.touched && meta.error;

  return (
    <label className={styles.formInputLabel}>
      {label}
      <DatePicker
        className={styles.formInput}
        {...field}
        {...props}
        dateFormat={'yyyy-MM-dd'}
        selected={field?.value}
        onChange={date => helpers.setValue(date)}
        locale={i18n.language === 'en' ? 'en' : 'uk'}
        autoComplete="off"
        popperClassName={styles.formInputPopper}
        maxDate={new Date()}
      />
      {isShowError ? <div className={styles.formInputErrorText}>{meta.error}</div> : null}
    </label>
  );
};
