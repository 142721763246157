import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const EditIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <g clipPath="url(#clip0_1714_134)">
        <path
          d="M15.5884 2.40137L13.5986 0.411499C13.3333 0.146362 12.9801 0.00012207 12.604 0C12.6037 0 12.6036 0 12.6034 0C12.2273 0 11.8742 0.145996 11.6092 0.411011L1.46396 10.5562C1.38083 10.6412 1.34665 10.7623 1.32199 10.8381L0.0180383 15.4021C-0.0287146 15.5659 0.0169397 15.7422 0.137301 15.8625C0.226534 15.9518 0.346407 16 0.468966 16C0.512057 16 0.555392 15.9941 0.597872 15.9819L5.15927 14.6787C5.17282 14.6748 5.37436 14.6055 5.44382 14.536L15.589 4.39075C15.8542 4.12561 16.0001 3.77234 16 3.396C15.9999 3.0199 15.8536 2.66663 15.5884 2.40137ZM1.15195 14.848L2.02768 11.7831L4.21689 13.9723L1.15195 14.848ZM5.11215 13.541L2.45896 10.8878L10.6137 2.73303L13.2669 5.38623L5.11215 13.541ZM14.9256 3.72754L13.9303 4.72302L11.277 2.0697L12.2724 1.07434C12.3603 0.98645 12.4779 0.93811 12.6034 0.93811H12.6036C12.7293 0.93811 12.8471 0.986694 12.9353 1.07483L14.9251 3.0647C15.108 3.24756 15.1083 3.54492 14.9256 3.72754Z"
          fill="#717171"
        />
      </g>
      <defs>
        <clipPath id="clip0_1714_134">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
