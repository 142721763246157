import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IReview, REVIEW_PAGE } from '../../models/IShared';
import { selectUser } from '../../redux/Auth';
import { declineString } from '../../services/localization/localizationService';
import { useLazyGetReviewsChildrenQuery } from '../../services/shared';
import { Button } from '../Button';
import { Loader } from '../Loader';
import { Comment } from './Comment';
import { getInitials } from './Comment/getInitials';
import styles from './Comments.module.scss';

interface IProps {
  reviews: IReview[];
  onChangeReview: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onCreateReview: (parentId: string) => () => void;
  count: number;
  type: REVIEW_PAGE;
}

export const Comments: React.FC<IProps> = ({
  reviews,
  onChangeReview,
  onCreateReview,
  count,
  type,
}) => {
  const [parentId, setParentId] = useState('');
  const [review, setReview] = useState('');
  const { t } = useTranslation();

  const onWillChangeReview = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChangeReview(e);
    setReview(e.target.value);
  };

  const onWillCreateReview = () => {
    onCreateReview(parentId)();
    setReview('');
  };

  const onChangeParentId = (parentId: string) => {
    setParentId(parentId);
  };

  const props = {
    onChangeParentId,
    onCreateReview,
    onChangeReview,
  };

  const user = useTypedSelector(selectUser);

  const getUserInitials = () => {
    if (user) {
      return user?.profile_name
        ? getInitials(user?.profile_name, 2)
        : getInitials(user?.username, 1);
    } else {
      return '';
    }
  };

  const userInitials = getUserInitials();
  const titleEnding = declineString(count, [
    t('comments.titleCommentPart1'),
    t('comments.titleCommentPart2'),
    t('comments.titleCommentPart3'),
  ]);

  return (
    <div className={styles.comments}>
      <div className={styles.commentsTitleContainer}>
        <h3 className={styles.commentsTitle}>
          {t('comments.titlePart1')} {count} {titleEnding}
        </h3>
      </div>
      {reviews.map(review => {
        return <CommentWrapper key={review.pk} review={review} {...props} type={type} />;
      })}
      <div className={styles.commentFormSection}>
        <h3 className={styles.commentFormSectionTitle}>{t('comments.subtitle')}:</h3>
        <p className={styles.commentFormSectionDescription}>
          {t('comments.descriptionPart1')}
          <br />
          {t('comments.descriptionPart2')}
        </p>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <label className={styles.commentFormLabel}>
            <div className={styles.commentFormTxt}>
              <div className={styles.commentFormAuthorImage}>{userInitials}</div>
              {t('comments.leaveReview')}
            </div>
            <textarea
              className={styles.commentFormTextArea}
              onChange={onWillChangeReview}
              value={review}
            />
          </label>
          <div className={styles.commentFormButtonsContainer}>
            <Button
              type="button"
              onClick={onWillCreateReview}
              className={styles.commentFormSubmitBtn}
            >
              {t('comments.send')}
            </Button>
            <Button
              onClick={() => setReview('')}
              className={styles.commentFormCancelBtn}
              isOrange
              type="button"
            >
              {t('comments.cancel')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

interface IComment {
  review: IReview;
  onChangeParentId: (parentId: string) => void;
  onCreateReview: (parentId: string) => () => void;
  onChangeReview: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  type: REVIEW_PAGE;
}

const CommentWrapper: React.FC<IComment> = ({
  review,
  onChangeParentId,
  onChangeReview,
  onCreateReview,
  type,
}) => {
  const [childrenCommentsVisible, setChildrenCommentsVisible] = useState(false);

  const [
    getReviewsChildren,
    { data: childrenReviews = null, isLoading: isChildLoading, isFetching: isFetchingChildReviews },
  ] = useLazyGetReviewsChildrenQuery();

  const [loadedChildId, setLoadedChildId] = useState<string[]>([]);

  const onGetChildrenReviews = async (id: string) => {
    if (loadedChildId.includes(id)) return;

    const resReviewsChildren = await getReviewsChildren({ id, type });

    if (resReviewsChildren.isSuccess) setLoadedChildId(pre => [...pre, id]);
  };

  const showChildrenComments = (id: string) => () => {
    onGetChildrenReviews(id as string);
    setChildrenCommentsVisible(!childrenCommentsVisible);
  };

  return (
    <Fragment key={review.pk}>
      {isFetchingChildReviews && <Loader />}
      <Comment
        comment={review}
        totalAmount={review.reviews}
        showChildrenComments={showChildrenComments(String(review.pk))}
        childrenCommentsVisible={childrenCommentsVisible}
        onChangeParentId={onChangeParentId}
        onCreateReview={onCreateReview}
        onChangeReview={onChangeReview}
      />

      {review.reviews! > 0 &&
        childrenCommentsVisible &&
        childrenReviews &&
        childrenReviews.map(childReview => (
          <Comment
            key={childReview.pk}
            comment={childReview}
            isChild
            isChildLoading={isChildLoading}
            onChangeParentId={onChangeParentId}
            onCreateReview={onCreateReview}
            onChangeReview={onChangeReview}
          />
        ))}
    </Fragment>
  );
};
