import React from 'react';
import { Link } from 'react-router-dom';

import defaultProductImage from '../../../../assets/img/class.png';
import { IPopularProduct } from '../../../../models/IProducts';
import styles from './PopularProduct.module.scss';

type Props = {
  product: IPopularProduct;
  link: string;
};

export const PopularProduct: React.FC<Props> = ({ product, link }) => {
  const { title, subject_title, ages, preview_popular, product_card_block } = product;
  const productBlock = product_card_block?.slice(0, 2);

  return (
    <li className={styles.popularProduct}>
      <Link to={link} className={styles.popularProductImageContainer}>
        <img
          className={styles.popularProductImage}
          width="75"
          height="83"
          src={preview_popular || defaultProductImage}
          alt="book"
        />
      </Link>

      <div className={styles.popularProductInfo}>
        <Link to={link} className={styles.popularProductTitleContainer}>
          <h4 className={styles.popularProductTitle}>{title}</h4>
        </Link>
        {productBlock?.length > 0 && (
          <ul className={styles.popularProductCurriculumList}>
            {productBlock.map(task => {
              const { title, pk } = task;
              const num = parseInt(title) || '';

              return (
                <li key={pk} className={styles.popularProductCurriculumItem}>
                  <strong>{num}</strong>
                  {title.slice(num.toString().length)}
                </li>
              );
            })}
          </ul>
        )}
        <div className={styles.popularProductSubjectAndAgeInfo}>
          <div className={styles.popularProductSubjectTitle}>
            {subject_title?.name}
          </div>
          <div className={styles.popularProductAgeInfo}>
            {ages?.name}
          </div>
        </div>
      </div>
    </li>
  );
};
