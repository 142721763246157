import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';

import { EditorLogo } from '../../../assets/svg/EditorLogo';
import logo from '../../../assets/svg/logo.svg';
import { useGetMenuHeaderQuery } from '../../../services/shared';
import { Button } from '../../Button';
import { Loader } from '../../Loader';
import { MaxWidthContainer } from '../../MaxWidthContainer';
import { Search } from '../Search/Search';
import { SearchPopup } from '../SearchPopup/SearchPopup';
import styles from './BottomHeader.module.scss';
import { DesktopNavigationList } from './DesktopNavigationList';
import { MobileNavigationList } from './MobileNavigationList';

type Props = {
  isProfilePopupOpened: boolean;
};

export const BottomHeader: FC<Props> = ({ isProfilePopupOpened }) => {
  const [isShowSearchPopup, setIsShowSearchPopup] = useState(false);
  const [isPageNavigationOpened, setIsPageNavigationOpened] = useState(false);
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const { t } = useTranslation();

  const { data: menuList = [], isLoading } = useGetMenuHeaderQuery('');

  useEffect(() => {
    setSearch(searchParams.get('search') || '');
  }, [searchParams]);

  useEffect(() => {
    if (isPageNavigationOpened) {
      setIsPageNavigationOpened(false);
    }
  }, [isProfilePopupOpened]);

  const onShowPageNavigation = () => {
    setIsPageNavigationOpened(!isPageNavigationOpened);
  };

  const onShowSearchPopup = () => {
    setIsShowSearchPopup(pre => !pre);

    if (isPageNavigationOpened) {
      setIsPageNavigationOpened(false);
    }
  };

  const onCloseSearchPopup = () => {
    setIsShowSearchPopup(false);
  };

  if (isLoading) return <Loader />;

  return (
    <MaxWidthContainer>
      <div className={styles.headerBottom}>
        <NavLink to="/">
          <img src={logo} className={styles.headerLogo} alt={t('header.logoAltText')} />
        </NavLink>

        <div className={styles.headerBottomSearchContainer}>
          {isShowSearchPopup && <SearchPopup onClose={onCloseSearchPopup} search={search} />}
          <Search
            onClick={onShowSearchPopup}
            onChange={search => {
              setSearch(search);
            }}
            value={search}
          />
          <div className={styles.headerBottomMobileMenuAndEditorLink}>
            <a
              className={styles.headerBottomMobileEditorBtn}
              aria-label="Перейти в редактор"
              target="_blank"
              href="https://kidcanvas.skrinkaznan.com/"
              rel="noopener noreferrer"
            >
              <EditorLogo width="20" height="20" />
            </a>
            <button
              onClick={onShowPageNavigation}
              className={styles.headerBottomMenuBtn}
              aria-label="Меню"
            >
              <i className="icofont-navigation-menu"></i>
            </button>
          </div>
        </div>
        <nav className={styles.headerBottomDesktopNavigation}>
          <DesktopNavigationList list={menuList} />
        </nav>
        <a
          href="https://kidcanvas.skrinkaznan.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.headerBottomEditorButton}
        >
          <Button>
            {t('header.openEditor')}
            <EditorLogo width="24" height="24" />
          </Button>
        </a>
      </div>
      <nav
        className={cn(styles.headerBottomMobileNavigation, {
          [styles.navigationVisible]: isPageNavigationOpened,
        })}
      >
        {isPageNavigationOpened && (
          <MobileNavigationList
            list={menuList}
            onCloseNavigation={() => setIsPageNavigationOpened(false)}
          />
        )}
      </nav>
    </MaxWidthContainer>
  );
};
