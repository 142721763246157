import { ICourse } from '../models/ICourses';
import { IProduct } from '../models/IProducts';
import { RecommendationCourse, RecommendationProduct } from '../models/IShared';

export const changeObjByFavorite = <
  T extends {
    courses: RecommendationCourse[] | ICourse[] | null;
    products: RecommendationProduct[] | IProduct[] | null;
  }
>(
  typeItem: 'courses' | 'products',
  item: ICourse | IProduct | RecommendationProduct | RecommendationCourse
) => {
  return (pre: T) => ({
    ...pre,
    [typeItem]: pre[typeItem]!.map(currItem =>
      currItem.pk === item.pk
        ? {
            ...currItem,
            is_favorite: !item.is_favorite,
          }
        : currItem
    ),
  });
};

interface IChangeObjByFavorite {
  pre: IProduct[] | ICourse[] | undefined;
  item: IProduct | ICourse;
}

export const changeObjByFavoriteSingleArray = ({ pre, item }: IChangeObjByFavorite) =>
  pre!.map(currItem =>
    currItem.pk === item.pk
      ? {
          ...currItem,
          is_favorite: !item.is_favorite,
        }
      : currItem
  );
