import cn from 'classnames';
import React from 'react';

import { LinkButton } from '../../../../components/LinkButton';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import styles from './HomeSectionLayout.module.scss';

type Props = {
  title: string;
  description: string;
  className?: string;
  containerClassName?: string;
  children: React.ReactNode;
  buttonLink?: string;
  buttonText?: string;
};

export const HomeSectionLayout: React.FC<Props> = ({
  title,
  description,
  className,
  children,
  containerClassName,
  buttonLink,
  buttonText,
}) => {
  return (
    <div className={cn(styles.homeSection, className)}>
      <MaxWidthContainer className={containerClassName}>
        <div className={styles.homeSectionHeading}>
          <h2 className={styles.homeSectionTitle}>{title}</h2>
          <p className={styles.homeSectionDescription}>{description}</p>
        </div>
        {children}
        {buttonLink && (
          <div className={styles.homeSectionBtnContainer}>
            <LinkButton to={buttonLink}>{buttonText}</LinkButton>
          </div>
        )}
      </MaxWidthContainer>
    </div>
  );
};
