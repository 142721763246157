import { Component, ReactNode } from 'react';

import { ErrorInfo } from './ErrorInfo/ErrorInfo';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (error) {
      console.error(`error - ${JSON.stringify(errorInfo)}`);
    }
  }

  handleResetError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return <ErrorInfo onResetError={this.handleResetError} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
