import cn from 'classnames';
import React from 'react';

import styles from './Button.module.scss';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (arg: any) => void;
  children: React.ReactNode;
  isBlue?: boolean;
  isOrange?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

export const Button: React.FC<Props> = ({
  onClick,
  children,
  isBlue,
  isOrange,
  className,
  disabled,
  type = 'submit',
}) => {
  const classes = cn(
    styles.Button,
    { [styles.Blue]: isBlue },
    { [styles.Orange]: isOrange },
    { [styles.disabled]: disabled }
  );

  return (
    <button onClick={onClick} className={cn(classes, className)} type={type} disabled={disabled}>
      {children}
    </button>
  );
};
