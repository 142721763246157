import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import arrowIcon from '../../../assets/img/down-arrow.png';
import blackArrowIcon from '../../../assets/img/down-arrow-black.png';
import { IChildrenReview, IReview } from '../../../models/IShared';
import { declineString } from '../../../services/localization/localizationService';
import { Loader } from '../../Loader';
import styles from './Comment.module.scss';
import { getInitials } from './getInitials';
import { ReplyForm } from './ReplyForm';
import { useValidCommentTime } from './useValidCommentTime';

type Props = {
  comment: IReview | IChildrenReview;
  isChild?: boolean;
  showChildrenComments?: () => void;
  childrenCommentsVisible?: boolean;
  totalAmount?: number;
  isChildLoading?: boolean;
  onChangeParentId: (id: string) => void;
  onCreateReview: (parentId: string) => () => void;
  onChangeReview: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const Comment: React.FC<Props> = ({
  comment,
  isChild,
  showChildrenComments,
  childrenCommentsVisible,
  totalAmount,
  isChildLoading,
  onCreateReview,
  onChangeReview,
}) => {
  const { t } = useTranslation();
  const [isCommentExpanded, setIsCommentExpanded] = useState(false);
  const [isReplyFieldOpened, setIsReplyFieldOpened] = useState(false);
  const { user, created_at, text } = comment;

  const getParentUserName = () => {
    if ('parent' in comment) {
      return comment.parent.profile_name ?? comment.parent.username;
    }

    return null;
  };

  const parentUserName = getParentUserName();
  const userInitials = user.profile_name
    ? getInitials(user.profile_name, 2)
    : getInitials(user.username, 1);

  const isCommentShort = text.length < 185;
  const userName = user?.profile_name ?? user?.username;

  const handleCancellation = () => {
    setIsReplyFieldOpened(false);
  };

  const { formattedDate } = useValidCommentTime(created_at);

  const answerEnding = declineString(totalAmount, [
    t('comments.answerText1'),
    t('comments.answerText2'),
    t('comments.answerText3'),
  ]);

  return (
    <div className={cn(styles.comment, { [styles.commentIndent]: isChild })}>
      <div className={styles.commentAuthor}>
        {user?.avatar && <img src={user.avatar} alt={t('comments.commentAuthorImageAltText')} />}
        {!user?.avatar && userInitials}
      </div>
      <div className={styles.commentInfo}>
        {parentUserName ? (
          <div className={styles.commentAuthorNames}>
            <h4 className={styles.commentAuthorName}>{parentUserName}</h4>
            <h4 className={styles.commentAuthorName}>{userName}</h4>
          </div>
        ) : (
          <h4 className={styles.commentAuthorName}>{userName}</h4>
        )}
        <p className={styles.commentText}>
          {isCommentShort || isCommentExpanded ? text : `${text.slice(0, 185)}...`}
        </p>
        <div className={styles.commentControl}>
          <div className={styles.commentReply}>
            <button
              onClick={() => {
                setIsReplyFieldOpened(true);
              }}
              className={styles.commentReplyButton}
            >
              <i className={cn('icofont-reply', styles.commentReplyIcon)}></i>
              {t('comments.commentReplyBtnText')}
            </button>
            <div className={styles.commentDate}>
              <i className={cn('icofont-clock-time', styles.commentClockIcon)}></i>
              {formattedDate}
            </div>
          </div>
          {!isCommentShort && (
            <button
              onClick={() => setIsCommentExpanded(!isCommentExpanded)}
              className={styles.commentControlButton}
            >
              {isCommentExpanded
                ? t('comments.commentCollapseBtnText')
                : t('comments.commentExpandBtnText')}
              <img
                className={cn(styles.commentArrowIcon, {
                  [styles.commentArrowIconRotate]: isCommentExpanded,
                })}
                width="13"
                height="13"
                src={arrowIcon}
                alt="arrow"
              />
            </button>
          )}
        </div>
        {isReplyFieldOpened && (
          <ReplyForm
            onCancel={handleCancellation}
            userInitials={userInitials}
            onSend={onCreateReview(String(comment.pk))}
            onChangeText={onChangeReview}
          />
        )}
        {isChildLoading && <Loader />}
        {!isChild && !!totalAmount && (
          <button className={styles.nestedCommentsControl} onClick={showChildrenComments}>
            <img
              className={cn(styles.commentArrowIcon, {
                [styles.commentArrowIconRotate]: childrenCommentsVisible,
              })}
              src={blackArrowIcon}
              width="13"
              height="13"
              alt="arrow"
            />
            <div className={styles.nestedCommentAuthorImage}>{userInitials}</div>
            <div className={styles.nestedCommentsAmount}>
              {totalAmount} {answerEnding}
            </div>
          </button>
        )}
      </div>
    </div>
  );
};
