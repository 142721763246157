import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const FacebookIcon = (props: IIcon) => {
  return (
    <Icon width="10" height="18" fill="none" {...props}>
      <path
        d="M6.32244 17.0763V9.28755H8.93574L9.32782 6.25125H6.32244V4.31301C6.32244 3.43421 6.56548 2.83531 7.82711 2.83531L9.43359 2.83465V0.118876C9.15578 0.0827732 8.20212 0 7.09217 0C4.77441 0 3.18763 1.41474 3.18763 4.0123V6.25125H0.566406V9.28755H3.18763V17.0763H6.32244Z"
        fill="white"
      />
    </Icon>
  );
};
