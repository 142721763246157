import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import bookImage from '../../../../assets/img/coming-soon-articles.webp';
import { USER_TYPE } from '../../../../models/IAuth';
import { setUserType } from '../../../../redux/Auth';
import { PAGES_ENUM } from '../../../../utils/constants';
import { Modal } from '../../Modal';
import { deleteModal } from '../../ModalSlice';
import styles from './CreateAccount.module.scss';

export const CreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSetUserType = (role: USER_TYPE) => dispatch(setUserType(role));

  const onCloseModal = () => {
    dispatch(deleteModal());
  };

  const onClickBtn = (role: USER_TYPE) => () => {
    onSetUserType(role);
    onCloseModal();
    navigate(PAGES_ENUM.SIGN_UP);
  };

  return (
    <Modal
      ariaLabel={t('modal.createAccount.title')}
      contentClassName={styles.createAccountModalContent}
    >
      <div className={styles.title}>{t('modal.createAccount.title')}</div>
      <div className={styles.container}>
        <img className={styles.bookImage} src={bookImage} alt="book" />
        <div className={styles.createAccount}>
          <div className={styles.subtitle}>{t('modal.createAccount.subtitle')}</div>

          <div className={styles.modalBtn} onClick={onClickBtn(USER_TYPE.PARENT)}>
            <div className={styles.modalBtnBigText}>{t('modal.createAccount.secondBtnTitle')}</div>
          </div>

          <div className={styles.modalBtn} onClick={onClickBtn(USER_TYPE.TEACHER)}>
            <div className={styles.modalBtnBigText}>{t('modal.createAccount.thirdBtnTitle')}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
