import cn from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import defProfileImage from '../../../assets/img/user.png';
import { LogoutIcon } from '../../../assets/svg/LogoutIcon';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useLogout } from '../../../hooks/useLogout';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { selectIsGoogleAuthenticate, selectUser } from '../../../redux/Auth';
import styles from './ProfilePopup.module.scss';
import { useTranslatedProfileList } from './useTranslatedProfileList';

interface IProps {
  onClose: () => void;
}

export const ProfilePopup: React.FC<IProps> = ({ onClose }) => {
  const popupRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(popupRef, null, onClose);
  const { t } = useTranslation();
  const { onLogout, onGoogleLogout } = useLogout();

  const { profileList } = useTranslatedProfileList();

  const profile = useTypedSelector(selectUser);

  const isGoogleAuthenticate = useTypedSelector(selectIsGoogleAuthenticate);

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isGoogleAuthenticate) {
      onGoogleLogout(e);
    } else {
      onLogout(e);
    }
    onClose();
  };

  return (
    <div className={styles.container} ref={popupRef}>
      <div className={styles.profileHeader}>
        <div>
          <div className={styles.profileImg}>
            <img
              src={profile?.avatar ?? defProfileImage}
              alt={t('profilePopup.profileImageAltText')}
            />
          </div>
        </div>
        <div className={styles.profileInfo}>
          <div className={styles.profileName}>{profile?.full_name}</div>
          <div className={styles.profileEmail}>{profile?.username}</div>
        </div>
      </div>

      <div className={styles.profileList}>
        {profileList.map((item, idx) => {
          const { icon: Icon } = item;

          return (
            <Link
              to={item.link}
              onClick={onClose}
              className={cn(styles.profileItem, {
                [styles.border]: item.border,
              })}
              key={idx}
            >
              <Icon className={styles.profileItemIcon} />
              <div className={styles.text}>{item.text}</div>
            </Link>
          );
        })}

        <button className={styles.profileItem} onClick={handleLogout}>
          <LogoutIcon className={styles.profileItemIcon} />
          <div className={styles.text}>{t('profilePopup.logout')}</div>
        </button>
      </div>
    </div>
  );
};
