import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MoveToCollectionIcon } from '../../../../../assets/svg/MoveToCollectionIcon';
import { SaveIcon } from '../../../../../assets/svg/SaveIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { ICollectionForProduct, IResCartCollection } from '../../../../../models/ICollections';
import {
  useAddToCollectionMutation,
  useGetCollectionsForProductQuery,
} from '../../../../../services/collections';
import Emitter, { EMITTER_EVENTS } from '../../../../../utils/eventEmitter';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Spinner } from '../../../../Spinner';
import { Modal } from '../../../Modal';
import { deleteModal, setModal } from '../../../ModalSlice';
import { ModalTypes } from '../../../types/enums/ModalTypes';
import { CollectionModalLayout } from '../CollectionModalLayout';
import { CollectionsList } from '../CollectionsList';
import styles from './SaveToCollectionsModal.module.scss';

interface IProps {
  id: number;
  typeModal: 'saveModal' | 'moveModal';
}

export const SaveToCollectionsModal: React.FC<IProps> = ({ id, typeModal }) => {
  const { t } = useTranslation();
  const {
    data: dataCollectionsForProduct = { results: [] },
    isFetching,
    isError,
    error,
  } = useGetCollectionsForProductQuery(
    {
      product_id: id,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [data, setData] = useState<ICollectionForProduct[]>([]);

  const [collections, setCollections] = useState<number[]>([]);

  const dispatch = useTypedDispatch();

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onCheck = (collectionId: number) => () => {
    setCollections(pre =>
      pre.includes(collectionId) ? pre?.filter(c => c !== collectionId) : [...pre, collectionId]
    );

    setData(pre =>
      pre.map(collection =>
        collection.pk === collectionId
          ? { ...collection, is_product_in: !collection.is_product_in }
          : collection
      )
    );
  };

  useEffect(() => {
    if (isFetching) return;
    setData(dataCollectionsForProduct.results);

    const collections = dataCollectionsForProduct.results
      .filter(c => c.is_product_in && c.is_personal)
      .map(c => c.pk);

    setCollections(collections);

    return () => setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const [addToCollection, resAddToCollection] = useAddToCollectionMutation();

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resAddToCollection.isSuccess,
    textSuccess: t('collections.modals.saveToCollection.successNotificationText'),
    afterCreate: onClose,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resAddToCollection.isError,
    error: resAddToCollection.error as ErrorNode,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: isError,
    error: error as ErrorNode,
  });

  const onAddToCollection = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = (await addToCollection({ id, body: { collections } })) as IResCartCollection;

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      Emitter.emit(EMITTER_EVENTS.ADD_TO_COLLECTION_SUCCESS, {
        isTrue: collections.length !== 0,
        id,
      });
    }
  };

  const onCreateNewCollection = () => {
    dispatch(
      setModal({
        modalType: ModalTypes.CREATE_COLLECTION,
        modalProps: {
          afterSuccessFunc: {
            modalType: ModalTypes.SAVE_TO_COLLECTIONS,
            modalProps: { id, typeModal },
          },
        },
      })
    );
  };

  const modal = {
    saveModal: {
      icon: <SaveIcon className={styles.saveToCollectionsIcon} />,
      title: t('collections.modals.saveToCollection.title'),
    },
    moveModal: {
      icon: <MoveToCollectionIcon className={styles.moveToCollectionsIcon} />,
      title: t('collections.modals.saveToCollection.moveTitle'),
    },
  };

  return (
    <Modal ariaLabel={modal[typeModal].title}>
      <CollectionModalLayout title={modal[typeModal].title} icon={modal[typeModal].icon}>
        {isFetching ? (
          <Spinner className={styles.spinnerClassName} />
        ) : (
          <CollectionsList list={data} onCheck={onCheck} />
        )}
        <div className={styles.moveToCollectionsButtons}>
          <Button
            className={styles.moveToCollectionsButton}
            onClick={onCreateNewCollection}
            isOrange
          >
            {t('collections.modals.saveToCollection.createCollectionBtnText')}
          </Button>
          {data?.length > 0 && (
            <Button className={styles.moveToCollectionsButton} onClick={onAddToCollection}>
              {t('collections.modals.confirmBtnText')}
            </Button>
          )}
        </div>
      </CollectionModalLayout>
    </Modal>
  );
};
