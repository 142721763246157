import EventEmitter from 'eventemitter3';

export enum EMITTER_EVENTS {
  ADD_TO_COLLECTION_SUCCESS = 'ADD_TO_COLLECTION_SUCCESS',
}

const eventEmitter = new EventEmitter();

const Emitter = {
  on: (event: string, fn: (value: any) => void) => eventEmitter.on(event, fn),
  once: (event: string, fn: () => void) => eventEmitter.once(event, fn),
  off: (event: string, fn?: () => void) => eventEmitter.off(event, fn),
  emit: (event: string, obj: { [key: string]: any }) => eventEmitter.emit(event, obj),
};

Object.freeze(Emitter);

export default Emitter;
