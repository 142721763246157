import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { ProductDetailImg } from './ProductDetailImg';
import styles from './ProductImgSlider.module.scss';

interface IImage {
  attachment: string;
  attachment_type: string;
  pk: number;
}
export interface IProps {
  imgList: IImage[];
  className?: string;
  imageAlt: string;
}

export const ProductImgSlider: React.FC<IProps> = ({ imgList, className, imageAlt }) => {
  const [currentImg, setCurrentImg] = useState<null | string>(null);

  useEffect(() => {
    if (!currentImg && imgList?.[0]?.attachment) {
      setCurrentImg(imgList[0].attachment);
    }
  }, [imgList?.[0]?.attachment]);

  const onChangeCurrentImg = (img: string) => () => setCurrentImg(img);

  return (
    <div className={cn(styles.productWrapperPicture, className)}>
      <div className={styles.productPicture}>
        <img alt={imageAlt} src={currentImg} />
      </div>
      <div className={styles.productDetailImgList}>
        {imgList?.map(img => (
          <ProductDetailImg
            key={img?.pk}
            src={img?.attachment}
            onClick={onChangeCurrentImg(img?.attachment)}
            imageAlt={imageAlt}
          />
        ))}
      </div>
    </div>
  );
};
