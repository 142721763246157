export const EmailIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1219_57)">
        <path
          d="M24 19.7812C21.6737 19.7812 19.7812 21.6737 19.7812 24C19.7812 26.3263 21.6737 28.2188 24 28.2188C26.3263 28.2188 28.2188 26.3263 28.2188 24C28.2188 21.6737 26.3263 19.7812 24 19.7812Z"
          fill="#FFFEFE"
        />
        <path
          d="M24 0C10.8178 0 0 10.8178 0 24C0 37.1822 10.8178 48 24 48C37.1822 48 48 37.1822 48 24C48 10.8178 37.1822 0 24 0ZM39.4688 25.4062C39.4688 28.5085 36.946 31.0312 33.8438 31.0312C31.9432 31.0312 30.267 30.0787 29.2484 28.6316C27.9591 30.091 26.0957 31.0312 24 31.0312C20.1232 31.0312 16.9688 27.8768 16.9688 24C16.9688 20.1232 20.1232 16.9688 24 16.9688C25.5889 16.9688 27.0399 17.5186 28.2188 18.4109V18.375H31.0312V24C31.0312 24.4815 30.9817 24.9517 30.8889 25.4062H31.0312C31.0312 26.9567 32.2933 28.2188 33.8438 28.2188C35.3942 28.2188 36.6562 26.9567 36.6562 25.4062V24C36.6562 17.021 30.979 11.3438 24 11.3438C17.021 11.3438 11.3438 17.021 11.3438 24C11.3438 30.979 17.021 36.6562 24 36.6562C26.8125 36.6562 29.4726 35.7554 31.6932 34.0497L33.4071 36.2799C30.6893 38.366 27.4373 39.4688 24 39.4688C15.4705 39.4688 8.53125 32.5295 8.53125 24C8.53125 15.4705 15.4705 8.53125 24 8.53125C32.5295 8.53125 39.4688 15.4705 39.4688 24V25.4062Z"
          fill="#FFFEFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_1219_57">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
