import { useSearchParams } from 'react-router-dom';

import { useSetSearchParamsNotSavedInHistory } from './useSetSearchParamsNotSavedInHistory';

export const usePagination = () => {
  const [searchParams] = useSearchParams();
  const { setSearchParamsNotSavedInHistory } = useSetSearchParamsNotSavedInHistory();

  const getPageFromSearchParams = () => {
    const urlPage = Number(searchParams.get('page'));

    return urlPage > 0 ? urlPage : 1;
  };

  const page = getPageFromSearchParams();

  const getSearchParamsAsObject = () => {
    const params: { [key: string]: any } = {};

    searchParams.forEach((value, key) => {
      if (params[key]) {
        params[key] = Array.isArray(params[key]) ? [...params[key], value] : [params[key], value];
      } else {
        params[key] = value;
      }
    });

    return params;
  };

  const onPageChange = ({ selected }) => {
    const newPage = selected + 1;
    const updatedParams = { ...getSearchParamsAsObject(), page: String(newPage) };

    setSearchParamsNotSavedInHistory(updatedParams);
  };

  return { page, onPageChange };
};
