import { USER_ROLE } from './IShared';

export interface IAuthLoginBody {
  username: string;
  password: string;
  remember?: boolean;
}

interface IUser {
  pk: number;
  username: string;
  profile_name: string;
  full_name: null | string;
  avatar: null | string;
}

export interface IAuthResponse {
  user: IUser;
  token: string;
  role: USER_ROLE;
  status?: number;
}

export interface IToken {
  token: string;
}

export interface IAuthRegisterBody {
  username: string;
  password: string;
  user_type: string;
  email: string;
  age?: number;
  home_studying?: boolean;
  ref_link?: string;
}

export enum USER_TYPE {
  TEACHER = 'teacher',
  PARENT = 'parent',
  STUDENT = 'student',
}

export interface IAuthGoogleLoginBody {
  access_token: string;
  authuser?: string;
  expires_in: number;
  prompt: string;
  scope: string;
  token_type: string;
}

export interface IAuthGoogleRegisterBody {
  access_token: string;
  authuser?: string;
  expires_in: number;
  prompt: string;
  scope: string;
  token_type: string;
  user_type: USER_TYPE;
  age?: number;
  home_studying?: boolean;
  ref_link?: string;
}

export interface ISendResetCodeResponse {
  result: boolean;
  status: number;
}

export interface ISendResetCodeBody {
  email: string;
  username?: string;
}

export interface ICheckCodeResponse {
  result: boolean;
}

export interface ICheckCodeBody {
  email: string;
  username?: string;
  code: string;
}

export interface IResetPasswordResponse {
  result: boolean;
}

export interface IResetPasswordBody {
  email: string;
  code: string;
  username?: string;
  new_password: string;
}
