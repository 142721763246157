import './VideoPlayer.scss';

import cn from 'classnames';
import { FC } from 'react';
import ReactPlayer from 'react-player';

import defaultPreviewImage from '../../assets/img/videoTab.png';
import defaultVideo from './flower.mp4';

type Props = {
  url: string;
  onStart?: () => void;
  onEnded?: () => void;
  thumbnail?: string;
  className?: string;
  isPlaying?: boolean;
  defaultVideoLink?: string;
};

export const VideoPlayer: FC<Props> = ({
  url,
  thumbnail,
  className,
  onStart,
  onEnded,
  isPlaying = true,
  defaultVideoLink,
}) => {
  const isDefaultPreview = isPlaying ? defaultPreviewImage : null;

  return (
    <div className={cn('video-player-wrapper', className)}>
      <ReactPlayer
        className="video-player"
        light={thumbnail || isDefaultPreview}
        url={url || defaultVideoLink || defaultVideo}
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1,
              controls: 1,
              fs: 1,
              rel: 0,
              showinfo: 0,
            },
          },
        }}
        controls
        width="100%"
        height="100%"
        playIcon={
          <div className="video-player__icon">
            <i className="icofont-play"></i>
          </div>
        }
        onStart={onStart}
        onEnded={onEnded}
        playing={isPlaying}
      />
    </div>
  );
};
