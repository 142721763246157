import { FAVORITE, TTypePageFavorite } from '../models/IShared';
import { useSetCartFavoriteMutation } from '../services/cart';
import { useSetCourseFavoriteMutation } from '../services/courses';
import { useSetProductsFavoriteMutation } from '../services/products';
import { ErrorNode, handleSuccess } from '../utils/notifications';
import { NOTIFICATION_TYPES, useNotification } from './useNotification';

export interface IUseSetFavorite {
  typePage: TTypePageFavorite;
  type: FAVORITE.COURSE | FAVORITE.PRODUCT;
}

export const useSetFavorite = () => {
  const [setCourseFavorite, resCourseFavorite] = useSetCourseFavoriteMutation();
  const [setProductFavorite, resProductFavorite] = useSetProductsFavoriteMutation();
  const [setCartFavorite, resCartFavorite] = useSetCartFavoriteMutation();

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resCourseFavorite.isError,
    error: resCourseFavorite.error as ErrorNode,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resProductFavorite.isError,
    error: resProductFavorite.error as ErrorNode,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resCartFavorite.isError,
    error: resCartFavorite.error as ErrorNode,
  });

  return {
    setCourseFavorite,
    setProductFavorite,
    setCartFavorite,
    resCourseFavorite,
    resProductFavorite,
    resCartFavorite,
  };
};

export const favoriteShowSuccessMessage = (isFavorite: boolean, t: (key: string) => string) => {
  if (isFavorite) handleSuccess(t('common.addedToWishlist'));
  if (!isFavorite) handleSuccess(t('common.removedFromWishlist'));
};
