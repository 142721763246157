import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const useValidationService = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('footer.newsletter.emailErrorFormatValidationText'))
      .required(t('footer.newsletter.enterEmailValidationText')),
  });

  return validationSchema;
};
