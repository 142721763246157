import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';

import * as I from '../models/ICollections';
import { ICourse } from '../models/ICourses';
import { CollectionInvalidationTags } from '../models/InvalidationTags';
import { IProduct } from '../models/IProducts';
import * as IS from '../models/IShared';
import { enumToArray } from '../utils/helpers';
import { transformResponseWithStatusCode } from '../utils/transformResponse/transformResponseWithStatusCode';
import { rootApi } from './rootApi';

export const collectionsApi = rootApi
  .enhanceEndpoints({ addTagTypes: [...enumToArray(CollectionInvalidationTags)] })
  .injectEndpoints({
    endpoints: builder => ({
      getCollections: builder.query<IS.IResponse<I.ICollection[]>, unknown>({
        query: params => ({ url: '/shop/collection-list/', params }),
        providesTags: () => ['ICollections'],
      }),

      createCollection: builder.mutation<unknown, FormData>({
        query: body => ({
          url: '/shop/collection-create',
          body,
          method: 'POST',
        }),
        invalidatesTags: () => ['ICollections', 'ICollectionForProduct'],
      }),

      editCollection: builder.mutation<unknown, I.IEditCollectionRequest>({
        query: ({ id, body }) => ({
          url: `/shop/collection-update/${id}`,
          body,
          method: 'PUT',
        }),
        invalidatesTags: () => ['ICollections', 'ICollection'],
      }),

      deleteCollection: builder.mutation<unknown, number>({
        query: id => ({
          url: `/shop/collection-destroy/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => ['ICollections'],
        transformResponse: transformResponseWithStatusCode,
      }),

      getCollection: builder.query<I.ICollectionDetail, { id: number; params: object }>({
        query: ({ id, params }) => ({ url: `/shop/collection-retrieve/${id}`, params }),
        providesTags: ['ICollection'],
      }),

      getCollectionRecommendedProducts: builder.query<
        IS.IResponse<IS.RecommendationProduct[]>,
        number
      >({
        query: id => `/shop/get-collection-products-recommendation/${id}`,
      }),

      getCollectionRecommendedCourses: builder.query<
        IS.IResponse<IS.RecommendationCourse[]>,
        number
      >({
        query: id => `/shop/get-collection-courses-recommendation/${id}`,
      }),

      getCollectionProducts: builder.query<IS.IResponse<IProduct[]>, I.IRequestCollectionProducts>({
        query: ({ id, params }) => ({ url: `/shop/get-collection-products/${id}`, params }),
        providesTags: ['ICollectionProducts'],
      }),

      getCollectionCourses: builder.query<IS.IResponse<ICourse[]>, I.IRequestCollectionCourses>({
        query: ({ id, params }) => ({ url: `/shop/get-collection-courses/${id}`, params }),
        providesTags: ['ICollectionCourses'],
      }),

      deleteFromCollection: builder.mutation<unknown, I.IDeleteFromCollectionRequest>({
        query: ({ collectionId, itemId }) => ({
          url: `/shop/remove-from-collection/${collectionId}/${itemId}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => ['ICollection'],
        transformResponse: transformResponseWithStatusCode,
      }),

      getCollectionsForProduct: builder.query<
        IS.IResponse<I.ICollectionForProduct[]>,
        { [key: string]: any }
      >({
        query: params => ({
          url: `/shop/get-collections-for-product/`,
          params,
        }),
        providesTags: ['ICollectionForProduct'],
      }),

      addToCollection: builder.mutation<unknown, I.IAddToCollectionRequest>({
        query: ({ id, body }) => ({
          url: `/shop/add-baseproduct-to-collection/${id}`,
          body,
          method: 'POST',
        }),
        invalidatesTags: () => ['ICollection', 'ICollectionCourses', 'ICollectionProducts'],
        transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
          return { ...data, status: meta?.response?.status } as { status: number };
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetCollectionsQuery,
  useCreateCollectionMutation,
  useDeleteCollectionMutation,
  useEditCollectionMutation,
  useGetCollectionQuery,
  useGetCollectionProductsQuery,
  useGetCollectionCoursesQuery,
  useGetCollectionRecommendedProductsQuery,
  useDeleteFromCollectionMutation,
  useGetCollectionRecommendedCoursesQuery,
  useGetCollectionsForProductQuery,
  useAddToCollectionMutation,
} = collectionsApi;
