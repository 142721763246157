import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../Button';
import { ShareSocials } from '../../../ShareSocials';
import { Modal } from '../../Modal';
import { useModalVisibility } from '../../useModalVisibility';
import styles from './SharePageModal.module.scss';

type Props = {
  title?: string;
};

export const SharePageModal: FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const { onClose } = useModalVisibility();

  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (modalRef?.current && !modalRef?.current.contains(target)) {
      onClose();
    }
  };

  return (
    <Modal ariaLabel="show certificate modal" contentClassName={styles.modalContentClassName}>
      <div
        className={styles.modalMain}
        tabIndex={-1}
        role="dialog"
        onClick={e => handleClickOutside(e)}
        ref={modalRef}
      >
        <div>
          <h2>{t('modal.sharePageModal.title')}</h2>
          <ShareSocials title={title} />
          <Button onClick={() => onClose()}>{t('modal.sharePageModal.cancelBtnText')}</Button>
        </div>
      </div>
    </Modal>
  );
};
