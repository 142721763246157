import React from 'react';

import { IMenuHeader } from '../../../../models/IShared';
import { DesktopNavigationitem } from './DesktopNavigationitem/DesktopNavigationitem';
import styles from './DesktopNavigationList.module.scss';

type Props = {
  list: IMenuHeader[];
};

export const DesktopNavigationList: React.FC<Props> = ({ list }) => {
  return (
    <ul className={styles.list}>
      {list.map(item => (
        <DesktopNavigationitem key={item.pk} item={item} />
      ))}
    </ul>
  );
};
