import cn from 'classnames';
import { FieldAttributes, useField } from 'formik';

import styles from './FormInput.module.scss';

export const FormInput: React.FC<FieldAttributes<any>> = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);
  const isShowError = meta.touched && meta.error;

  return (
    <label className={cn(styles.formInputLabel, className)}>
      {label}
      <input className={styles.formInput} {...field} {...props} />
      {isShowError ? <div className={styles.formInputErrorText}>{meta.error}</div> : null}
    </label>
  );
};
