import { useTranslation } from 'react-i18next';

import { RecommendedVideoCourseIcon } from '../../../assets/svg/RecommendedVideoCourseIcon';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { ADD_TO_CART } from '../../../models/ICart';
import { RecommendationCourse, RecommendationProduct } from '../../../models/IShared';
import { RecommendationCard } from '../../RecommendationCard';
import { RecommendedMaterialsCarousel } from '../RecommendedMaterialsCarousel';
import styles from './RecommendedVideoCourses.module.scss';

export interface IProps {
  list: RecommendationCourse[];
  link: string;
  onFavorite: (item: RecommendationCourse | RecommendationProduct) => void;
  onCollection: (item: RecommendationCourse | RecommendationProduct) => () => void;
  customTitle?: string;
}

export const RecommendedVideoCourses: React.FC<IProps> = ({
  list,
  link,
  onFavorite,
  onCollection,
  customTitle,
}) => {
  const { t } = useTranslation();

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Add to cart start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { addToCart } = useAddToCart({});
  const onAddToShoppingCart = (course: RecommendationCourse) =>
    addToCart({ id: course.pk, body: { type: ADD_TO_CART.COURSE } });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Add to cart end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  return (
    <RecommendedMaterialsCarousel
      desktopTitle={t('recommended.videoCourses')}
      mobileTitle={t('recommended.videoCoursesTitle')}
      icon={<RecommendedVideoCourseIcon className={styles.videoCourseIcon} />}
      className={styles.videoCoursesCarousel}
      customTitle={customTitle}
      showCommonTitle
    >
      {list?.map(card => (
        <RecommendationCard
          key={card.pk}
          card={card}
          subjectFilterLink={`${link}/?subjects=${card?.subject_title?.pk}`}
          ageFilterLink={`${link}/?ages=${card?.ages?.pk}`}
          onFavorite={onFavorite}
          onCollection={onCollection}
          onAddToShoppingCart={onAddToShoppingCart}
          lessonsAmount={card?.lessons_amount}
          practiceAmount={card?.practice_amount}
          cardLink={`/course/${card?.pk}`}
          type="COURSE"
        />
      ))}
    </RecommendedMaterialsCarousel>
  );
};
