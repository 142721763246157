export const FavoriteIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99998 14.2349C7.7722 14.2349 7.5526 14.1523 7.38146 14.0024C6.73509 13.4373 6.11193 12.9061 5.56212 12.4376L5.55932 12.4352C3.94738 11.0615 2.55542 9.87524 1.58691 8.70667C0.504272 7.40027 0 6.16162 0 4.80847C0 3.49377 0.450805 2.28088 1.26928 1.39307C2.09753 0.494751 3.234 0 4.46972 0C5.3933 0 6.23912 0.291992 6.98363 0.867798C7.35936 1.15845 7.69994 1.51416 7.99998 1.92908C8.30015 1.51416 8.64061 1.15845 9.01646 0.867798C9.76097 0.291992 10.6068 0 11.5304 0C12.766 0 13.9026 0.494751 14.7308 1.39307C15.5493 2.28088 16 3.49377 16 4.80847C16 6.16162 15.4958 7.40027 14.4132 8.70654C13.4447 9.87524 12.0528 11.0614 10.4411 12.4349C9.89036 12.9042 9.26622 13.4362 8.61839 14.0027C8.44737 14.1523 8.22765 14.2349 7.99998 14.2349ZM4.46972 0.937256C3.49889 0.937256 2.60705 1.32471 1.95825 2.02832C1.2998 2.74255 0.937132 3.72986 0.937132 4.80847C0.937132 5.94653 1.3601 6.96436 2.30847 8.10864C3.22509 9.21472 4.58849 10.3766 6.1671 11.7219L6.17003 11.7244C6.72191 12.1947 7.34752 12.7279 7.99864 13.2972C8.65367 12.7268 9.28026 12.1927 9.83323 11.7217C11.4117 10.3763 12.775 9.21472 13.6916 8.10864C14.6399 6.96436 15.0628 5.94653 15.0628 4.80847C15.0628 3.72986 14.7002 2.74255 14.0417 2.02832C13.393 1.32471 12.5011 0.937256 11.5304 0.937256C10.8192 0.937256 10.1662 1.16333 9.5897 1.60913C9.07591 2.00659 8.718 2.50903 8.50816 2.8606C8.40025 3.04138 8.21031 3.14929 7.99998 3.14929C7.78966 3.14929 7.59972 3.04138 7.49181 2.8606C7.28209 2.50903 6.92418 2.00659 6.41027 1.60913C5.83373 1.16333 5.18078 0.937256 4.46972 0.937256Z"
        fill="#717171"
      />
    </svg>
  );
};
