import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { useSetSearchParamsNotSavedInHistory } from '../../hooks/useSetSearchParamsNotSavedInHistory';
import { useUrlParams } from '../../hooks/useUrlParams';
import { ITag } from '../../models/IBlog';
import { PAGES_ENUM } from '../../utils/constants';
import styles from './PopularTags.module.scss';

interface IProps {
  tags?: ITag[];
  className?: string;
  link?: string;
  isTitleNeeded?: boolean;
  isPageRedirectByClickTags?: boolean;
  page?: PAGES_ENUM;
}

export const changeTags = (tag: ITag) => (pre: ITag[]) =>
  pre.some(c => c.id === tag.id) ? pre.filter(c => c.id !== tag.id) : [...pre, tag];

export const PopularTags: React.FC<IProps> = ({
  tags = [],
  className,
  link,
  isTitleNeeded = true,
  isPageRedirectByClickTags = false,
}) => {
  const { t } = useTranslation();

  const { setSearchParamsNotSavedInHistory } = useSetSearchParamsNotSavedInHistory();

  const [searchParams] = useSearchParams();
  const { params } = useUrlParams();

  const urlTagsId = searchParams.getAll('tags');
  const initSelectedTags = tags.filter(tag => urlTagsId.includes(String(tag.id)));

  const [selectedTags, setSelectedTags] = useState(initSelectedTags);

  useEffect(() => {
    if (link && selectedTags?.length) {
      const tagsId = selectedTags[0]?.id;

      setSearchParamsNotSavedInHistory({ ...params, tags: tagsId });
    }
  }, [searchParams]);

  if (tags.length === 0) return null;

  const onTag = (tag: ITag) => () => {
    const tagId = String(tag?.id);
    let tagsId = [...urlTagsId];
    const isHaveTagInArray = tagsId.includes(tagId);

    tagsId = isHaveTagInArray ? tagsId.filter(c => c !== tagId) : [...tagsId, tagId];
    setSearchParamsNotSavedInHistory({ ...params, tags: tagsId, page: '1' });
    setSelectedTags(changeTags(tag));
  };

  const getTagClsn = (tag: ITag) =>
    cn(styles.tagBtn, {
      [styles.selectedTag]: selectedTags.some(currTag => currTag?.id === tag?.id),
    });

  return (
    <div className={cn(styles.sidebarBox, className)}>
      {isTitleNeeded && (
        <div className={styles.sidebarTitleBox}>
          <h4 className={styles.sidebarTitle}>{t('tag.title')}</h4>
        </div>
      )}
      <div className={styles.sidebarBoxBody}>
        <div className={styles.tags}>
          {tags?.map(tag => (
            <Fragment key={tag?.id}>
              {isPageRedirectByClickTags ? (
                <Link to={`${link}/?tags=${tag?.id}`} className={getTagClsn(tag)}>
                  <i className={cn('icofont-search', styles.sidebarSearchIcon)}></i>
                  {tag.name}
                </Link>
              ) : (
                <div className={getTagClsn(tag)} onClick={onTag(tag)}>
                  <i className={cn('icofont-search', styles.sidebarSearchIcon)}></i>
                  {tag.name}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
