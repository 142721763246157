export const declineString = (num: number, strings: string[]) => {
  if (!num && num !== 0) return;

  let output = num.toString();

  if (num === 1) {
    output = strings[0];
  } else if (num < 5 && num !== 0) {
    output = strings[1];
  } else {
    output = strings[2];
  }

  return output;
};
