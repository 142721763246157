import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './ProductAndPriceInfo.module.scss';

type Props = {
  title: string;
  price: number;
  discountPrice: number;
  authorLink: string;
  author: string;
  ageInfo: string;
  isPurchased: boolean;
  className?: string;
};

export const ProductAndPriceInfo: React.FC<Props> = ({
  title,
  price,
  discountPrice,
  authorLink,
  author,
  ageInfo,
  className,
  isPurchased,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.productInfoContainer, className)}>
      <div className={styles.productTitleAndPrice}>
        <h2 className={styles.productTitle}>{title}</h2>
        {!isPurchased && (
          <div className={styles.productPrices}>
            <div
              className={cn(styles.productPrice, {
                [styles.productPriceCrossedOut]:
                  discountPrice || (discountPrice === 0 && price !== 0),
              })}
            >
              {price}&#8372;
            </div>
            {(discountPrice || (discountPrice === 0 && price !== 0)) && (
              <div className={styles.productDiscountPrice}>{discountPrice}&#8372;</div>
            )}
          </div>
        )}
      </div>
      <div className={styles.productAuthorAndAgeInfo}>
        <Link to={authorLink} className={styles.productAuthor}>
          {t('product.authorTitle')} {author}
        </Link>
        <div className={styles.productYears}>{ageInfo}</div>
      </div>
    </div>
  );
};
