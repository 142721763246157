import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditIcon } from '../../../../../assets/svg/EditIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { useEditCollectionMutation } from '../../../../../services/collections';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Modal } from '../../../Modal';
import { deleteModal } from '../../../ModalSlice';
import { CollectionModalLayout } from '../CollectionModalLayout';
import styles from './EditCollectionModal.module.scss';

type TE = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  name: string;
  description: string;
  id: number;
}

export const EditCollectionModal: React.FC<IProps> = ({ name, description, id }) => {
  const { t } = useTranslation();
  const [edit, resEditCollection] = useEditCollectionMutation();

  const [form, setForm] = useState({ name, description });
  const formData = new FormData();

  const dispatch = useTypedDispatch();

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onChangeForm = (e: TE) => {
    const { name, value } = e.target;

    setForm(pre => ({ ...pre, [name]: value }));
  };

  const onEdit = (e: React.FormEvent<HTMLFormElement>) => {
    Object.entries(form).forEach(([key, value]) => {
      if (key && value) {
        formData.append(key, value);
      }
    });
    edit({ body: formData, id });
    e.preventDefault();
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resEditCollection.isSuccess,
    textSuccess: t('collections.modals.editCollection.successNotificationText'),
    afterCreate: () => dispatch(deleteModal()),
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resEditCollection.isError,
    error: resEditCollection.error as ErrorNode,
  });

  return (
    <Modal ariaLabel={t('collections.modals.editCollection.title')}>
      <CollectionModalLayout
        title={t('collections.modals.editCollection.title')}
        icon={<EditIcon className={styles.editCollectionIcon} />}
      >
        <form className={styles.editCollectionForm}>
          <div className={styles.editCollectionFormInputs}>
            <input
              className={styles.editCollectionFormInput}
              type="text"
              name="name"
              placeholder={t('collections.modals.editCollection.nameFieldPlaceholder')}
              required
              value={form.name}
              onChange={onChangeForm}
            />
            <input
              className={styles.editCollectionFormInput}
              type="text"
              name="description"
              placeholder={t('collections.modals.editCollection.descriptionFieldPlaceholder')}
              value={form.description}
              onChange={onChangeForm}
            />
          </div>
          <div className={styles.editCollectionFormButtons}>
            <Button className={styles.editCollectionFormButton} onClick={onClose} isOrange>
              {t('collections.modals.cancelBtnText')}
            </Button>
            <Button className={styles.editCollectionFormButton} onClick={onEdit}>
              {t('collections.modals.confirmBtnText')}
            </Button>
          </div>
        </form>
      </CollectionModalLayout>
    </Modal>
  );
};
