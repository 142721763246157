import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { useGetSearchAgesCategoryQuery } from '../../../services/shared';
import { PAGES_ENUM } from '../../../utils/constants';
import { Button } from '../../Button';
import styles from './SearchPopup.module.scss';
import { Selects } from './Selects';
import { SelectsAges } from './SelectsAges';

interface IProps {
  onClose: () => void;
  search: string;
}

export const SearchPopup: React.FC<IProps> = ({ onClose, search }) => {
  const { t } = useTranslation();
  const popupRef = useRef<HTMLDivElement | null>(null);
  const stringToPage = getTranslatedStringToPage(t);

  useClickOutside(popupRef, null, onClose);

  const { data: dataAgesCategories = [], isFetching: isFetchingAgesCategory } =
    useGetSearchAgesCategoryQuery('');

  const [selectedPage, setSelectedPage] = useState('');
  const [selectedYear, setSelectedYear] = useState<number>(undefined);

  const navigate = useNavigate();

  const onChangeSelectPage = (selected: string) => {
    setSelectedPage(selected);
  };

  const onChangeSelectYears = (selected: number) => {
    setSelectedYear(selected);
  };

  const params = new URLSearchParams();

  selectedYear && params.set('ages', String(selectedYear));
  search && params.set('search', search);

  const onClickSearchBtn = () => {
    navigate({
      pathname: stringToPage[selectedPage],
      search: `?${params}`,
    });
    onClose();
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClickSearchBtn();
    }
  };

  const isShowSelectsAges = dataAgesCategories.length !== 0 && !isFetchingAgesCategory;

  return (
    <div className={styles.container} ref={popupRef} onKeyDown={onKeyDown} tabIndex={0}>
      <div className={styles.title}>{t('searchPopup.titlePlace')}?</div>

      <Selects
        onSelect={onChangeSelectPage}
        list={Object.keys(stringToPage)}
        classNameItem={styles.page}
      />

      {isShowSelectsAges && (
        <>
          <div className={styles.title}>{t('searchPopup.titleAge')}?</div>

          <SelectsAges
            onSelect={onChangeSelectYears}
            list={dataAgesCategories}
            classNameItem={styles.year}
          />
        </>
      )}

      <div className={styles.wrapperBtn}>
        <Button onClick={onClickSearchBtn} className={styles.btn}>
          {t('searchPopup.btn')}
        </Button>
      </div>
    </div>
  );
};

const getTranslatedStringToPage = (t: (key: string) => string): Record<string, string> => ({
  [t('searchPopup.pageKeys.COURSES')]: PAGES_ENUM.COURSES,
  [t('searchPopup.pageKeys.PRODUCTS')]: PAGES_ENUM.PRODUCTS,
  [t('searchPopup.pageKeys.BLOG')]: PAGES_ENUM.BLOG,
  [t('searchPopup.pageKeys.ONLINE_LESSONS')]: PAGES_ENUM.ONLINE_LESSONS,
});
