import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './assets/i18n/en.json';
import translationUK from './assets/i18n/uk.json';
import { getLanguageFromLocalStorage } from './services/storage';

// This was added for old versions of browsers that can support "userLanguage".
interface NavigatorWithUserLanguage extends Navigator {
  userLanguage?: string;
}

const detectLanguage = (): string => {
  const nav = navigator as NavigatorWithUserLanguage;
  const detectedLanguage = nav.language || nav.userLanguage;

  if (detectedLanguage?.startsWith('ru') || detectedLanguage?.startsWith('uk')) {
    return 'uk';
  }

  return 'en';
};

const languageFromLocalStorage = getLanguageFromLocalStorage() || detectLanguage();

localStorage.setItem('language', languageFromLocalStorage);

i18n.use(initReactI18next).init({
  // lng: languageFromLocalStorage,
  lng: 'uk',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: translationEN,
    },
    uk: {
      translation: translationUK,
    },
  },
  fallbackLng: 'uk',
});

export default i18n;
