import React from 'react';

import { Carousel } from '../../../../../components/Carousel';
import { IHomeCarousel } from '../../../../../models/IHome';
import styles from './FacilityCarousel.module.scss';

export const FacilityCarousel: React.FC<IHomeCarousel> = ({ children }) => {
  const settings = {
    dots: true,
    variableWidth: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Carousel
      className={styles.facilityCarouselContainer}
      settings={settings}
      areDotsVisible
      onlyActiveSlidesVisible={false}
    >
      {children}
    </Carousel>
  );
};
