import Tippy from '@tippyjs/react';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import defaultCardImage from '../../assets/img/default-preview.png';
import { SaveIcon } from '../../assets/svg/SaveIcon';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { RecommendationCourse, RecommendationProduct } from '../../models/IShared';
import { selectIsAuth } from '../../redux/Auth';
import { Button } from '../Button';
import { CurriculumSummary } from '../CurriculumSummary';
import { Favorite } from '../Favorite/Favorite';
import styles from './RecommendationCard.module.scss';

type Props = {
  onFavorite: (item: RecommendationCourse | RecommendationProduct) => void;
  onCollection: (item: RecommendationCourse | RecommendationProduct) => () => void;
  onAddToShoppingCart: (item: RecommendationCourse | RecommendationProduct) => void;
  card: RecommendationCourse | RecommendationProduct;
  subjectFilterLink: string;
  ageFilterLink: string;
  lessonsAmount?: number;
  practiceAmount?: number;
  taskCount?: number;
  cardLink: string;
  type: 'PRODUCT' | 'COURSE';
};

export const RecommendationCard: FC<Props> = ({
  card,
  subjectFilterLink,
  ageFilterLink,
  onFavorite,
  onCollection,
  onAddToShoppingCart,
  cardLink,
}) => {
  const {
    subject_title,
    preview,
    title,
    price,
    discount_price,
    ages,
    is_favorite,
    in_collection,
    creator,
    product_card_block,
    is_purchased,
  } = card || {};
  const taskBlock = product_card_block?.slice(0, 2);
  const [isFavorite, setIsFavorite] = useState(is_favorite);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAuthToken = useTypedSelector(selectIsAuth);

  // >>>>>>>>>>> Favorite start <<<<<<<<<<<

  useEffect(() => {
    setIsFavorite(is_favorite);
  }, [is_favorite]);

  const onWillFavorite = () => {
    setIsFavorite(pre => !pre);
    onFavorite(card);
  };

  // >>>>>>>>>>> Favorite end <<<<<<<<<<<

  const onWillAddToCollection = () => onCollection && onCollection(card)();

  const handleCardClick = () => {
    if (cardLink) {
      navigate(cardLink);
    }
  };

  return (
    <div className={styles.recommendationCard} onClick={handleCardClick}>
      <div className={styles.recommendationCardContent}>
        <div className={styles.recommendationCardPriceAndImageWrapper}>
          {(price || price === 0) && (
            <div className={styles.recommendationCardPriceWrapper}>
              <div
                className={cn(styles.recommendationCardOldPrice, {
                  [styles.recommendationCardOldPriceCrossedOut]:
                    discount_price || (discount_price === 0 && price !== 0),
                })}
              >
                {price}&#8372;
              </div>
              {(discount_price || (discount_price === 0 && price !== 0)) && (
                <div className={styles.recommendationCardDiscountPrice}>
                  {discount_price}&#8372;
                </div>
              )}
            </div>
          )}
          <img
            src={preview || defaultCardImage}
            alt={t('recommended.recommendationImageAltText')}
          />
        </div>
        {product_card_block?.length > 0 && (
          <div className={styles.recommendationCardCurriculumInfo}>
            <ul className={styles.recommendationCardCurriculumList}>
              {product_card_block.map(task => {
                const { title, pk } = task;
                const num = parseInt(title) || '';

                return (
                  <li key={pk} className={styles.recommendationCardCurriculumItem}>
                    <strong>{num}</strong>
                    {title.slice(num.toString().length)}
                  </li>
                );
              })}
            </ul>
            {!is_purchased && (
              <Button
                className={styles.recommendationCardCartBtn}
                isOrange
                onClick={e => {
                  e.stopPropagation();
                  onAddToShoppingCart(card);
                }}
              >
                {t('recommended.cartBtnText')}
              </Button>
            )}
          </div>
        )}
        <div className={styles.recommendationCardInfo}>
          <div className={styles.recommendationCardSubjectAndBtnsWrapper}>
            <Link
              to={subjectFilterLink}
              className={styles.recommendationCardSubjectLink}
              onClick={e => e.stopPropagation()}
            >
              {subject_title?.name}
            </Link>
            {isAuthToken && (
              <div className={styles.recommendationCardBtnsWrapper}>
                <Tippy
                  content={
                    in_collection ? t('common.deleteFromCollection') : t('common.addToCollection')
                  }
                >
                  <button
                    className={styles.recommendationCardSaveBtn}
                    onClick={e => {
                      e.stopPropagation();
                      onWillAddToCollection();
                    }}
                    aria-label={
                      in_collection ? t('common.deleteFromCollection') : t('common.addToCollection')
                    }
                  >
                    <SaveIcon className={cn({ [styles.saveIconColored]: in_collection })} />
                  </button>
                </Tippy>
                <Tippy
                  content={
                    isFavorite ? t('common.removeFromFavorites') : t('common.addToFavorites')
                  }
                >
                  <button
                    className={styles.recommendationCardFavoriteBtn}
                    onClick={e => {
                      e.stopPropagation();
                      onWillFavorite();
                    }}
                    aria-label={
                      isFavorite ? t('common.removeFromFavorites') : t('common.addToFavorites')
                    }
                  >
                    <Favorite isLike={isFavorite} />
                  </button>
                </Tippy>
              </div>
            )}
          </div>
          <div className={styles.recommendationCardTitle}>{title}</div>
          <div className={styles.recommendationCardFooterWrapper}>
            <CurriculumSummary taskBlock={taskBlock} className={styles.curriculumSummary} />
            <div className={styles.recommendationCardAuthorAndAgeInfo}>
              <div className={styles.recommendationCardAuthorInfo}>
                {t('recommended.authorText')} {creator.full_name}
              </div>
              <Link to={ageFilterLink} onClick={e => e.stopPropagation()}>
                {ages.name}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
