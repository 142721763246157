import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { USER_ROLE } from '../../../../models/IShared';
import { selectUser } from '../../../../redux/Auth';
import { useSendDestroyLinkMutation } from '../../../../services/myProfile';
import { handleError } from '../../../../utils/notifications';
import { Button } from '../../../Button';
import { Modal } from '../../Modal';
import styles from './DeleteAccountModal.module.scss';

type Props = {
  description: string;
  role: USER_ROLE;
  email: string;
  childId: number;
};

export const DeleteAccountModal: FC<Props> = ({ description, role, email, childId }) => {
  const [isDeletionConfirmed, setIsDeletionConfirmed] = useState(false);
  const [sendDestroyLink, { isLoading }] = useSendDestroyLinkMutation();
  const userId = useTypedSelector(selectUser)?.pk;
  const { t } = useTranslation();

  const handleDeletionConfirm = async () => {
    if (userId || childId) {
      try {
        await sendDestroyLink({
          email,
          role: childId ? String(USER_ROLE.STUDENT) : String(role),
          destroy_id: childId ? childId : userId,
        }).unwrap();
        setIsDeletionConfirmed(true);
      } catch (error) {
        handleError(t('errors.DELETE_ACCOUNT_FAILED'));
      }
    }
  };

  return (
    <Modal ariaLabel={t('deleteAccount.deleteAccountModal.title')}>
      <div className={styles.deleteAccountModal}>
        <h3>{t('deleteAccount.deleteAccountModal.title')}</h3>
        {isDeletionConfirmed ? (
          childId ? (
            <p className={styles.deleteAccountDescription}>
              {t('deleteAccount.deleteAccountModal.childAccountDeletionText')}
            </p>
          ) : (
            <p className={styles.deleteAccountDescription}>
              {t('deleteAccount.deleteAccountModal.deleteAccountConfirmText')}
            </p>
          )
        ) : (
          <>
            <p className={styles.deleteAccountDescription}>{description}</p>
            <Button
              className={styles.confirmBtn}
              onClick={handleDeletionConfirm}
              disabled={isLoading}
            >
              {t('deleteAccount.deleteAccountModal.confirmBtnText')}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
