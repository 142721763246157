import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { useStartProductMutation } from '../../../../services/product';
import { Button } from '../../../Button';
import { Modal } from '../../Modal';
import { deleteModal } from '../../ModalSlice';
import styles from './ProductProgressWarningModal.module.scss';

type Props = {
  productId: string;
  token: string;
};

export const ProductProgressWarningModal: React.FC<Props> = ({ productId, token }) => {
  const dispatch = useTypedDispatch();
  const [startProduct] = useStartProductMutation();
  const { t } = useTranslation();
  const editorLink = `${process.env.REACT_APP_EDITOR_HOST}?productId=${productId}&token=${token}`;

  const handleStartProductBtnClick = async () => {
    try {
      const data = await startProduct(productId).unwrap();

      if (data?.status === true) {
        const linkElement = document.createElement('a');

        linkElement.setAttribute('target', '_blank');
        linkElement.setAttribute('rel', 'noopener noreferrer');
        linkElement.href = editorLink;
        linkElement.click();
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(deleteModal());
    }
  };

  const handleContinueButtonClick = () => {
    const linkElement = document.createElement('a');

    linkElement.setAttribute('target', '_blank');
    linkElement.setAttribute('rel', 'noopener noreferrer');
    linkElement.href = editorLink;
    linkElement.click();
    dispatch(deleteModal());
  };

  return (
    <Modal
      ariaLabel={t('product.modalAriaLabelText.title')}
      contentClassName={styles.progressModal}
    >
      <div className={styles.progressModalContent}>
        <div className={styles.progressModalTitleAndDescriptionWrapper}>
          <div className={styles.progressModalTitle}>{t('product.progressWarningModal.title')}</div>
          <p>{t('product.progressWarningModal.description')}</p>
        </div>
        <div className={styles.progressModalBtnsContainer}>
          <Button isBlue className={styles.progressModalBtn} onClick={handleStartProductBtnClick}>
            {t('product.progressWarningModal.startAgainBtnText')}
          </Button>
          <Button onClick={handleContinueButtonClick} isOrange className={styles.progressModalBtn}>
            {t('product.continueTheTaskBtnText')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
