import cn from 'classnames';
import React from 'react';

import styles from './CurriculumSummary.module.scss';

interface ICurriculumSummary {
  taskBlock: { pk: number; title: string }[];
  className?: string;
}

export const CurriculumSummary: React.FC<ICurriculumSummary> = ({ taskBlock, className }) => {
  return (
    <div className={cn(styles.taskBlock, className)}>
      {taskBlock?.map(block => {
        const { title, pk } = block;
        const num = parseInt(title) || '';

        return (
          <div key={pk} className={styles.taskInfo}>
            <div className={styles.taskCircle} />
            <div className={styles.taskTitle}>
              {num && <strong>{num}</strong>}
              <p>{title.slice(num.toString().length)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
