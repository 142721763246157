import cn from 'classnames';
import React from 'react';

import styles from './GearButton.module.scss';

type Props = {
  onClick: () => void;
  className?: string;
};

export const GearButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <button onClick={onClick} className={cn(styles.gearButton, className)}>
      <i className={cn('icofont-gear', styles.gearButtonIcon)}></i>
    </button>
  );
};
