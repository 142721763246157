import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const TelegramIcon = (props: IIcon) => {
  return (
    <Icon width="48" height="42" viewBox="0 0 48 42" fill="none" {...props}>
      <path
        d="M0.847823 20.0391L11.908 24.1671L16.189 37.9347C16.4629 38.8164 17.5411 39.1424 18.2568 38.5572L24.4219 33.5313C25.0682 33.0048 25.9887 32.9785 26.664 33.4688L37.7837 41.5419C38.5493 42.0984 39.634 41.6788 39.826 40.7537L47.9717 1.57112C48.1813 0.560555 47.1884 -0.282486 46.2261 0.0896743L0.834863 17.6003C-0.285299 18.0323 -0.275539 19.6182 0.847823 20.0391ZM15.499 21.9697L37.1147 8.65657C37.5032 8.418 37.9029 8.94329 37.5693 9.25273L19.7301 25.8351C19.103 26.4188 18.6986 27.1999 18.584 28.0478L17.9763 32.5512C17.8958 33.1524 17.0512 33.2121 16.8853 32.6302L14.5482 24.4182C14.2805 23.4815 14.6706 22.4811 15.499 21.9697Z"
        fill="white"
      />
    </Icon>
  );
};
