import { FC } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import styles from './ShareSocials.module.scss';

type Props = {
  title?: string;
};

export const ShareSocials: FC<Props> = ({ title = '' }) => {
  return (
    <div className={styles.shareSocials}>
      <FacebookShareButton url={window.location.href}>
        <FacebookIcon />
      </FacebookShareButton>
      <TelegramShareButton url={window.location.href} title={title}>
        <TelegramIcon />
      </TelegramShareButton>
      <WhatsappShareButton url={window.location.href} title={title}>
        <WhatsappIcon />
      </WhatsappShareButton>
      <TwitterShareButton url={window.location.href} title={title}>
        <TwitterIcon />
      </TwitterShareButton>
    </div>
  );
};
