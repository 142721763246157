export const getInitials = (str: string, count = 2) => {
  const words = str.split(' ');
  let initials = '';

  for (let i = 0; i < words.length && i < count; i++) {
    const word = words[i];

    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  }

  return initials;
};
