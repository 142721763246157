import { Loader } from '../../../../components/Loader';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import { useGetPageCommentsQuery } from '../../../../services/home';
import { Testimonial } from './Testimonial/Testimonial';
import styles from './Testimonials.module.scss';
import { TestimonialsCarousel } from './TestimonialsCarousel';

export const Testimonials = () => {
  const { data = [], isLoading } = useGetPageCommentsQuery('');
  const isData = data.length > 0;

  if (!isData) return null;

  if (isLoading) return <Loader />;

  return (
    <div className={styles.testimonials}>
      <MaxWidthContainer>
        <>
          {data.length > 1 ? (
            <TestimonialsCarousel>
              {data.map(testimonial => (
                <Testimonial key={testimonial.id} testimonial={testimonial} />
              ))}
            </TestimonialsCarousel>
          ) : (
            <Testimonial testimonial={data[0]} />
          )}
        </>
      </MaxWidthContainer>
    </div>
  );
};
