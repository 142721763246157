import { useTranslation } from 'react-i18next';

import emptyImage from '../../../../assets/img/coming-soon-articles.webp';
import { EmptyContentMessage } from '../../../../components/EmptyContentMessage';
import { Loader } from '../../../../components/Loader';
import { ITopPost } from '../../../../models/IHome';
import { useGetHomePostsQuery } from '../../../../services/home';
import { PAGES_ENUM } from '../../../../utils/constants';
import { HomeSectionLayout } from '../HomeSectionLayout';
import { OurPostCard } from './OurPostCard';
import styles from './OurPosts.module.scss';
import { OurPostsCarousel } from './OurPostsCarousel';

export const OurPosts = () => {
  const { data = { results: [] }, isLoading } = useGetHomePostsQuery('');
  const isData = data.results.length > 0;
  const { t } = useTranslation();

  if (isLoading) return <Loader />;

  return (
    <HomeSectionLayout
      title={t('home.ourBlog.title')}
      description={t('home.ourBlog.description')}
      buttonLink={isData ? PAGES_ENUM.BLOG : ''}
      buttonText={t('home.ourBlog.buttonText')}
    >
      {isData ? (
        <>
          <OurPostsCarousel>
            {data.results.map((post: ITopPost) => (
              <OurPostCard key={post.pk} post={post} />
            ))}
          </OurPostsCarousel>
          <div className={styles.ourPostsContentDesktop}>
            {data.results.map((post: ITopPost) => (
              <OurPostCard key={post.pk} post={post} />
            ))}
          </div>
        </>
      ) : (
        <EmptyContentMessage
          title={t('home.ourBlog.emptyContentMessage.title')}
          image={emptyImage}
        />
      )}
    </HomeSectionLayout>
  );
};
