import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const InstagramIcon = (props: IIcon) => {
  return (
    <Icon width="48" height="48" fill="none" {...props}>
      <g clipPath="url(#clip0_1219_71)">
        <path
          d="M37.9524 8.88086C37.31 8.88086 36.7871 9.4037 36.7871 10.0462C36.7871 10.6886 37.31 11.2115 37.9524 11.2115C38.595 11.2115 39.1177 10.6887 39.1177 10.0462C39.1177 9.40361 38.595 8.88086 37.9524 8.88086Z"
          fill="white"
        />
        <path
          d="M24.0008 13.6699C18.3044 13.6699 13.6699 18.3044 13.6699 24.0008C13.6699 29.6972 18.3044 34.3318 24.0008 34.3318C29.6973 34.3318 34.3318 29.6973 34.3318 24.0009C34.3318 18.3045 29.6973 13.6699 24.0008 13.6699Z"
          fill="white"
        />
        <path
          d="M34.8415 0H13.1585C5.90287 0 0 5.90288 0 13.1586V34.8415C0 42.0972 5.90287 48 13.1585 48H34.8415C42.0972 48 48 42.0971 48 34.8415V13.1586C48 5.90288 42.0972 0 34.8415 0ZM24 37.1655C16.7405 37.1655 10.8346 31.2595 10.8346 24C10.8346 16.7405 16.7406 10.8347 24 10.8347C31.2594 10.8347 37.1655 16.7406 37.1655 24C37.1655 31.2594 31.2594 37.1655 24 37.1655ZM37.9529 14.0471C35.7473 14.0471 33.953 12.2527 33.953 10.0472C33.953 7.84162 35.7473 6.04716 37.9529 6.04716C40.1585 6.04716 41.9528 7.84153 41.9528 10.0471C41.9528 12.2527 40.1585 14.0471 37.9529 14.0471Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1219_71">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
