import cn from 'classnames';
import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { FacebookIcon } from '../../../assets/svg/FacebookIcon';
import { InstagramIcon } from '../../../assets/svg/InstagramIcon';
import { LoginIcon } from '../../../assets/svg/LoginIcon';
import { LogoutIcon } from '../../../assets/svg/LogoutIcon';
import { PinterestIcon } from '../../../assets/svg/PinterestIcon';
import { ShoppingCartIcon } from '../../../assets/svg/ShoppingCartIcon';
import { TelegramIcon } from '../../../assets/svg/TelegramIcon';
import { UserIcon } from '../../../assets/svg/UserIcon';
import { YoutubeIcon } from '../../../assets/svg/YoutubeIcon';
import { useLogout } from '../../../hooks/useLogout';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { selectIsAuth, selectIsGoogleAuthenticate } from '../../../redux/Auth';
import { useGetProductsAndCoursesQuery } from '../../../services/cart';
import { PAGES_ENUM } from '../../../utils/constants';
import { MaxWidthContainer } from '../../MaxWidthContainer';
import { ProfilePopup } from '../ProfilePopup/ProfilePopup';
import styles from './TopHeader.module.scss';

type Props = {
  isProfilePopupOpened: boolean;
  onOpenProfilePopup: Dispatch<SetStateAction<boolean>>;
};

export const TopHeader: FC<Props> = ({ onOpenProfilePopup, isProfilePopupOpened }) => {
  const isAuth = useSelector(selectIsAuth);
  const { data: dataCart } = useGetProductsAndCoursesQuery('', {
    skip: !isAuth,
    refetchOnMountOrArgChange: true,
  });
  const productsAmount = dataCart?.result?.products?.length;
  const coursesAmount = dataCart?.result?.courses?.length;
  let totalAmount: number;

  if (!isNaN(productsAmount) && !isNaN(coursesAmount)) {
    totalAmount = productsAmount + coursesAmount;
  }

  const navigate = useNavigate();
  const isGoogleAuthenticate = useTypedSelector(selectIsGoogleAuthenticate);
  const { t } = useTranslation();

  const onShowProfilePopup = () => {
    onOpenProfilePopup(pre => !pre);
  };

  const onCloseProfilePopup = () => {
    onOpenProfilePopup(false);
  };

  const onLoginBtnClick = () => {
    navigate(PAGES_ENUM.SIGN_IN);
  };

  const { onLogout, onGoogleLogout } = useLogout();

  return (
    <div className={styles.headerTopContainer}>
      <MaxWidthContainer>
        <div className={styles.headerTop}>
          <div className={styles.headerTopTxt}>{t('header.topTxt')}</div>
          <nav className={styles.headerTopNav}>
            <a
              href="https://t.me/dityMaybutnyogo"
              className={styles.headerTopLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={t('header.telegramLinkAriaLabelText')}
            >
              <TelegramIcon className={cn(styles.headerTopTelegramIcon, styles.headerTopIcon)} />
            </a>
            <a
              href="https://www.facebook.com/ditymaybutnyogo/"
              className={styles.headerTopLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={t('header.facebookLinkAriaLabelText')}
            >
              <FacebookIcon className={cn(styles.headerTopFacebookIcon, styles.headerTopIcon)} />
            </a>
            <a
              href="https://instagram.com/ditymaybutnyogo"
              className={styles.headerTopLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={t('header.instagramLinkAriaLabelText')}
            >
              <InstagramIcon className={cn(styles.headerTopInstagramIcon, styles.headerTopIcon)} />
            </a>
            <a
              href="https://www.youtube.com/@ditymaybutnyogo"
              className={styles.headerTopLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={t('header.youtubeLinkAriaLabelText')}
            >
              <YoutubeIcon className={styles.headerTopIcon} />
            </a>
            <a
              href="https://www.pinterest.com/SkrinkaZnan/"
              className={styles.headerTopLink}
              rel="noopener noreferrer"
              target="_blank"
              aria-label={t('header.pinterestLinkAriaLabelText')}
            >
              <PinterestIcon className={styles.headerTopIcon} />
            </a>
            {isProfilePopupOpened && <ProfilePopup onClose={onCloseProfilePopup} />}

            {isAuth ? (
              <>
                <NavLink
                  to="#"
                  className={styles.headerTopLink}
                  onClick={onShowProfilePopup}
                  aria-label={t('header.userProfilePopupArialLabelText')}
                >
                  <UserIcon className={cn(styles.headerTopUserIcon, styles.headerTopIcon)} />
                </NavLink>

                <NavLink
                  to={PAGES_ENUM.CART}
                  className={styles.headerTopLink}
                  aria-label={t('header.cartLinkArialLabelText')}
                >
                  <ShoppingCartIcon
                    className={cn(styles.headerTopShoppingCartIcon, styles.headerTopIcon)}
                  />
                  {!!totalAmount && (
                    <span className={styles.headerTopCartItems}>{totalAmount}</span>
                  )}
                </NavLink>
                <button
                  className={styles.headerTopLink}
                  onClick={isGoogleAuthenticate ? onGoogleLogout : onLogout}
                  aria-label={t('header.logOutBtnArialLabeltext')}
                >
                  <LogoutIcon className={cn(styles.headerTopUserIcon, styles.headerTopIcon)} />
                </button>
              </>
            ) : (
              <button
                className={styles.headerTopLink}
                onClick={onLoginBtnClick}
                aria-label={t('header.logInBtnArialLabeltext')}
              >
                <LoginIcon className={styles.headerTopIcon} />
              </button>
            )}
          </nav>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
