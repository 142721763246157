import React from 'react';
import { useTranslation } from 'react-i18next';

import emptyImage from '../../../../../assets/img/no-collections.webp';
import { ICollectionForProduct } from '../../../../../models/ICollections';
import { EmptyContentMessage } from '../../../../EmptyContentMessage';
import { CollectionsItem } from './CollectionsItem';
import styles from './CollectionsList.module.scss';

interface IProps {
  list: ICollectionForProduct[];
  onCheck: (collectionId: number) => () => void;
}

export const CollectionsList: React.FC<IProps> = ({ list, onCheck }) => {
  const { t } = useTranslation();
  const isEmptyCollectionList = list.length === 0;

  return (
    <>
      {isEmptyCollectionList ? (
        <EmptyContentMessage
          title={t('collections.collectionsPage.emptyContentMessage.title')}
          image={emptyImage}
          className={styles.collectionsEmptyContent}
        />
      ) : (
        <ul className={styles.collectionsList}>
          {!isEmptyCollectionList &&
            list.map(collection => (
              <CollectionsItem
                key={collection.pk}
                name={collection.name}
                is_product_in={collection.is_product_in}
                isPersonal={collection.is_personal}
                onCheck={onCheck(collection.pk)}
              />
            ))}
        </ul>
      )}
    </>
  );
};
