import { useEffect, useRef, useState } from 'react';

import { useTypedDispatch } from '../../hooks/useTypedDispatch';
import { deleteModal } from './ModalSlice';

export const useModalVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useTypedDispatch();
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onClose = () => {
    setIsVisible(false);

    if (timerRef?.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      dispatch(deleteModal());
    }, 300);
  };

  useEffect(() => {
    setIsVisible(true);

    return () => {
      if (timerRef?.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { isVisible, onClose };
};
