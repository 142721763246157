import { useEffect, useRef, useState } from 'react';

export const useVisible = () => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementRef.current && observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return { elementRef, isVisible };
};
