import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateCollectionIcon } from '../../../../../assets/svg/CreateCollectionIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { useCreateCollectionMutation } from '../../../../../services/collections';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Modal } from '../../../Modal';
import { deleteModal, setModal } from '../../../ModalSlice';
import { ModalTypes } from '../../../types/enums/ModalTypes';
import { CollectionModalLayout } from '../CollectionModalLayout';
import styles from './CreateCollectionModal.module.scss';

type TE = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  afterSuccessFunc?: {
    modalType: ModalTypes.SAVE_TO_COLLECTIONS;
    modalProps: { id: number; typeModal: string };
  } | null;
}

export const CreateCollectionModal: React.FC<IProps> = ({ afterSuccessFunc = null }) => {
  const { t } = useTranslation();
  const [create, resCreateCollection] = useCreateCollectionMutation();
  const [form, setForm] = useState({ name: '', description: '' });
  const formData = new FormData();

  const onChangeForm = (e: TE) => {
    const { name, value } = e.target;

    setForm(pre => ({ ...pre, [name]: value }));
  };

  const dispatch = useTypedDispatch();

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onCreate = (e: React.FormEvent<HTMLFormElement>) => {
    Object.entries(form).forEach(([key, value]) => {
      if (key && value) {
        formData.append(key, value);
      }
    });

    create(formData);
    e.preventDefault();
  };

  const afterSuccessOpenModal = () => {
    afterSuccessFunc && dispatch(setModal(afterSuccessFunc));
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resCreateCollection.isSuccess,
    textSuccess: t('collections.modals.createCollection.successNotificationText'),
    afterCreate: afterSuccessFunc ? afterSuccessOpenModal : onClose,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resCreateCollection.isError,
    error: resCreateCollection.error as ErrorNode,
  });

  return (
    <Modal
      ariaLabel={t('collections.modals.createCollection.title')}
      contentClassName={styles.createCollectionModal}
    >
      <CollectionModalLayout
        title={t('collections.modals.createCollection.title')}
        icon={<CreateCollectionIcon className={styles.createCollectionIcon} />}
      >
        <form className={styles.createCollectionForm} onSubmit={onCreate}>
          <div className={styles.createCollectionFormInputs}>
            <input
              className={styles.createCollectionFormInput}
              type="text"
              name="name"
              placeholder={t('collections.modals.createCollection.nameFieldPlaceholder')}
              required
              value={form.name}
              onChange={onChangeForm}
            />
            <input
              className={styles.createCollectionFormInput}
              type="text"
              name="description"
              placeholder={t('collections.modals.createCollection.descriptionFieldPlaceholder')}
              value={form.description}
              onChange={onChangeForm}
            />
          </div>
          <Button type="submit" className={styles.createCollectionFormButton} isOrange>
            {t('collections.modals.createCollection.createBtnText')}
          </Button>
        </form>
      </CollectionModalLayout>
    </Modal>
  );
};
