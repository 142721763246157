import { useTranslation } from 'react-i18next';

import defaultFeatureIcon from '../../../../assets/img/feat-icon-1.png';
import welcomeImage from '../../../../assets/img/welcome-block-image.webp';
import { Loader } from '../../../../components/Loader';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import { IWelcomeTextItem } from '../../../../models/IHome';
import { useGetWelcomeTextQuery } from '../../../../services/home';
import { getBackgroundAndBoxShadowByClassName } from '../../../../utils/helpers';
import styles from './Welcome.module.scss';

export const Welcome = () => {
  const { data = [], isLoading } = useGetWelcomeTextQuery('');
  const { t } = useTranslation();

  if (isLoading) return <Loader />;

  return (
    <div className={styles.welcome}>
      <MaxWidthContainer>
        <div className="row g-0 align-items-start">
          <div className="col-xl-8 col-lg-7">
            <div className={styles.welcomeContent}>
              <div className={styles.welcomeHeading}>
                <h2 className={styles.welcomeTitle}>
                  {t('home.welcome.titleFirstPart')}{' '}
                  <span>{t('home.welcome.titleSecondPart')}</span>
                </h2>
                <p className={styles.welcomeDescription}>{t('home.welcome.description')}</p>
              </div>
              <Features list={data} />
            </div>
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className={styles.welcomeImageContainer}>
              <img src={welcomeImage} alt="welcome" width="376" height="470" />
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

interface IFeatures {
  list: IWelcomeTextItem[];
}

const Features: React.FC<IFeatures> = ({ list }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.welcomeFeatureList}>
      {list.map((feature, idx) => {
        const { background, boxShadow } = getBackgroundAndBoxShadowByClassName(
          feature.gradient_type
        );

        return (
          <div key={idx} className={styles.welcomeFeatureItem}>
            <div className={styles.welcomeFeatureBox}>
              <div className={styles.welcomeFeaturePartIcon}>
                <img
                  src={feature?.icon || defaultFeatureIcon}
                  alt={t('home.welcome.featureImageAltText')}
                  style={{ boxShadow }}
                />
              </div>
              <div className={styles.welcomeFeatureTxt}>
                <h3 className={styles.welcomeFeatureTitle}>{feature?.title}</h3>
                <div className={styles.welcomeDivider} style={{ background }}></div>
                <p className={styles.welcomeFeatureDescription}>{feature?.description}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
