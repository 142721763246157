import * as I from '../models/IProducts';
import * as IS from '../models/IShared';
import { rootApi } from './rootApi';

export const productApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getProduct: builder.query<I.ISingleProduct, string>({
      query: id => ({
        url: `/shop/get-single-product/${id}`,
      }),
    }),

    getRecommendedProductsBySingleProduct: builder.query<
      IS.IResponse<IS.RecommendationProduct[]>,
      string
    >({
      query: id => ({
        url: `/shop/get-product-recommendation/${id}`,
      }),
    }),

    getRecommendedCoursesBySingleProduct: builder.query<
      IS.IResponse<IS.RecommendationCourse[]>,
      string
    >({
      query: id => ({
        url: `/shop/get-opposite-product-recommendation/${id}`,
      }),
    }),

    getProductProgress: builder.query<I.IProductProgress, string>({
      query: id => ({
        url: `/shop/progress/${id}`,
      }),
    }),

    startProduct: builder.mutation<{ [key: string]: boolean }, string>({
      query: id => ({
        url: `/shop/start-product/${id}`,
        method: 'PUT',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductQuery,
  useGetRecommendedProductsBySingleProductQuery,
  useGetRecommendedCoursesBySingleProductQuery,
  useGetProductProgressQuery,
  useStartProductMutation,
} = productApi;
