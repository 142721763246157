import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPopularCourse } from '../../../models/ICourses';
import { PopularWrapper } from '../PopularWrapper';
import { PopularCourse } from './PopularCourse';

interface IProps {
  list: IPopularCourse[];
  link: string;
}

export const PopularCourses: React.FC<IProps> = ({ list, link }) => {
  const { t } = useTranslation();

  if (!list || list?.length === 0) return null;

  return (
    <PopularWrapper
      list={list}
      title={t('popularCourses.title')}
      renderItem={(course: IPopularCourse) => (
        <PopularCourse
          key={course.pk}
          course={course}
          link={`/course/${course?.pk}`}
        />
      )}
    />
  );
};
