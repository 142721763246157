enum CartInvalidationTags {
  CartProductsAndCourses = 'CartProductsAndCourses',
}

enum CollectionInvalidationTags {
  Collections = 'Collections',
  Collection = 'Collection',
  CollectionForProduct = 'CollectionForProduct',
  CollectionCourses = 'CollectionCourses',
  CollectionProducts = 'CollectionProducts',
}

enum HomeInvalidationTags {
  TopCourses = 'TopCourses',
  TopProducts = 'TopProducts',
}

export { CartInvalidationTags, CollectionInvalidationTags, HomeInvalidationTags };
