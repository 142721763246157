import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const SaveIcon = (props: IIcon) => {
  return (
    <Icon width="13" height="16" viewBox="0 0 12 16" fill="none" {...props}>
      <path
        d="M10.693 14.35L6.92091 11.4162C6.37925 10.9949 5.62075 10.9949 5.07909 11.4162L1.30697 14.35C0.978541 14.6055 0.5 14.3714 0.5 13.9554V1.33333C0.5 0.926546 0.890402 0.5 1.5 0.5H10.5C11.1096 0.5 11.5 0.926546 11.5 1.33333V13.9554C11.5 14.3714 11.0215 14.6055 10.693 14.35Z"
        fill="none"
        stroke="#717171"
      />
    </Icon>
  );
};
