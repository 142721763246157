import { FC } from 'react';

import { Modal } from '../../Modal';
import styles from './ShowCertificateModal.module.scss';

type Props = {
  src: string;
};

export const ShowCertificateModal: FC<Props> = ({ src }) => {
  return (
    <Modal ariaLabel="show certificate modal" contentClassName={styles.modalContentClassName}>
      <div className={styles.modalCertificateContainer}>
        <img src={src} alt="certificate" />
      </div>
    </Modal>
  );
};
