import React from 'react';

import productCheckIcon from '../../../assets/img/product-check.png';
import { IProductLessonShortContent } from '../../../models/IProducts';
import styles from './ProductTabs.module.scss';

interface IProps {
  list: IProductLessonShortContent[];
}

export const СontentTab: React.FC<IProps> = ({ list }) => {
  return (
    <div className={styles.wrapperContent}>
      {list.map((item, idx) => (
        <div key={idx} className={styles.wrapperContentItem}>
          <div className={styles.wrapperText}>
            {item?.order}. {item?.title}
          </div>
          {item?.checkbox_text && (
            <div className={styles.wrapperCheckedText}>
              <img src={productCheckIcon} width="23" height="23" alt="check mark" />
              {item.checkbox_text}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
