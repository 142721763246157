import { useTranslation } from 'react-i18next';

import { RecommendedBookIcon } from '../../../assets/svg/RecommendedBookIcon';
import { useAddToCart } from '../../../hooks/useAddToCart';
import { ADD_TO_CART } from '../../../models/ICart';
import { RecommendationCourse, RecommendationProduct } from '../../../models/IShared';
import { RecommendationCard } from '../../RecommendationCard';
import { RecommendedMaterialsCarousel } from '../RecommendedMaterialsCarousel';
import styles from './RecommendedBooks.module.scss';

export interface IProps {
  list: RecommendationProduct[];
  link: string;
  onFavorite: (item: RecommendationCourse | RecommendationProduct) => void;
  onCollection: (item: RecommendationCourse | RecommendationProduct) => () => void;
  customTitle?: string;
  isShowMobileCommonTitle?: boolean;
}

export const RecommendedBooks: React.FC<IProps> = ({
  list,
  link,
  onFavorite,
  onCollection,
  customTitle,
  isShowMobileCommonTitle,
}) => {
  const { t } = useTranslation();

  // Add to cart start

  const { addToCart } = useAddToCart({});
  const onAddToShoppingCart = (product: RecommendationProduct) => {
    addToCart({ id: product.pk, body: { type: ADD_TO_CART.PRODUCT } });
  };
  // Add to cart end

  return (
    <RecommendedMaterialsCarousel
      desktopTitle={t('recommended.products')}
      mobileTitle={t('recommended.productsTitle')}
      icon={<RecommendedBookIcon className={styles.recommendedBookIcon} />}
      className={styles.booksCarousel}
      customTitle={customTitle}
      showCommonTitle={isShowMobileCommonTitle}
    >
      {list?.map(card => (
        <RecommendationCard
          key={card.pk}
          card={card}
          subjectFilterLink={`${link}/?subjects=${card?.subject_title?.pk}`}
          ageFilterLink={`${link}/?ages=${card?.ages?.pk}`}
          onFavorite={onFavorite}
          onCollection={onCollection}
          onAddToShoppingCart={onAddToShoppingCart}
          taskCount={card.task_count}
          cardLink={`${link}/${card?.pk}`}
          type="PRODUCT"
        />
      ))}
    </RecommendedMaterialsCarousel>
  );
};
