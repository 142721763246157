import { googleLogout } from '@react-oauth/google';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { clearStore } from '../redux/Auth';
import { useLazyLogoutQuery } from '../services/Auth';
import { PAGES_ENUM } from '../utils/constants';
import { handleError } from '../utils/notifications';
import { useTypedDispatch } from './useTypedDispatch';

export const useLogout = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const [logout] = useLazyLogoutQuery();

  const clearUserData = () => {
    dispatch(clearStore());
    localStorage.clear();
    sessionStorage.clear();
  };

  const onLogout = async (e: React.MouseEvent<HTMLButtonElement>, isGoogleLogout?: boolean) => {
    try {
      const res = (await logout('').unwrap()) as { status: number };

      if (res?.status >= 200 && res?.status < 400) {
        if (isGoogleLogout) {
          e.preventDefault();
          googleLogout();
        }
        clearUserData();
        window.location.href = PAGES_ENUM.HOME;
      }
    } catch (error) {
      handleError(t('errors.NOT_LOGGED_OUT'));
    }
  };

  const onGoogleLogout = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await onLogout(e, true);
  };

  return { onLogout, onGoogleLogout };
};
