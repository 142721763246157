import { TSort } from '../components/SortPanel';
import { IRequestFavorite, ProductCardCurriculum, TTypePageFavorite } from './IShared';

export interface IResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  num_pages?: number;
  results: T;
}

export interface ICourse {
  pk: number;
  title: string;
  preview: string;
  price: number;
  discount_price: null | number;
  subject_title: {
    pk: number;
    name: string;
  };
  creator: {
    pk: number;
    full_name: string;
  };
  in_collection: boolean;
  is_purchased: boolean;
  is_favorite: boolean;
  ages: {
    pk: number;
    name: string;
  };
  lessons_amount: number;
  test_amount: number;
  practice_amount: number;
  preview_description?: string;

  is_product?: boolean;
  product_card_block?: ProductCardCurriculum[];
}

export interface IRequestCourses {
  filter?: TSort | undefined;
  page: number;
}

export enum COURSE_PAGE {
  MY_COURSE = 'myCourses',
  COURSES = 'courses',
}

export interface ICourseRequestFavorite extends IRequestFavorite {
  typePage: TTypePageFavorite;
}

export interface IPopularCourse {
  pk: number;
  title: string;
  preview: string;
  subject_title: {
    pk: number;
    name: string;
  };
  ages: {
    pk: number;
    name: string;
  };
  subjects: { name: string; pk: number };
  is_product: false;
  product_card_block: [];
  lessons_amount: number;
  practice_amount: number;
  preview_popular: string;
}
