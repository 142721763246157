import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TSort } from '../components/SortPanel';
import { ICategory } from '../models/IShared';
import {
  useGetAgesCategoryQuery,
  useGetLessonThemesCategoryQuery,
  useGetSubjectNamesCategoryQuery,
} from '../services/shared';
import { useSetSearchParamsNotSavedInHistory } from './useSetSearchParamsNotSavedInHistory';
import { useUrlParams } from './useUrlParams';

export const changeCategories = (category: ICategory) => (pre: ICategory[]) =>
  pre.some(c => c.id === category.id) ? pre.filter(c => c.id !== category.id) : [...pre, category];

export const useFilter = () => {
  const { setSearchParamsNotSavedInHistory } = useSetSearchParamsNotSavedInHistory();

  const { data: dataAgesCategories = { result: [] }, isFetching: isFetchingAgesCategory } =
    useGetAgesCategoryQuery('');

  const {
    data: dataSubjectNamesCategory = { result: [] },
    isFetching: isFetchingSubjectNamesCategory,
  } = useGetSubjectNamesCategoryQuery('');

  const {
    data: dataLessonThemesCategory = { result: [] },
    isFetching: isFetchingLessonThemesCategory,
  } = useGetLessonThemesCategoryQuery('');

  // Sort
  const [filter, setFilter] = useState<TSort | undefined>(undefined);
  const [selectedAgesCategory, setSelectedAgesCategory] = useState<ICategory[]>([]);
  const [selectedSubjectsCategory, setSelectedSubjectsCategory] = useState<ICategory[]>([]);
  const [selectedLessonThemesCategory, setSelectedLessonThemesCategory] = useState<ICategory[]>([]);
  const [prices, setPrices] = useState<string[]>([]);

  const [searchParams] = useSearchParams();
  const { params } = useUrlParams();

  useEffect(() => {
    const isFetchingCategories =
      isFetchingAgesCategory || isFetchingSubjectNamesCategory || isFetchingLessonThemesCategory;

    if (isFetchingCategories) return;
    const filter = searchParams.get('filter');
    const ages = searchParams.getAll('ages');
    const subjects = searchParams.getAll('subjects');
    const lesson_themes = searchParams.getAll('lesson_themes');
    const prices = searchParams.getAll('prices');

    const filteredCategoryFuncByStringArr = (categoryArr: string[]) => (item: ICategory) =>
      categoryArr.includes(String(item.id));

    if (filter) setFilter(filter as TSort);

    setPrices(prices);

    const selectedAges = dataAgesCategories.result.filter(filteredCategoryFuncByStringArr(ages));
    const selectedSubjects = dataSubjectNamesCategory.result.filter(
      filteredCategoryFuncByStringArr(subjects)
    );
    const selectedLessonThemes = dataLessonThemesCategory.result.filter(
      filteredCategoryFuncByStringArr(lesson_themes)
    );

    setSelectedAgesCategory(selectedAges);
    setSelectedSubjectsCategory(selectedSubjects);
    setSelectedLessonThemesCategory(selectedLessonThemes);
  }, [
    isFetchingAgesCategory,
    isFetchingSubjectNamesCategory,
    isFetchingLessonThemesCategory,
    searchParams,
  ]);

  const onChangeSort = (filter: TSort) => {
    const isWithOutFilter = filter === '';

    if (isWithOutFilter) {
      const paramsWithOutFilter = { ...params };

      delete paramsWithOutFilter.filter;
      setSearchParamsNotSavedInHistory({ ...paramsWithOutFilter });
    } else {
      setFilter(filter);
      setSearchParamsNotSavedInHistory({ ...params, filter: filter as string, page: '1' });
    }
  };

  const onChangePrice = (prices: string[] | null, is_free: string | null) => {
    const updatedParams = { ...params };

    if (prices !== null) {
      updatedParams.prices = prices;
    } else {
      delete updatedParams.prices;
    }

    if (is_free !== null) {
      updatedParams.is_free = is_free;
    } else {
      delete updatedParams.is_free;
    }

    updatedParams.page = '1';

    setSearchParamsNotSavedInHistory(updatedParams);

    if (prices !== null) {
      setPrices(prices);
    }
  };

  const onSelectAgesCategory = (category: ICategory) => {
    const categoryId = String(category.id);
    let agesId = searchParams.getAll('ages');
    const isHaveCategoryAgeInArray = agesId.includes(categoryId);

    agesId = isHaveCategoryAgeInArray
      ? agesId.filter(c => c !== categoryId)
      : [...agesId, categoryId];
    setSearchParamsNotSavedInHistory({ ...params, ages: agesId, page: '1' });
    setSelectedAgesCategory(changeCategories(category));
  };

  const onSelectSubjectsCategory = (category: ICategory) => {
    const categoryId = String(category.id);
    let subjects = searchParams.getAll('subjects');
    const isHaveCategoryInArray = subjects.includes(categoryId);

    subjects = isHaveCategoryInArray
      ? subjects.filter(c => c !== categoryId)
      : [...subjects, categoryId];
    setSearchParamsNotSavedInHistory({ ...params, subjects, page: '1' });
    setSelectedAgesCategory(changeCategories(category));
  };

  const onSelectLessonThemesCategory = (category: ICategory) => {
    const categoryId = String(category.id);
    let lesson_themes = searchParams.getAll('lesson_themes');
    const isHaveCategoryInArray = lesson_themes.includes(categoryId);

    lesson_themes = isHaveCategoryInArray
      ? lesson_themes.filter(c => c !== categoryId)
      : [...lesson_themes, categoryId];
    setSearchParamsNotSavedInHistory({ ...params, lesson_themes, page: '1' });
    setSelectedAgesCategory(changeCategories(category));
  };

  // Sort
  return {
    filter: {
      onSelectAgesCategory,
      onSelectSubjectsCategory,
      onSelectLessonThemesCategory,
      onChangeSort,
      onChangePrice,
    },
    filterData: {
      params,
      prices,
      filter,
      selectedAgesCategory: selectedAgesCategory.map(c => c.id),
      selectedSubjectsCategory: selectedSubjectsCategory.map(c => c.id),
      selectedLessonThemesCategory: selectedLessonThemesCategory.map(c => c.id),
      ages: dataAgesCategories.result,
      subjectNames: dataSubjectNamesCategory.result,
      lessonThemes: dataLessonThemesCategory.result,
    },
  };
};
