import cn from 'classnames';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GENDER,
  IChild,
  IReqBodyUpdateChild,
  IReqCreateChild,
} from '../../../../models/IMyProfile';
import { FormControlButtons } from '../FormControlButtons';
import { FormDatePicker } from '../FormDatePicker';
import { FormInput } from '../FormInput';
import { FormSelect } from '../FormSelect';
import styles from './ChildForm.module.scss';
import { useValidationService } from './useValidationService';

type Props = {
  title: string;
  isEditButtonHidden?: boolean;
  isCreateChild?: boolean;
  form?: IChild;
  onSave?: (values: IReqCreateChild | IReqBodyUpdateChild) => void;
  onDeleteAccountBtnClick?: () => void;
};

const createChildForm = {
  first_name: '',
  last_name: '',
  birthdate: '',
  gender: GENDER.NOT_SELECTED,
  username: '',
  password: '',
};

export const ChildForm: React.FC<Props> = ({
  title,
  isEditButtonHidden = false,
  isCreateChild,
  form,
  onSave,
  onDeleteAccountBtnClick,
}) => {
  const { t } = useTranslation();
  const [isEditionMode, setIsEditionMode] = useState(false);
  const isEditionModeDisabled = !isEditionMode && !isEditButtonHidden;
  const validationSchema = useValidationService();

  const initialValues = isCreateChild
    ? createChildForm
    : {
        ...form,
        birthdate: form?.birthdate ? moment(form?.birthdate).toDate() : new Date(),
        password: '',
      };

  const onSubmit = (values: IReqCreateChild, { resetForm }: FormikHelpers<IReqCreateChild>) => {
    const birthdate = moment(values.birthdate).format('YYYY-MM-DD');
    const reqBodyChild = { ...values, birthdate };

    onSave(reqBodyChild);
    resetForm({ values: { ...values, password: '' } });
    setIsEditionMode(false);
  };

  const handleEdition = () => {
    setIsEditionMode(true);
  };

  const handleCancellation = (formik: FormikProps<any>) => {
    formik.resetForm();
    setIsEditionMode(false);
  };

  return (
    <>
      <div className={styles.childFormTitle}>{title}</div>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {formik => (
          <Form className={styles.childForm}>
            <FormInput
              label={t('myProfile.child.nameFieldPlaceholderText')}
              name="first_name"
              type="text"
              placeholder={t('myProfile.child.nameFieldPlaceholderText')}
              disabled={isEditionModeDisabled}
            />

            <FormInput
              label={t('myProfile.child.surnameFieldPlaceholderText')}
              name="last_name"
              type="text"
              placeholder={t('myProfile.child.surnameFieldPlaceholderText')}
              disabled={isEditionModeDisabled}
            />

            <FormInput
              label={t('myProfile.child.loginFieldPlaceholderText')}
              name="username"
              type="text"
              placeholder={t('myProfile.child.loginFieldPlaceholderText')}
              disabled={isEditionModeDisabled}
            />

            <FormInput
              label={t('myProfile.child.passwordFieldPlaceholderText')}
              name="password"
              type="password"
              placeholder={t('myProfile.child.passwordFieldPlaceholderText')}
              disabled={isEditionModeDisabled}
              autoComplete="new-password"
            />

            <FormDatePicker
              label={t('myProfile.child.birthdateFieldPlaceholderText')}
              name="birthdate"
              placeholderText={t('myProfile.child.birthdateFieldPlaceholderText')}
              disabled={isEditionModeDisabled}
            />

            <FormSelect
              name="gender"
              disabled={isEditionModeDisabled}
              label={t('myProfile.child.genderOption1')}
            >
              <option value="">{t('myProfile.child.genderOption1')}</option>
              <option value={GENDER.MALE}>{t('myProfile.child.genderOption2')}</option>
              <option value={GENDER.FEMALE}>{t('myProfile.child.genderOption3')}</option>
              <option value={GENDER.NOT_SELECTED}>{t('myProfile.child.genderOption4')}</option>
            </FormSelect>

            <FormControlButtons
              isEditionMode={isEditionMode}
              isEditButtonHidden={isEditButtonHidden}
              onCancel={() => handleCancellation(formik)}
              onEdit={handleEdition}
              className={cn(styles.childFormControlButtonsCentered, {
                [styles.childFormControlButtonsAligned]:
                  typeof onDeleteAccountBtnClick === 'function',
              })}
              onDeleteAccountBtnClick={onDeleteAccountBtnClick}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
