import { t } from 'i18next';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import errorImage from '../../../assets/img/preload-delete-book.webp';
import { PAGES_ENUM } from '../../../utils/constants';
import { Button } from '../../Button';
import { MaxWidthContainer } from '../../MaxWidthContainer';
import styles from './ErrorInfo.module.scss';

interface ErrorInfoProps {
  onResetError: () => void;
}

export const ErrorInfo: FC<ErrorInfoProps> = ({ onResetError }) => {
  const navigate = useNavigate();

  const handleHomeBtnClick = () => {
    onResetError();
    navigate(PAGES_ENUM.HOME);
  };

  return (
    <div className={styles.error}>
      <MaxWidthContainer>
        <div className={styles.errorContent}>
          <div className={styles.errorImageWrapper}>
            <img
              src={errorImage}
              alt={t('globalError.imageAltText')}
              width="200"
              height="200"
            ></img>
          </div>
          <p className={styles.errorText}>{t('globalError.errorText')}</p>
          <Button onClick={handleHomeBtnClick}>{t('globalError.homeBtnText')}</Button>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
