import { useTranslation } from 'react-i18next';

import { LinkButton } from '../../../../components/LinkButton';
import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import styles from './About.module.scss';

export const About = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.about}>
      <MaxWidthContainer>
        <div className="row g-0 justify-content-end">
          <div className="col-xl-6 col-lg-7 col-md-8 col-sm-12">
            <h2 className={styles.aboutTitle}>{t('home.about.title')}</h2>
            <p className={styles.aboutDescription}>{t('home.about.descriptionFirstPart')}</p>
            <p className={styles.aboutDescription}>{t('home.about.descriptionSecondPart')}</p>
            <p className={styles.aboutDescription}>{t('home.about.descriptionThirdPart')}</p>
            <p className={styles.aboutDescription}>{t('home.about.descriptionFourthPart')}</p>
            <p className={styles.aboutDescription}>{t('home.about.descriptionFifthPart')}</p>
            <LinkButton
              className={styles.aboutBtn}
              link="https://ditymaybutnyogo.com/uk/"
              isOrange
              isTargetBlank
            >
              {t('home.about.buttonText')}
            </LinkButton>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
