import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPopularProduct } from '../../../models/IProducts';
import { PAGES_ENUM } from '../../../utils/constants';
import { PopularWrapper } from '../PopularWrapper';
import { PopularProduct } from './PopularProduct/PopularProduct';

interface IProps {
  data: IPopularProduct[];
}

export const PopularProducts: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  if (!data || data?.length === 0) return null;

  const link = PAGES_ENUM.PRODUCTS;

  return (
    <PopularWrapper
      list={data}
      title={t('popularProducts.title')}
      renderItem={(product: IPopularProduct) => (
        <PopularProduct
          key={product.pk}
          product={product}
          link={`${link}/${product?.pk}`}
        />
      )}
    />
  );
};
