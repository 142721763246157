import * as I from '../models/ICourses';
import * as IS from '../models/IShared';
import { transformResponseWithStatusCode } from '../utils/transformResponse/transformResponseWithStatusCode';
import { rootApi } from './rootApi';

export const coursesApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getCourses: builder.query<I.IResponse<I.ICourse[]>, I.IRequestCourses>({
      query: params => ({
        url: '/courses/get-filtered-courses/',
        params,
      }),
    }),

    getCoursesMinMaxPrice: builder.query<IS.IMinMaxPrice, unknown>({
      query: () => '/courses/get-courses-price-range/',
    }),

    getMyBoughtCourses: builder.query<I.IResponse<I.ICourse[]>, unknown>({
      query: params => ({ url: '/user/get-my-bought-courses/', params }),
    }),

    setCourseFavorite: builder.mutation<any, I.ICourseRequestFavorite>({
      query: ({ id, body }) => ({
        url: `/user/add-baseproduct-to-favorites/${id}`,
        body,
        method: 'POST',
      }),
      transformResponse: transformResponseWithStatusCode,
    }),

    getPopularCourses: builder.query<I.IResponse<I.IPopularCourse[]>, unknown>({
      query: () => '/courses/get-popular-courses/',
    }),

    getCoursesRecommendation: builder.query<I.IResponse<IS.RecommendationCourse[]>, unknown>({
      query: () => '/courses/get-courses-recommendations/',
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCoursesQuery,
  useLazyGetCoursesQuery,
  useGetCoursesMinMaxPriceQuery,
  useSetCourseFavoriteMutation,
  useGetMyBoughtCoursesQuery,
  useGetPopularCoursesQuery,
  useGetCoursesRecommendationQuery,
} = coursesApi;
