import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const LockIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <path
        d="M13.2106 6.69769V4.27906C13.2106 1.91959 11.291 0 8.9315 0C6.572 0 4.65244 1.91959 4.65244 4.27906H5.76872C5.76872 2.53509 7.18753 1.11628 8.9315 1.11628C10.6755 1.11628 12.0943 2.53509 12.0943 4.27906V6.69766H3.16406V16H14.6989V6.69769H13.2106ZM13.5827 14.8837H4.28034V7.81397H13.5827L13.5827 14.8837Z"
        fill="#717171"
      />
      <path
        d="M9.48981 11.7893V9.86133H8.37353V11.7893C8.25809 11.9205 8.1875 12.0919 8.1875 12.2799C8.1875 12.6903 8.52134 13.0241 8.93169 13.0241C9.34203 13.0241 9.67587 12.6903 9.67587 12.2799C9.67584 12.0919 9.60522 11.9205 9.48981 11.7893Z"
        fill="#717171"
      />
      <path d="M3.53334 3.7207H1.30078V4.83698H3.53334V3.7207Z" fill="#717171" />
      <path
        d="M2.8401 1.12083L2.05078 1.91016L3.6294 3.48878L4.41873 2.69946L2.8401 1.12083Z"
        fill="#717171"
      />
    </Icon>
  );
};
