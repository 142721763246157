import { ICourse } from './ICourses';
import { IProduct } from './IProducts';
import { RecommendationCourse, RecommendationProduct } from './IShared';

export interface IResponse<T> {
  results: T;
  result: T;
}

export enum ADD_TO_CART {
  COURSE = 1,
  PRODUCT = 2,
}

export interface IBodyCart {
  type: ADD_TO_CART.COURSE | ADD_TO_CART.PRODUCT;
}

export interface IRequestAddToCart {
  id: number;
  body: IBodyCart;
}

export interface IResponseProductsAndCourses {
  courses: RecommendationCourse[] | [];
  products: RecommendationProduct[] | [];
  total_price: number;
  discount_code: null | string;
  favorite_course: RecommendationCourse | null;
  favorite_courses_count: number;
  favorite_product: RecommendationProduct | null;
  favorite_products_count: number;
}

export interface IDiscount {
  code: string | null;
}

export interface IRequestDiscount {
  body: IDiscount;
}

export enum REMOVE_CART {
  COURSE = '1',
  PRODUCT = '2',
}

export interface IRemoveFromCartRequest {
  id: number;
  body: { type: REMOVE_CART.PRODUCT | REMOVE_CART.COURSE };
}

export interface IResCartFavorite {
  data: {
    last_favorite?: object | null;
    status: number;
  };
}

export interface IResTransformCartFavorite {
  last_favorite?: object | null;
  status: number;
}

export interface IOrderHistory {
  pk: number;
  amount: number;
  discount_amount: null | number;
  paid_at: string | null;
  paid_type: null | string;
  image: string;
  invoice_id: string | null;
  payment_system: null | string;
  payment_card: string | null;
}

export interface IOrderResponse {
  result: IOrder;
}
export interface IOrder {
  courses: ICourse[];
  products: IProduct[];
}

export interface IPaymentResponse {
  pageUrl: string;
  result: boolean;
}
