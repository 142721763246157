import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useScrollTop = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get('page');

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if ('scrollRestoration' in history) {
      // eslint-disable-next-line no-restricted-globals
      history.scrollRestoration = 'manual';
    }

    window.scrollTo({ top: 0, left: 0 });
  }, [pathname, currentPage]);
};
