import * as I from '../models/IProducts';
import * as IS from '../models/IShared';
import { transformResponseWithStatusCode } from '../utils/transformResponse/transformResponseWithStatusCode';
import { rootApi } from './rootApi';

export const productsApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<I.IResponse<I.IProduct[]>, I.IRequestProducts>({
      query: params => ({
        url: '/shop/get-filtered-products/',
        params,
      }),
    }),

    getPopularProducts: builder.query<I.IResponse<I.IPopularProduct[]>, unknown>({
      query: () => '/shop/get-popular-products/',
    }),

    getMyBoughtProducts: builder.query<I.IResponse<I.IProduct[]>, unknown>({
      query: params => ({ url: '/user/get-my-bought-products/', params }),
    }),

    getProductsMinMaxPrice: builder.query<IS.IMinMaxPrice, unknown>({
      query: () => '/shop/get-product-price-range/',
    }),

    setProductsFavorite: builder.mutation<any, I.IProductsRequestFavorite>({
      query: ({ id, body }) => ({
        url: `/user/add-baseproduct-to-favorites/${id}`,
        body,
        method: 'POST',
      }),
      transformResponse: transformResponseWithStatusCode,
    }),

    getProductsRecommendation: builder.query<I.IResponse<IS.RecommendationProduct[]>, unknown>({
      query: () => '/shop/get-products-recommendations/',
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductsQuery,
  useGetPopularProductsQuery,
  useGetMyBoughtProductsQuery,
  useGetProductsMinMaxPriceQuery,
  useSetProductsFavoriteMutation,
  useGetProductsRecommendationQuery,
} = productsApi;
