import Tippy from '@tippyjs/react';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import defaultCourseImage from '../../assets/img/course_girl.webp';
import { CheckIcon } from '../../assets/svg/CheckIcon';
import { MoreIcon } from '../../assets/svg/MoreIcon';
import { SaveIcon } from '../../assets/svg/SaveIcon';
import { ICourse } from '../../models/ICourses';
import { CollectionControlPanel } from '../../pages/Collections/CollectionControlPanel';
import { selectIsAuth } from '../../redux/Auth';
import { PAGES_ENUM } from '../../utils/constants';
import { CourseMaterialInfo } from '../CourseMaterialInfo';
import { Favorite } from '../Favorite/Favorite';
import styles from './VideoCourseCard.module.scss';

interface IProps extends ICourse {
  onCollection: () => void;
  onAddToShoppingCart?: () => void;
  onFavorite?: () => void;
  collectionControl?: boolean;
  collectionId?: number;
  isDisabled?: boolean;
}

export const VideoCourseCard: React.FC<IProps> = ({
  preview = '',
  title = '',
  ages,
  price = '',
  discount_price = '',
  is_favorite = false,
  onCollection,
  onAddToShoppingCart,
  creator,
  lessons_amount,
  practice_amount,
  in_collection = false,
  is_purchased,
  subject_title,
  onFavorite,
  collectionControl = false,
  collectionId,
  product_card_block,
  pk,
  isDisabled,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBoughtCoursesPage = location.pathname.includes(PAGES_ENUM.MY_COURSES);
  const [isControlPanelOpened, setIsControlPanelOpened] = useState(false);
  const [isFavorite, setIsFavorite] = useState(is_favorite);
  const buttonRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setIsFavorite(is_favorite);
  }, [is_favorite]);

  const onWillFavorite = () => {
    setIsFavorite(pre => !pre);
    onFavorite && onFavorite();
  };

  const onWillAddedShoppingCart = () => {
    onAddToShoppingCart();
  };

  const thanksAddedToShoppingCard = (
    <div className={styles.wrapperThanks}>
      <div className={styles.thanksText}>{t('videoCourseCard.purchasedCourse')}</div>
      <CheckIcon />
    </div>
  );

  const btnAddToShoppingCard = (
    <div className={styles.wrapperBtn}>
      <button
        className={styles.shoppingCardBtn}
        onClick={e => {
          e.stopPropagation();
          onWillAddedShoppingCart();
        }}
        aria-label={t('videoCourseCard.addToCart')}
      >
        {t('videoCourseCard.addToCart')}
      </button>
    </div>
  );

  let curriculumFooter: React.ReactNode;

  if (is_purchased || !price) {
    curriculumFooter = thanksAddedToShoppingCard;
  } else {
    curriculumFooter = btnAddToShoppingCard;
  }

  const isAuth = useSelector(selectIsAuth);

  const handleCardClick = () => {
    navigate(`/course/${pk}`);
  };

  return (
    <div className={styles.card} onClick={handleCardClick}>
      <div className={styles.cardBody}>
        <div className={styles.cardBodyImg}>
          {!!price && !is_purchased && (
            <div className={styles.wrapperPrice}>
              <div
                className={cn(styles.cardBodyOldPrice, {
                  [styles.cardPriceCrossedOut]:
                    discount_price || (discount_price === 0 && price !== 0),
                })}
              >
                {price}&#8372;
              </div>
              {(discount_price || (discount_price === 0 && price !== 0)) && (
                <div className={styles.cardBodyNewPrice}>{discount_price}&#8372;</div>
              )}
            </div>
          )}
          <img
            src={preview || defaultCourseImage}
            alt={t('videoCourseCard.coursePreviewImageAltText')}
          />
        </div>
        {product_card_block?.length > 0 && (
          <div className={styles.bookCardCurriculumInfo}>
            <ul className={styles.bookCardCurriculumList}>
              {product_card_block.map(task => {
                const { title, pk } = task;
                const num = parseInt(title) || '';

                return (
                  <li key={pk} className={styles.bookCardCurriculumItem}>
                    <strong>{num}</strong>
                    {title.slice(num.toString().length)}
                  </li>
                );
              })}
            </ul>
            {curriculumFooter}
          </div>
        )}
        {collectionControl && (
          <button
            className={styles.cardMoreBtn}
            onClick={e => {
              e.stopPropagation();
              setIsControlPanelOpened(!isControlPanelOpened);
            }}
            ref={buttonRef}
            aria-label="manage collection"
          >
            <MoreIcon />
          </button>
        )}
        {isControlPanelOpened && (
          <CollectionControlPanel
            className={styles.cardControlPanel}
            onClose={() => setIsControlPanelOpened(false)}
            forwardedRef={buttonRef}
            isCollectionItem
            collectionId={collectionId}
            itemId={pk}
          />
        )}
        <div className={styles.wrapperTitleAndOther}>
          <div className={styles.wrapperBtnAndCollectionAndFavorite}>
            <Link
              to={`${isBoughtCoursesPage ? PAGES_ENUM.MY_COURSES : PAGES_ENUM.COURSES}/?subjects=${
                subject_title?.pk
              }`}
              className={styles.btn}
              onClick={e => e.stopPropagation()}
            >
              {subject_title.name}
            </Link>

            {isAuth && (
              <div className={styles.wrapperSaveAndFavoriteIcons}>
                <Tippy
                  content={
                    in_collection ? t('common.deleteFromCollection') : t('common.addToCollection')
                  }
                >
                  <button
                    className={styles.wrapperCollection}
                    onClick={e => {
                      e.stopPropagation();
                      onCollection();
                    }}
                    aria-label={
                      in_collection ? t('common.deleteFromCollection') : t('common.addToCollection')
                    }
                    disabled={isDisabled}
                  >
                    <SaveIcon className={cn({ [styles.saveIconColored]: in_collection })} />
                  </button>
                </Tippy>
                <Tippy
                  content={
                    isFavorite ? t('common.removeFromFavorites') : t('common.addToFavorites')
                  }
                >
                  <button
                    className={styles.wrapperFavoriteIcon}
                    onClick={e => {
                      e.stopPropagation();
                      onWillFavorite();
                    }}
                    aria-label={
                      isFavorite ? t('common.removeFromFavorites') : t('common.addToFavorites')
                    }
                    disabled={isDisabled}
                  >
                    <Favorite isLike={isFavorite} />
                  </button>
                </Tippy>
              </div>
            )}
          </div>
          <Link
            to={`/course/${pk}`}
            className={styles.cardBodyTitle}
            onClick={e => e.stopPropagation()}
          >
            {title}
          </Link>

          {(!!lessons_amount || !!practice_amount) && (
            <div className={styles.courseMaterials}>
              {!!lessons_amount && (
                <CourseMaterialInfo
                  text={[
                    ` ${t('videoCourseCard.lessons')}`,
                    ` ${t('videoCourseCard.lessons2')}`,
                    ` ${t('videoCourseCard.lessons3')}`,
                  ]}
                  amount={lessons_amount}
                  textClassName={styles.courseMaterialText}
                  circleClassName={styles.courseMaterialCircle}
                />
              )}
              {!!practice_amount && (
                <CourseMaterialInfo
                  text={[
                    ` ${t('videoCourseCard.practice')}`,
                    ` ${t('videoCourseCard.practice2')}`,
                    ` ${t('videoCourseCard.practice3')}`,
                  ]}
                  amount={practice_amount}
                  textClassName={styles.courseMaterialText}
                  circleClassName={styles.courseMaterialCircle}
                />
              )}
            </div>
          )}

          <div className={styles.wrapperAuthorAndYears}>
            <div className={styles.author}>
              {t('videoCourseCard.authorTitle')} {creator?.full_name}
            </div>
            <div className={styles.wrapperYears}>
              <Link
                to={`${isBoughtCoursesPage ? PAGES_ENUM.MY_COURSES : PAGES_ENUM.COURSES}/?ages=${
                  ages?.pk
                }`}
                onClick={e => e.stopPropagation()}
              >
                {t('videoCourseCard.ageTitle')} <span className={styles.years}>{ages?.name}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
