import cn from 'classnames';
import React from 'react';

import { declineString } from '../../services/localization/localizationService';
import styles from './CourseMaterialInfo.module.scss';

type Props = {
  text?: string[];
  subjectInfo?: { pk: number; name: string }[];
  amount?: number;
  textClassName?: string;
  circleClassName?: string;
  containerClassName?: string;
};

export const CourseMaterialInfo: React.FC<Props> = ({
  amount,
  text,
  textClassName,
  circleClassName,
  containerClassName,
  subjectInfo,
}) => {
  let declinedTitle: string | null;

  if (text) {
    declinedTitle = declineString(amount, text);
  }

  return (
    <div className={cn(styles.courseSubjectInfo, containerClassName)}>
      <div className={cn(styles.courseSubjectCircle, circleClassName)}></div>
      <div className={cn(styles.courseSubjectAmount, textClassName)}>
        <span>{amount}</span> {declinedTitle ? declinedTitle : null}
        <div className={styles.courseSubjectInfoWrapper}>
          {subjectInfo?.length > 0
            ? subjectInfo.map(subject => <div key={subject.pk}>{subject.name}</div>)
            : null}
        </div>
      </div>
    </div>
  );
};
