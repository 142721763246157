import { IIcon } from './IIcon';

export const Icon = (props: IIcon) => {
  const { ariaLabel, className, width, height, viewBox, children, ...rest } = props;
  const viewBoxResult = viewBox || `0 0 ${width} ${height}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBoxResult}
      className={className}
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </svg>
  );
};
