import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const RecommendedVideoCourseIcon = (props: IIcon) => {
  return (
    <Icon width="35" height="35" fill="none" {...props}>
      <path
        d="M32.6074 2.80273L2.39258 2.80622C1.07331 2.80622 0 3.87953 0 5.1988V29.8047C0 31.124 1.07331 32.1973 2.39258 32.1973L32.6074 32.1938C33.9267 32.1938 35 31.1205 35 29.8012V5.19531C35 3.87604 33.9267 2.80273 32.6074 2.80273ZM29.5312 11.6886H32.9492V16.4728H29.5312V11.6886ZM29.5312 18.5237H32.9492V23.3079H29.5312V18.5237ZM32.9492 5.19531V9.63778H29.5312V4.85352H32.6074C32.7959 4.85352 32.9492 5.00685 32.9492 5.19531ZM5.46875 16.4763H2.05078V11.692H5.46875V16.4763ZM2.05078 18.5271H5.46875V23.3114H2.05078V18.5271ZM2.39258 4.857H5.46875V9.64127H2.05078V5.1988C2.05078 5.01033 2.20411 4.857 2.39258 4.857ZM2.05078 29.8047V25.3622H5.46875V30.1465H2.39258C2.20411 30.1465 2.05078 29.9932 2.05078 29.8047ZM7.51953 4.857H27.4805V30.1386H7.51953V4.857ZM32.6074 30.143H29.5312V25.3587H32.9492V29.8012C32.9492 29.9897 32.7959 30.143 32.6074 30.143Z"
        fill="#00394F"
      />
      <path
        d="M22.4829 16.6567L13.5962 11.1574C13.2798 10.9616 12.8823 10.9526 12.5575 11.1336C12.2326 11.3146 12.0312 11.6574 12.0312 12.0294V22.9669C12.0312 23.3379 12.2317 23.68 12.5554 23.8613C12.7112 23.9487 12.884 23.9923 13.0566 23.9923C13.2424 23.9923 13.4278 23.9419 13.5918 23.8414L22.4785 18.4033C22.7823 18.2174 22.9679 17.8873 22.9688 17.5312C22.9696 17.1751 22.7856 16.844 22.4829 16.6567ZM14.082 21.1372V13.8697L19.9868 17.5237L14.082 21.1372Z"
        fill="#00394F"
      />
    </Icon>
  );
};
