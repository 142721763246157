import React from 'react';
import { useTranslation } from 'react-i18next';

import quoteImage from '../../../../../assets/img/quote.png';
import featImage from '../../../../../assets/img/testimonial-img-1.png';
import { IHomePageComments } from '../../../../../models/IHome';
import styles from './Testimonial.module.scss';

type Props = {
  testimonial: IHomePageComments;
};

export const Testimonial: React.FC<Props> = ({ testimonial }) => {
  const { t } = useTranslation();

  if (!testimonial) return;

  return (
    <div className={styles.testimonialContent}>
      <img className={styles.testimonialImage} src={testimonial.image ?? featImage} alt="Person" />
      <div className={styles.testimonialInfo}>
        <img className={styles.testimonialQuoteImage} src={quoteImage} alt="quote" />
        <h2 className={styles.testimonialTitle}>{t('home.testimonial.title')}</h2>
        <p className={styles.testimonialText}>{testimonial.text}</p>
        <div className={styles.testimonialDivider} />
        <span className={styles.testimonialAuthor}>{testimonial.name}</span>
      </div>
    </div>
  );
};
