import { useAddToCartMutation } from '../services/cart';
import { ErrorNode } from '../utils/notifications';
import { NOTIFICATION_TYPES, useNotification } from './useNotification';

interface IUseAddToCart {
  textSuccess?: string;
}
export const useAddToCart = ({ textSuccess = 'Додано до кошика' }: IUseAddToCart) => {
  const [addToCart, resAddToCart] = useAddToCartMutation();

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resAddToCart.isSuccess,
    textSuccess,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resAddToCart.isError,
    error: resAddToCart.error as ErrorNode,
  });

  return { addToCart };
};
