import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const EyeIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <g>
        <path
          d="M15.7406 7.24602C14.8426 6.09468 13.6795 5.14234 12.3769 4.49203C11.0473 3.82819 9.6169 3.48375 8.12369 3.46569C8.08256 3.46456 7.91744 3.46456 7.87631 3.46569C6.38313 3.48378 4.95269 3.82819 3.62307 4.49203C2.32054 5.14234 1.15745 6.09465 0.259453 7.24602C-0.0864842 7.68955 -0.0864842 8.30908 0.259453 8.75261C1.15742 9.90396 2.32054 10.8563 3.62307 11.5066C4.95269 12.1705 6.3831 12.5149 7.87631 12.533C7.91744 12.5341 8.08256 12.5341 8.12369 12.533C9.61687 12.5149 11.0473 12.1705 12.3769 11.5066C13.6795 10.8563 14.8426 9.90399 15.7406 8.75261C16.0865 8.30902 16.0865 7.68955 15.7406 7.24602ZM3.91385 10.9243C2.6976 10.317 1.61142 9.42764 0.772702 8.3523C0.610608 8.14446 0.610608 7.85418 0.772702 7.64634C1.61139 6.57099 2.69757 5.68162 3.91385 5.07437C4.25929 4.90193 4.61204 4.75281 4.97132 4.62656C4.04701 5.45743 3.46467 6.66156 3.46467 7.9993C3.46467 9.33711 4.04704 10.5413 4.97144 11.3722C4.61216 11.2459 4.25932 11.0967 3.91385 10.9243ZM8.00003 11.8838C5.8581 11.8838 4.11554 10.1412 4.11554 7.99927C4.11554 5.85734 5.8581 4.11481 8.00003 4.11481C10.142 4.11481 11.8845 5.85737 11.8845 7.9993C11.8845 10.1412 10.142 11.8838 8.00003 11.8838ZM15.2274 8.35227C14.3887 9.42761 13.3025 10.317 12.0862 10.9242C11.7412 11.0965 11.3886 11.245 11.0298 11.3711C11.9535 10.5402 12.5354 9.33649 12.5354 7.99927C12.5354 6.66134 11.9529 5.45706 11.0284 4.62619C11.3878 4.75247 11.7407 4.90178 12.0862 5.07431C13.3025 5.68156 14.3887 6.57093 15.2274 7.64627C15.3895 7.85415 15.3895 8.14443 15.2274 8.35227Z"
          fill="#FFB502"
        />
        <path
          d="M8.00023 6.33789C7.08361 6.33789 6.33789 7.08361 6.33789 8.00023C6.33789 8.91685 7.08361 9.66257 8.00023 9.66257C8.91685 9.66257 9.66257 8.91685 9.66257 8.00023C9.6626 7.08361 8.91689 6.33789 8.00023 6.33789ZM8.00023 9.01173C7.44251 9.01173 6.98873 8.55801 6.98873 8.00023C6.98873 7.44248 7.44245 6.98876 8.00023 6.98876C8.55795 6.98876 9.0117 7.44248 9.0117 8.00023C9.01173 8.55801 8.55795 9.01173 8.00023 9.01173Z"
          fill="#FFB502"
        />
      </g>
      <defs>
        <clipPath id="clip0_2249_196">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
