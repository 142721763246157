import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import TreeView, { NodeId } from 'react-accessible-treeview';

import { ArrowIcon } from '../../assets/svg/ArrowIcon';
import { ICategory } from '../../models/IShared';
import styles from './Category.module.scss';

type Props = {
  data?: ICategory[];
  title?: string;
  onClose?: () => void;
  categoryData?: any;
  onSelect?: (selectedCategory: ICategory) => void;
  selectedIds?: number[];
  className?: string;
  onRedirect?: (id: number) => void;
};

function getExpandedNodes(selectedIds: number[], treeData: any[]) {
  const expandedNodes = new Set();

  selectedIds.forEach(id => {
    let currentId: number | null = id;

    while (currentId !== null) {
      // eslint-disable-next-line
      const node = treeData.find(item => item.id === currentId);

      if (node) {
        expandedNodes.add(node.id);
        currentId = node.parent;
      } else {
        currentId = null;
      }
    }
  });

  return Array.from(expandedNodes);
}

export const Category: React.FC<Props> = ({
  data = [],
  title = '',
  onClose,
  onSelect,
  selectedIds = [],
  className,
  onRedirect,
}) => {
  const [isCategoryExpanded, setIsCategoryExpanded] = useState(true);
  const [key, setKey] = useState(1);

  useEffect(() => {
    setKey(pre => pre + 1);
  }, [data]);

  const expandedNodes = getExpandedNodes(selectedIds, data);

  const onNodeSelect = (node: ICategory) => {
    if (typeof onRedirect === 'function' && node?.id) {
      onRedirect(node.id);

      return;
    }
    onSelect(node);
  };

  return (
    <div className={cn(styles.category, className)}>
      <div
        className={styles.categoryHeaderContainer}
        onClick={() => setIsCategoryExpanded(!isCategoryExpanded)}
      >
        <h3 className={styles.categoryTitle}>{title}</h3>
        <ArrowIcon
          className={cn(styles.categoryExpandArrowIcon, {
            [styles.categoryExpandArrowIconRotated]: isCategoryExpanded,
          })}
        />
      </div>
      {isCategoryExpanded && (
        <div className={styles.categoryTreeViewContainer}>
          {data.length !== 0 && (
            <TreeView
              key={key}
              selectedIds={selectedIds}
              defaultExpandedIds={expandedNodes as NodeId[]}
              onNodeSelect={s => {
                typeof onSelect === 'function' && onNodeSelect(s.element as ICategory);
              }}
              data={data}
              aria-label="category section"
              togglableSelect
              multiSelect
              className={styles.treeViewList}
              nodeRenderer={({
                element,
                getNodeProps,
                level,
                isBranch,
                isExpanded,
                handleSelect,
                handleExpand,
              }) => {
                return (
                  <div
                    {...getNodeProps({ onClick: handleExpand })}
                    className={cn(styles.treeViewListItem)}
                    style={{ paddingLeft: 20 * (level - 1) }}
                  >
                    <button
                      onClick={e => {
                        handleSelect(e);
                        e.stopPropagation();
                        if (onClose) {
                          onClose();
                        }
                      }}
                      className={cn(styles.treeViewCheckbox, {
                        [styles.treeViewCheckboxSelected]: selectedIds.includes(element.id as number),
                      })}
                      aria-label="select category"
                    />
                    <span className={styles.treeViewItemName}>{element.name}</span>
                    {isBranch && (
                      <ArrowIcon
                        className={cn(styles.treeViewListArrowIcon, {
                          [styles.treeViewListArrowIconExpanded]: isExpanded,
                        })}
                      />
                    )}
                  </div>
                );
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
