import cn from 'classnames';

import styles from './PopularWrapper.module.scss';

interface IPopularProps<T> {
  list: T[];
  title: string;
  renderItem: (item: T) => JSX.Element;
  className?: string;
}

export const PopularWrapper = <T, >({ list, title, renderItem, className }: IPopularProps<T>) => {
  if (!list || list.length === 0) return null;

  return (
    <div className={cn(styles.popularWrapper, className)}>
      <div className={styles.popularWrapperTitleContainer}>
        <h4 className={styles.popularWrapperTitle}>{title}</h4>
      </div>
      <div className={styles.popularWrapperContent}>
        <ul className={styles.popularWrapperList}>{list?.map(item => renderItem(item))}</ul>
      </div>
    </div>
  );
};
