import React, { ReactElement } from 'react';

import styles from './CollectionModalLayout.module.scss';

type Props = {
  children: React.ReactNode;
  title: string;
  icon: ReactElement<React.FC<React.SVGProps<SVGSVGElement>>>;
};

export const CollectionModalLayout: React.FC<Props> = ({ title, children, icon }) => {
  return (
    <div className={styles.collectionModal}>
      <div className={styles.collectionModalTitleContainer}>
        <h3 className={styles.collectionModalTitle}>{title}</h3>
        {icon}
      </div>
      {children}
    </div>
  );
};
