import { rootApi } from './rootApi';

export interface INewsletterReqBody {
  email: string;
}

export interface INewsletterResponse {
  result: boolean;
}

export const newsletterApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    subscribeNewsletter: builder.mutation<INewsletterResponse, INewsletterReqBody>({
      query: body => ({
        url: '/user/newsletter/',
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useSubscribeNewsletterMutation } = newsletterApi;
