import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { IReqCreateChild } from '../../../../models/IMyProfile';
import { ChildForm } from '../../../../pages/MyProfile/components/ChildForm';
import { useCreateChildMutation, useGetUserChildListQuery } from '../../../../services/myProfile';
import { handleError, handleSuccess } from '../../../../utils/notifications';
import { Modal } from '../../Modal';
import { deleteModal } from '../../ModalSlice';
import styles from './AddChildModal.module.scss';

interface AddChildModalProps {
  isEditButtonHidden: boolean;
  childrenAmount: number;
}

export const AddChildModal: React.FC<AddChildModalProps> = ({
  isEditButtonHidden,
  childrenAmount,
}) => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [skipChildrenListQuery, setSkipChildrenListQuery] = useState(true);
  const [createChild] = useCreateChildMutation();
  const { refetch: refetchUserChildList } = useGetUserChildListQuery('', {
    skip: skipChildrenListQuery,
  });

  const onCreate = async (values: IReqCreateChild) => {
    try {
      await createChild(values).unwrap();
      handleSuccess(t('myProfile.child.childAdditionSuccessText'));
      refetchUserChildList();
      dispatch(deleteModal());
    } catch (error) {
      if (error?.data?.non_field_errors?.[0] === 'CHILD_LIMIT') {
        handleError(t(`errors.CHILD_LIMIT`));
        dispatch(deleteModal());
      } else if (error?.data?.non_field_errors?.[0] === 'CHILD_USERNAME_ALREADY_EXISTS') {
        handleError(t(`errors.CHILD_USERNAME_ALREADY_EXISTS`));
      } else {
        dispatch(deleteModal());
        console.error('Child Addition Failed', error);
      }
    }
  };

  useEffect(() => {
    setSkipChildrenListQuery(false);
  }, []);

  return (
    <Modal contentClassName={styles.addModalContent} ariaLabel="Додати дитину">
      <div className={styles.addModalContainer}>
        <h3 className={styles.addChildModalTitle}>Додати дитину</h3>
        <ChildForm
          isCreateChild
          title={`${t('myProfile.child.subtitle')} ${childrenAmount + 1}`}
          onSave={onCreate}
          isEditButtonHidden={isEditButtonHidden}
        />
      </div>
    </Modal>
  );
};
