import cn from 'classnames';

import defaultImage from '../../assets/img/coming-soon-books.webp';
import styles from './EmptyContentMessage.module.scss';

type Props = {
  className?: string;
  title: string;
  description?: string;
  image?: string;
};

export const EmptyContentMessage: React.FC<Props> = ({ className, title, description, image }) => {
  return (
    <div className={cn(styles.message, className)}>
      <img
        className={styles.messageImage}
        src={image || defaultImage}
        alt="empty content"
        width="200"
        height="200"
      />
      <div className={styles.messageTextInfo}>
        <h3 className={styles.messageTitle}>{title}</h3>
        {description && <p className={styles.messageDescription}>{description}</p>}
      </div>
    </div>
  );
};
