import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Category } from '../Category';

interface Props {
  filter: { [key: string]: (arg: any, extraArg?: any) => void };
  filterData: { [key: string]: any };
}

export const Categories: FC<Props> = ({ filter, filterData }) => {
  const { t } = useTranslation();
  const categoriesConfig = [
    {
      data: filterData.subjectNames,
      selectedIds: filterData.selectedSubjectsCategory,
      onSelect: filter.onSelectSubjectsCategory,
      title: t('categories.subjects'),
      categoryKey: 'subjects',
    },
    {
      data: filterData.ages,
      selectedIds: filterData.selectedAgesCategory,
      onSelect: filter.onSelectAgesCategory,
      title: t('categories.ages'),
      categoryKey: 'ages',
    },
    {
      data: filterData.lessonThemes,
      selectedIds: filterData.selectedLessonThemesCategory,
      onSelect: filter.onSelectLessonThemesCategory,
      title: t('categories.lessonThemes'),
      categoryKey: 'lesson_themes',
    },
  ];

  return (
    <>
      {categoriesConfig.map(({ data, selectedIds, onSelect, title, categoryKey }) => (
        <Category
          key={categoryKey}
          data={data}
          selectedIds={selectedIds}
          onSelect={onSelect}
          title={title}
        />
      ))}
    </>
  );
};
