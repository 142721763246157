import React from 'react';

import { FillFavoriteIcon } from '../../assets/svg/FillFovoriteIcon';
import { FavoriteIcon } from '../../assets/svg/FovoriteIcon';
import styles from './Favorite.module.scss';

export interface IProps {
  isLike: boolean;
  onClick?: () => void;
}

export const Favorite: React.FC<IProps> = ({ isLike, onClick }) => {
  return (
    <div className={styles.wrapperFavoriteIcon} onClick={onClick}>
      {!isLike && <FavoriteIcon />}
      {isLike && <FillFavoriteIcon />}
    </div>
  );
};
