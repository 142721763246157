import './DescriptionTab.scss';

import React from 'react';

export interface IProps {
  html: string | TrustedHTML;
}

export const DescriptionTab: React.FC<IProps> = ({ html }) => {
  return <div className="product-description" dangerouslySetInnerHTML={{ __html: html }}></div>;
};
