import type { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import * as I from '../models/ICourse';
import * as IS from '../models/IShared';
import { rootApi } from './rootApi';

export const videolessonApi = rootApi
  .enhanceEndpoints({ addTagTypes: ['LessonTask'] })
  .injectEndpoints({
    endpoints: builder => ({
      getCourseDetail: builder.query<I.ICourseDetail, any>({
        query: ({ id }) => `/courses/get-lesson/${id}`,
      }),

      getRecommendedProductsByCourse: builder.query<
        IS.IResponse<IS.RecommendationProduct[]>,
        { id: string }
      >({
        query: ({ id }) => `/courses/get-opposite-course-recommendation/${id}`,
      }),

      getRecommendedCoursesByCourse: builder.query<
        IS.IResponse<IS.RecommendationCourse[]>,
        { id: string }
      >({
        query: ({ id }) => `/courses/get-course-recommendation/${id}`,
      }),

      changePassedLesson: builder.mutation<I.IResponsePassedLesson, I.IRequestPassedLesson>({
        query: body => ({ url: `/courses/create-user-passed`, method: 'POST', body }),
        transformResponse: (
          data: I.IResponsePassedLesson,
          meta: FetchBaseQueryMeta | undefined
        ) => {
          return { ...data, status: meta?.response?.status };
        },
      }),

      getTestByLessonId: builder.mutation<I.ITestResponse, { id: string; type: I.TEST_TYPE }>({
        query: ({ id, type }) => ({
          url: `/courses/get-test/${id}`,
          body: { type },
          method: 'POST',
        }),
      }),

      getCheckTest: builder.query<{ is_correct: boolean }, number>({
        query: id => `/courses/check-answer/${id}`,
      }),

      // check lesson task progress
      getLessonTaskProgress: builder.query<I.ILessonProgress, string>({
        query: id => `/courses/get-lesson-product-progress/${id}`,
        providesTags: () => ['LessonTask'],
      }),

      // start lesson task
      startLesson: builder.mutation<{ [key: string]: boolean }, string>({
        query: id => ({
          url: `/courses/start-lesson-product-progress/${id}`,
          method: 'PUT',
        }),
        invalidatesTags: () => ['LessonTask'],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetCourseDetailQuery,
  useLazyGetCourseDetailQuery,
  useGetRecommendedProductsByCourseQuery,
  useGetRecommendedCoursesByCourseQuery,
  useChangePassedLessonMutation,
  useGetTestByLessonIdMutation,
  useLazyGetCheckTestQuery,
  useGetLessonTaskProgressQuery,
  useStartLessonMutation,
} = videolessonApi;
