import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';

import * as I from '../models/ICart';
import { CartInvalidationTags } from '../models/InvalidationTags';
import * as IS from '../models/IShared';
import { rootApi } from './rootApi';

export const cartApi = rootApi
  .enhanceEndpoints({ addTagTypes: [CartInvalidationTags.CartProductsAndCourses] })
  .injectEndpoints({
    endpoints: builder => ({
      getProductsAndCourses: builder.query<I.IResponse<I.IResponseProductsAndCourses>, unknown>({
        query: () => '/shop/get-cart-products/',
        providesTags: () => [CartInvalidationTags.CartProductsAndCourses],
      }),

      addToCart: builder.mutation<any, I.IRequestAddToCart>({
        query: ({ id, body }) => ({
          url: `/shop/add-baseproduct-to-cart/${id}`,
          body,
          method: 'POST',
        }),
        invalidatesTags: () => [CartInvalidationTags.CartProductsAndCourses],
        transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
          return { ...data, status: meta?.response?.status } as {
            status: number;
            meta: { response: { status } };
          };
        },
      }),

      setCartFavorite: builder.mutation<unknown, IS.IRequestFavorite>({
        query: ({ id, body }) => ({
          url: `/user/add-baseproduct-to-favorites/${id}`,
          body,
          method: 'POST',
        }),
        transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
          return { ...data, status: meta?.response?.status } as I.IResTransformCartFavorite;
        },
      }),

      getCoursesRecommendation: builder.query<I.IResponse<IS.RecommendationCourse[]>, unknown>({
        query: () => '/shop/get-cart-courses-recommendations/',
      }),

      getProductsRecommendation: builder.query<I.IResponse<IS.RecommendationProduct[]>, unknown>({
        query: () => '/shop/get-cart-products-recommendations/',
      }),

      setDiscount: builder.mutation<any, I.IRequestDiscount>({
        query: ({ body }) => ({
          url: `/shop/use-discount/`,
          body,
          method: 'POST',
        }),
        invalidatesTags: () => [CartInvalidationTags.CartProductsAndCourses],
        transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
          return { ...data, status: meta?.response?.status } as { status: number };
        },
      }),

      removeFromCart: builder.mutation<any, I.IRemoveFromCartRequest>({
        query: ({ id, body }) => ({
          url: `/shop/remove-from-cart/${id}`,
          body,
          method: 'DELETE',
        }),
        invalidatesTags: () => [CartInvalidationTags.CartProductsAndCourses],
      }),

      getOrdersHistory: builder.query<IS.IResponse<I.IOrderHistory[]>, unknown>({
        query: params => ({ url: '/shop/orders/', params }),
      }),

      getOrder: builder.query<I.IOrderResponse, { id: string; params: unknown }>({
        query: ({ id, params }) => ({ url: `/shop/order-items/${id}`, params }),
      }),

      getPaymentUrl: builder.query<I.IPaymentResponse, unknown>({
        query: () => ({ url: `/shop/get-payment-url/` }),
        transformResponse: (data: object, meta: FetchBaseQueryMeta) => {
          return { ...data, status: meta?.response?.status } as I.IPaymentResponse & {
            status: number;
          };
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetProductsAndCoursesQuery,
  useLazyGetProductsAndCoursesQuery,
  useAddToCartMutation,
  useSetCartFavoriteMutation,
  useGetCoursesRecommendationQuery,
  useGetProductsRecommendationQuery,
  useSetDiscountMutation,
  useRemoveFromCartMutation,
  useGetOrdersHistoryQuery,
  useGetOrderQuery,
  useLazyGetPaymentUrlQuery,
} = cartApi;
