import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const CreateCollectionIcon = (props: IIcon) => {
  return (
    <Icon width="35" height="33" fill="none" {...props}>
      <g clipPath="url(#clip0_1548_87)">
        <path
          d="M34.9991 6.83625L27.7486 0L6.84372 19.7103L3.85938 29.3604L14.0943 26.5465L34.9991 6.83625ZM9.20464 20.2187L23.9999 6.26893L28.3503 10.3707L13.555 24.3205L9.20464 20.2187ZM32.0989 6.83625L29.8003 9.00347L25.45 4.9017L27.7485 2.73454L32.0989 6.83625ZM8.25411 22.057L11.6053 25.2167L6.87469 26.5172L8.25411 22.057Z"
          fill="#00394F"
        />
        <path d="M0 31.0664H34.9998V33H0V31.0664Z" fill="#00394F" />
      </g>
      <defs>
        <clipPath id="clip0_1548_87">
          <rect width="35" height="33" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
