import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Carousel } from '../../Carousel/Carousel';
import { CarouselArrowNext } from './CarouselArrowButtons/CarouselArrowNext';
import { CarouselArrowPrev } from './CarouselArrowButtons/CarouselArrowPrev';
import { IRecommendedCarousel } from './CarouselTypes';
import styles from './RecommendedMaterialsCarousel.module.scss';

export const RecommendedMaterialsCarousel: React.FC<IRecommendedCarousel> = ({
  children,
  desktopTitle,
  mobileTitle,
  icon,
  className,
  showCommonTitle,
  customTitle,
}) => {
  const { t } = useTranslation();
  const [isLastSlide, setIsLastSlide] = useState(false);
  const itemsCount = children.length;

  const settings = {
    nextArrow: <CarouselArrowNext isHidden={isLastSlide} />,
    prevArrow: <CarouselArrowPrev />,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1249,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
    beforeChange: (index: number, newIndex: number) => {
      const currentBreakpoint = settings.responsive.find(
        bp => window.innerWidth <= bp.breakpoint
      )?.settings;

      if (!currentBreakpoint) {
        setIsLastSlide(newIndex + settings.slidesToShow === itemsCount);
      } else {
        setIsLastSlide(newIndex + currentBreakpoint.slidesToShow === itemsCount);
      }
    },
  };

  return (
    <div
      className={cn(styles.recommendedMaterialsCarousel, className, {
        [styles.recommendedMaterialsCarouselWithOneItem]: itemsCount === 1,
        [styles.recommendedMaterialsCarouselWithTwoItems]: itemsCount === 2,
        [styles.recommendedMaterialsCarouselWithThreeItems]: itemsCount === 3,
      })}
    >
      {showCommonTitle && !customTitle && (
        <h3 className={styles.recommendedMaterialsCarouselCommonMobileTitle}>
          {t('recommended.commonMobileTitle')}
        </h3>
      )}
      <div className={styles.recommendedMaterialsCarouselTitleContainer}>
        {icon}
        {customTitle ? (
          <h3 className={styles.recommendedMaterialsCarouselCustomTitle}>{customTitle}</h3>
        ) : (
          <>
            <h3 className={styles.recommendedMaterialsCarouselMobileTitle}>{mobileTitle}</h3>
            <h3 className={styles.recommendedMaterialsCarouselDesktopTitle}>{desktopTitle}</h3>
          </>
        )}
      </div>
      <Carousel className={styles.recommendedMaterialsCarouselContainer} settings={settings}>
        {children}
      </Carousel>
    </div>
  );
};
