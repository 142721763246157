import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Odometer from 'react-odometerjs';

import defaultCounterImage from '../../../../assets/img/counter-icon-1.png';
import { IFeatures } from '../../../../models/IHome';
import styles from './Counter.module.scss';

interface IProps {
  counter: IFeatures;
  isVisibleCounters: boolean;
}

const defaultOdometrValue = 0;

export const SingleCounter: React.FC<IProps> = ({ counter, isVisibleCounters }) => {
  const { t } = useTranslation();
  const [odometerValue, setOdometerValue] = useState(defaultOdometrValue);

  useEffect(() => {
    if (!isVisibleCounters && !odometerValue) {
      setOdometerValue(defaultOdometrValue);

      return;
    }
    setOdometerValue(counter?.number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleCounters, odometerValue]);

  return (
    <div className={styles.singleCounter}>
      <div className={styles.counterBox}>
        <div className={styles.counterImg}>
          <img src={counter?.icon || defaultCounterImage} alt={t('home.counters.imageAltText')} />
        </div>
        <div className={styles.counterPartTxt}>
          <div className={styles.counterTxt} data-count={counter?.number}>
            <Odometer value={odometerValue} />
          </div>
          <p className={styles.counterAccomplishmentTitle}>{counter.title}</p>
        </div>
      </div>
    </div>
  );
};
