import cn from 'classnames';
import React from 'react';

import styles from './CollectionsItem.module.scss';

type Props = {
  name: string;
  is_product_in: boolean;
  isPersonal: boolean;
  onCheck: () => void;
};

export const CollectionsItem: React.FC<Props> = ({ name, is_product_in, onCheck, isPersonal }) => {
  return (
    <li className={styles.collectionsItem}>
      <button
        className={cn(styles.collectionsItemBtn, {
          [styles.collectionsItemBtnChecked]: is_product_in,
        })}
        onClick={onCheck}
        disabled={!isPersonal}
      >
        {name}
      </button>
    </li>
  );
};
