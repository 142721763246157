/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import emptyImage from '../../assets/img/coming-soon-courses.webp';
import { Banner } from '../../components/Banner';
import { Categories } from '../../components/Categories';
import { CoursesAndBooksSidebar } from '../../components/CoursesAndBooksSidebar';
import { CoursesProductsLayout } from '../../components/CoursesProductsLayout';
import { EmptyContentMessage } from '../../components/EmptyContentMessage';
import { GearButton } from '../../components/GearButton';
import { Loader } from '../../components/Loader';
import { Pagination } from '../../components/Pagination';
import { PopularCourses } from '../../components/Popular/PopularCourses';
import { PopularTags } from '../../components/PopularTags/PopularTags';
import { PriceRange } from '../../components/PriceRange';
import { SearchWidget } from '../../components/SearchWidget';
import { SidePanel } from '../../components/SidePanel';
import { SortPanel } from '../../components/SortPanel';
import { VideoCourseCard } from '../../components/VideoCourseCard';
import { useAddToCart } from '../../hooks/useAddToCart';
import { useFilter } from '../../hooks/useFilter';
import { usePagination } from '../../hooks/usePagination';
import { useSaveToCollection } from '../../hooks/useSaveToCollection';
import { favoriteShowSuccessMessage, useSetFavorite } from '../../hooks/useSetFavorite';
import { ADD_TO_CART } from '../../models/ICart';
import { COURSE_PAGE, ICourse } from '../../models/ICourses';
import { FAVORITE, IResFavoriteWithStatus } from '../../models/IShared';
import {
  useGetCoursesMinMaxPriceQuery,
  useGetCoursesQuery,
  useGetPopularCoursesQuery,
} from '../../services/courses';
import { useGetPopularTagsQuery } from '../../services/shared';
import { changeObjByFavoriteSingleArray } from '../../utils/changeObjByFavorite';
import { PAGES_ENUM } from '../../utils/constants';
import styles from './Courses.module.scss';

export const Courses = () => {
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(false);
  const { data: dataPopularTags } = useGetPopularTagsQuery('');
  const { t } = useTranslation();

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Filter start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { filter, filterData } = useFilter();
  const { data: priceRange } = useGetCoursesMinMaxPriceQuery('');
  const { data: dataPopularCourse = { results: [] } } = useGetPopularCoursesQuery('');

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Pagination start<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { page, onPageChange } = usePagination();

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Pagination end<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const {
    data: dataCourses = { results: [], count: 0, num_pages: 1 },
    isLoading,
    isFetching,
  } = useGetCoursesQuery({ ...filterData?.params, page }, { refetchOnMountOrArgChange: true });
  const [data, setData] = useState<ICourse[]>(dataCourses.results);

  useEffect(() => {
    if (isFetching) return;
    setData(dataCourses.results);
  }, [isFetching]);

  const handlePanelClose = () => {
    setIsSidePanelVisible(false);
  };

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Favorite start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { setCourseFavorite, resCourseFavorite } = useSetFavorite();
  const onFavorite = (course: ICourse) => async () => {
    const res = (await setCourseFavorite({
      id: course.pk,
      body: { type: FAVORITE.COURSE, favorite: !course.is_favorite },
      typePage: COURSE_PAGE.COURSES,
    })) as IResFavoriteWithStatus;

    if (res?.data?.status >= 200 && res?.data?.status < 400) {
      setData(pre => changeObjByFavoriteSingleArray({ pre, item: course }));
      favoriteShowSuccessMessage(!course.is_favorite, t);
    }
  };
  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Favorite end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Add to cart start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const { addToCart } = useAddToCart({});
  const onAddToShoppingCart = (course: ICourse) => () =>
    addToCart({ id: course.pk, body: { type: ADD_TO_CART.COURSE } });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> Add to cart end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const onChangeStatusCollectionInData = (obj: { [key: string]: any }) => {
    const { id, isTrue } = obj;

    setData(pre =>
      pre.map(course => (course.pk === id ? { ...course, in_collection: isTrue } : course))
    );
  };

  const { onSaveToCollection } = useSaveToCollection({ func: onChangeStatusCollectionInData });

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>> AddToCollection end <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const searchWidgetComponent = <SearchWidget />;

  const priceRangeComponent = (
    <PriceRange
      min={priceRange?.min_price}
      max={priceRange?.max_price}
      currMinPrice={filterData?.prices[0]}
      currMaxPrice={filterData?.prices[1]}
      onChangePrice={filter.onChangePrice}
    />
  );

  const popularTagsComponent = (
    <PopularTags className={styles.tags} tags={dataPopularTags?.results} />
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <Banner title={t('courses.bannerTitle')} />
      <GearButton className={styles.gearButtonHidden} onClick={() => setIsSidePanelVisible(true)} />
      <SidePanel onClose={handlePanelClose} isVisible={isSidePanelVisible}>
        {searchWidgetComponent}
        <Categories filter={filter} filterData={filterData} />
        {priceRangeComponent}
        {popularTagsComponent}
      </SidePanel>
      <CoursesProductsLayout>
        <CoursesAndBooksSidebar>
          <div className={styles.searchWidgetContainer}>{searchWidgetComponent}</div>
          <div className={styles.categoriesContainer}>
            <Categories filter={filter} filterData={filterData} />
          </div>
          <div className={styles.priceRangeContainer}>{priceRangeComponent}</div>
          <div className={styles.popularTagsContainer}>{popularTagsComponent}</div>
          <PopularCourses list={dataPopularCourse?.results} link={PAGES_ENUM.COURSES} />
        </CoursesAndBooksSidebar>
        <div className={styles.cardsContainer}>
          {data?.length > 0 ? (
            <>
              <SortPanel onSelect={filter.onChangeSort} selected={filterData.filter} />
              <div className={styles.cards}>
                {data.map((course, idx) => (
                  <VideoCourseCard
                    key={idx}
                    {...course}
                    onFavorite={onFavorite(course)}
                    onCollection={onSaveToCollection(course)}
                    onAddToShoppingCart={onAddToShoppingCart(course)}
                    isDisabled={resCourseFavorite.isLoading}
                  />
                ))}
              </div>
              <Pagination
                pageCount={dataCourses.num_pages}
                onPageChange={onPageChange}
                initialPage={page - 1}
              />
            </>
          ) : (
            <EmptyContentMessage
              title={t('courses.emptyContentMessage.title')}
              image={emptyImage}
            />
          )}
        </div>
      </CoursesProductsLayout>
    </>
  );
};
