import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../redux';
import { ModalTypes } from './types/enums/ModalTypes';

export interface IModalState {
  modalType: ModalTypes | '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalProps?: any;
}

const initialState: IModalState = {
  modalType: '',
  modalProps: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<IModalState>) => {
      const { modalType, modalProps } = action.payload;

      state.modalType = modalType;
      state.modalProps = modalProps;
    },
    deleteModal: state => {
      state.modalType = '';
      state.modalProps = null;
    },
  },
});

export const { setModal, deleteModal } = modalSlice.actions;
export const selectModal = (state: RootState) => state.modal;

export default modalSlice;
