import { useTranslation } from 'react-i18next';

import defaultPartnerImage from '../../assets/img/default-preview.png';
import { IPartners } from '../../models/IHome';
import styles from './Partner.module.scss';

interface IProps {
  list: IPartners[];
}

export const Partners: React.FC<IProps> = ({ list }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.partnersList}>
      {list.map(partner => (
        <a
          key={partner?.id}
          className={styles.partner}
          href={partner?.link ? partner.link : ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={partner.logo || defaultPartnerImage}
            className={styles.logo}
            alt={t('home.ourPartners.imageAltText')}
          />
          <div className={styles.namePartner}>{partner?.name}</div>
        </a>
      ))}
    </div>
  );
};
