import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import defaultImage from '../../../../../assets/img/videoTab.png';
import { CalendarIcon } from '../../../../../assets/svg/CalendarIcon';
import { EyeIcon } from '../../../../../assets/svg/EyeIcon';
import { ITopPost } from '../../../../../models/IHome';
import { PAGES_ENUM } from '../../../../../utils/constants';
import styles from './OurPostCard.module.scss';

type Props = {
  post: ITopPost;
};

export const OurPostCard: React.FC<Props> = ({ post }) => {
  const { t } = useTranslation();
  const { pk, main_image, author, lookups, title, preview_text, updated_at } = post;
  const formattedDate = moment.unix(Number(updated_at)).format('DD.MM.YYYY');

  return (
    <div className={styles.postCard}>
      <div className={styles.postCardDate}>
        <CalendarIcon />
        {formattedDate}
      </div>
      <div className={styles.postCardImgContainer}>
        <Link to={`${PAGES_ENUM.BLOG}/` + pk}>
          <img src={main_image || defaultImage} alt={t('home.ourBlog.previewImageAltText')} />
        </Link>
      </div>
      <div className={styles.postCardContent}>
        <div className={styles.postCardInfo}>
          <Link to={`${PAGES_ENUM.BLOG}/` + pk}>
            <h3 className={styles.postCardTitle}>{title}</h3>
          </Link>

          <p className={styles.postCardDescription}>{preview_text}</p>
        </div>
        <div className={styles.postCardFooter}>
          <div className={styles.postCardViews}>
            <EyeIcon className={styles.postCardViewIcon} />
            {lookups}
          </div>
          <div className={styles.postCardAuthorName}>Автор: {author.full_name}</div>
        </div>
      </div>
    </div>
  );
};
