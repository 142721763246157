import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const YoutubeIcon = (props: IIcon) => {
  return (
    <Icon width="17" height="12" fill="none" {...props}>
      <path
        d="M16.7062 1.93034C16.4981 1.01474 15.7586 0.339233 14.8684 0.238467C12.7603 0.000146774 10.6266 -0.00109719 8.50272 0.000146839C6.37849 -0.00109719 4.24443 0.000146774 2.13634 0.238467C1.24664 0.339233 0.507675 1.01474 0.299551 1.93034C0.0033342 3.23426 0 4.65761 0 6.00009C0 7.34257 0 8.76574 0.296217 10.0695C0.50399 10.9849 1.24313 11.6604 2.13336 11.7614C4.24127 11.9999 6.37515 12.0011 8.49939 11.9999C10.624 12.0011 12.7573 11.9999 14.8651 11.7614C15.7548 11.6606 16.4944 10.9851 16.7026 10.0695C16.9989 8.76556 17 7.34239 17 6.00009C17 4.65761 17.0025 3.23426 16.7062 1.93034ZM6.30619 8.67226C6.30619 6.76783 6.30619 4.88331 6.30619 2.9787C8.10788 3.93074 9.89764 4.87638 11.7107 5.83428C9.90326 6.78365 8.11262 7.7236 6.30619 8.67226Z"
        fill="white"
      />
    </Icon>
  );
};
