import ReactPaginate from 'react-paginate';

import { IPagination } from './interfaces/IPagination';
import { NextArrowBtn } from './NextArrowBtn';
import styles from './Pagination.module.scss';
import { PrevArrowBtn } from './PrevArrowBtn';

export const Pagination = (props: IPagination) => {
  const { pageCount, onPageChange, onClick, onNextPage, onPrevPage, initialPage } = props;

  return (
    <ReactPaginate
      key={initialPage}
      initialPage={initialPage}
      pageCount={pageCount}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      previousLabel={<PrevArrowBtn onClick={onPrevPage} />}
      breakLabel="..."
      nextLabel={<NextArrowBtn onClick={onNextPage} />}
      onPageChange={onPageChange}
      onClick={onClick}
      renderOnZeroPageCount={null}
      containerClassName={styles.paginationContainer}
      pageClassName={styles.pageClassName}
      pageLinkClassName={styles.pageLinkClassName}
      activeClassName={styles.activeClassName}
      previousClassName={styles.previousClassName}
      nextClassName={styles.nextClassName}
      previousLinkClassName={styles.previousLinkClassName}
      nextLinkClassName={styles.nextLinkClassName}
      disabledClassName={styles.disabledClassName}
    />
  );
};
