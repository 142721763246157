import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { DeleteIcon } from '../../../../../assets/svg/DeleteIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { useDeleteCollectionMutation } from '../../../../../services/collections';
import { PAGES_ENUM } from '../../../../../utils/constants';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Modal } from '../../../Modal';
import { deleteModal } from '../../../ModalSlice';
import { CollectionModalLayout } from '../CollectionModalLayout';
import styles from './DeleteCollectionModal.module.scss';

interface IProps {
  id: number;
}

export const DeleteCollectionModal: React.FC<IProps> = ({ id }) => {
  const { t } = useTranslation();
  const [deleteCollection, resDeleteCollection] = useDeleteCollectionMutation();
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectToCollectionsPage = () => navigate(PAGES_ENUM.COLLECTIONS);
  const isCollectionsPage = () => location.pathname === PAGES_ENUM.COLLECTIONS;
  const onCloseAndRedirect = () => {
    onClose();
    redirectToCollectionsPage();
  };

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onDelete = async () => {
    await deleteCollection(id);
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resDeleteCollection.isSuccess,
    textSuccess: t('collections.modals.deleteCollection.successNotificationText'),
    afterCreate: isCollectionsPage() ? onClose : onCloseAndRedirect,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resDeleteCollection.isError,
    error: resDeleteCollection.error as ErrorNode,
  });

  return (
    <Modal ariaLabel={t('collections.modals.deleteCollection.title')}>
      <CollectionModalLayout
        title={t('collections.modals.deleteCollection.title')}
        icon={<DeleteIcon className={styles.deleteCollectionIcon} />}
      >
        <p className={styles.deleteCollectionTxt}>
          {t('collections.modals.deleteCollection.description')}
        </p>
        <div className={styles.deleteCollectionButtons}>
          <Button className={styles.deleteCollectionButton} onClick={onClose} isOrange>
            {t('collections.modals.cancelBtnText')}
          </Button>
          <Button className={styles.deleteCollectionButton} onClick={onDelete}>
            {t('collections.modals.confirmBtnText')}
          </Button>
        </div>
      </CollectionModalLayout>
    </Modal>
  );
};
