import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { useSubscribeNewsletterMutation } from '../../../../../services/newsletter';
import { handleError, handleSuccess } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import styles from './MainFooterNewsletter.module.scss';
import { useValidationService } from './useValidationService';

interface IFormValues {
  email: string;
}

export const MainFooterNewsletter = () => {
  const { t } = useTranslation();
  const validationSchema = useValidationService();
  const [subscribe] = useSubscribeNewsletterMutation();

  const handleSubmit = async (values: IFormValues, { resetForm }: FormikHelpers<IFormValues>) => {
    try {
      await subscribe({ email: values.email }).unwrap();
      handleSuccess(t('footer.newsletter.successfullySubscribedToNewsText'));
      resetForm();
    } catch (error) {
      if (error?.data?.error === 'ALREADY_SUBSCRIBED_TO_NEWSLETTER') {
        handleError(t('errors.ALREADY_SUBSCRIBED_TO_NEWSLETTER'));
      } else {
        console.error('Subscription failed:', error);
      }
    }
  };

  return (
    <div>
      <p className={styles.newsletterDescription}>{t('footer.newsletter.description')}</p>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          <div className={styles.formInputWrapper}>
            <Field
              name="email"
              type="email"
              placeholder={t('footer.newsletter.inputPlaceholder')}
              className={styles.formInput}
            />
            <ErrorMessage component="div" name="email" className={styles.newsletterErrorMessage} />
          </div>
          <Button isOrange>{t('footer.newsletter.subscribeButtonText')}</Button>
        </Form>
      </Formik>
    </div>
  );
};
