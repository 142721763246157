import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { Routes } from './routes';

const App = () => {
  return (
    <ErrorBoundary>
      <Routes />
    </ErrorBoundary>
  );
};

export default App;
