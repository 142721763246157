import * as I from '../models/IShared';
import { rootApi } from './rootApi';

export const sharedApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getMenuHeader: builder.query<I.IMenuHeader[], unknown>({
      query: () => '/settings-info/get-menu-category/',
    }),

    getPopularTags: builder.query<I.IResponse<I.ITag[]>, unknown>({
      query: () => '/settings-info/get-popular-tags/',
    }),

    getAgesCategory: builder.query<I.IResponseCategories, unknown>({
      query: () => 'shop/get-ages/',
    }),

    getSearchAgesCategory: builder.query<I.ISearchAges[], unknown>({
      query: () => 'shop/get-shown-ages/',
    }),

    getSubjectNamesCategory: builder.query<I.IResponseCategories, unknown>({
      query: () => 'shop/get-subject-names/',
    }),

    getLessonThemesCategory: builder.query<I.IResponseCategories, unknown>({
      query: () => 'shop/get-lesson-themes/',
    }),

    getReviews: builder.query<I.IResponseReviews, I.IReviewRequest>({
      query: ({ id, type }) => `/user/get-reviews/${id}/${type}/`,
    }),

    getReviewsChildren: builder.query<I.IChildrenReview[], I.IReviewRequest>({
      query: ({ id, type }) => `/user/get-review-children/${id}/${type}/`,
    }),

    createdReview: builder.mutation<any, I.ICreateReviewRequest>({
      query: ({ body }) => ({
        url: `/user/create-review/`,
        body,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMenuHeaderQuery,
  useGetPopularTagsQuery,
  useGetReviewsQuery,
  useLazyGetReviewsChildrenQuery,
  useCreatedReviewMutation,
  useGetAgesCategoryQuery,
  useGetSubjectNamesCategoryQuery,
  useGetLessonThemesCategoryQuery,
  useGetSearchAgesCategoryQuery,
} = sharedApi;
