import React from 'react';

import { VideoPlayer } from '../../../components/VideoPlayer';
import styles from './ProductTabs.module.scss';

export interface IVideoTab {
  title: string;
  description: string;
  linkVideo: string;
}

export const VideoTab: React.FC<IVideoTab> = ({ title, description, linkVideo }) => {
  return (
    <div className={styles.videoTabWrapper}>
      <VideoPlayer
        url={linkVideo}
        isPlaying={false}
        defaultVideoLink="https://youtu.be/xq4qp98MyVw?si=OF8EieZ7HRlZl1Lt"
      />
      <div className={styles.videoTitle}>{title}</div>
      <div className={styles.videoDescription} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};
