export const FillFavoriteIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14.23C7.91906 14.23 7.83816 14.2085 7.76566 14.1654C7.68691 14.1187 5.81594 13.0016 3.91814 11.3184C2.79332 10.3208 1.89545 9.3314 1.24952 8.37762C0.413642 7.14342 -0.00670048 5.95627 8.07525e-05 4.84911C0.00801824 3.5608 0.456736 2.34923 1.26367 1.43755C2.08423 0.510504 3.17929 0 4.3472 0C5.84398 0 7.21244 0.862195 8.00003 2.22802C8.78763 0.862227 10.1561 0 11.6529 0C12.7562 0 13.809 0.46063 14.6172 1.29705C15.5042 2.21494 16.0082 3.51186 15.9999 4.85522C15.9931 5.96045 15.5649 7.1458 14.7272 8.37829C14.0792 9.33159 13.1826 10.3206 12.0622 11.3179C10.1713 13.0009 8.31381 14.118 8.23566 14.1647C8.16281 14.2082 8.08138 14.23 8 14.23Z"
        fill="#FFB502"
      />
    </svg>
  );
};
