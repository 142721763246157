import { useSearchParams } from 'react-router-dom';

export const useUrlParams = () => {
  const [searchParams] = useSearchParams();
  const params: { [key: string]: any } = {};

  searchParams.forEach((value, key) => {
    if (key in params) {
      params[key] = [...params[key], value];
    } else {
      params[key] = [value];
    }
  });

  return { params };
};
