import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import styles from './Search.module.scss';

interface IProps {
  onChange: (val: string) => void;
  onClick: () => void;
  value: string;
}

export const Search: React.FC<IProps> = ({ onChange, onClick, value: searchValue }) => {
  const [value, setValue] = useState(searchValue);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  let timeout: NodeJS.Timeout;

  useEffect(() => {
    setValue(searchParams.get('search') || '');
  }, [searchParams]);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setValue(value);

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange(value);
    }, 400);
  };

  return (
    <div className={styles.search} onClick={onClick}>
      <i className="icofont-search" />
      <input
        placeholder={t('header.search')}
        id="search"
        type="text"
        value={value}
        onChange={onChangeSearch}
        autoComplete="off"
      ></input>
    </div>
  );
};
