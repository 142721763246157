export enum ModalTypes {
  CREATE_ACCOUNT = 1,
  CREATE_ACCOUNT_CHILD_MODAL,
  VIDEO,
  CREATE_COLLECTION,
  DELETE_COLLECTION_MATERIAL,
  DELETE_COLLECTION,
  EDIT_COLLECTION,
  EDIT_PUBLIC_COLLECTION,
  CLOSE_COLLECTION,
  SAVE_TO_COLLECTIONS,
  ADD_CHILD,
  SHOW_CERTIFICATE_MODAL,
  SHARE_PAGE_MODAL,
  PRODUCT_PROGRESS_WARNING_MODAL,
  VIDEO_LESSON_PROGRESS_WARNING_MODAL,
  DELETE_ACCOUNT_MODAL,
}
