import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MaxWidthContainer } from '../MaxWidthContainer';
import styles from './Banner.module.scss';

interface IBreadcrumb {
  id: number;
  link: string;
  title: string;
}

type Props = {
  title: string;
  subtitle?: string;
  breadcrumbs?: IBreadcrumb[];
};

export const Banner: React.FC<Props> = ({ title, subtitle, breadcrumbs }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.banner}>
      <MaxWidthContainer>
        <div className="row g-0">
          <div className="col-12">
            <div className={styles.bannerInfo}>
              <h1 className={styles.bannerBreadcrumbTitle}>{title}</h1>
              <Link to="/" className={styles.bannerLink}>
                {t('banner.title')}
              </Link>
              {breadcrumbs?.length > 0 ? (
                <>
                  {breadcrumbs.map(breadcrumb => (
                    <div key={breadcrumb.id} className={styles.breadcrumbsContainer}>
                      <span className={styles.bannerDevider}>
                        <i className="icofont-simple-right"></i>
                      </span>
                      <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
                    </div>
                  ))}
                </>
              ) : subtitle ? (
                <>
                  <span className={styles.bannerDevider}>
                    <i className="icofont-simple-right"></i>
                  </span>
                  <span className={styles.bannerSubtitle}>{subtitle}</span>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};
