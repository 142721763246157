import 'odometer/themes/odometer-theme-default.css';

import { useTranslation } from 'react-i18next';

import { MaxWidthContainer } from '../../../../components/MaxWidthContainer';
import { useVisible } from '../../../../hooks/useVisible';
import { useGetFeaturesQuery } from '../../../../services/home';
import styles from './Counter.module.scss';
import { SingleCounter } from './SingleCounter';

export const Counters = () => {
  const { data = [] } = useGetFeaturesQuery('');
  const { t } = useTranslation();

  const { elementRef, isVisible } = useVisible();

  return (
    <>
      <div className={styles.counter} ref={elementRef}>
        <MaxWidthContainer>
          <div className="row g-0 justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div className={styles.counterHeading}>
                <h2 className={styles.counterTitle}>{t('home.counters.title')}</h2>
                <p className={styles.counterDescription}>{t('home.counters.description')}</p>
              </div>
            </div>
          </div>
          <div className="row g-0 justify-content-center">
            {data.map((counter, idx) => (
              <SingleCounter key={idx} counter={counter} isVisibleCounters={isVisible} />
            ))}
          </div>
        </MaxWidthContainer>
      </div>
    </>
  );
};
