import { COURSE_PAGE } from './ICourses';
import { PRODUCT_PAGE } from './IProducts';
export interface IResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  num_pages?: number;
  results: T;
}

export interface IMinMaxPrice {
  min_price: number;
  max_price: number;
}

// Favorite start
export enum FAVORITE {
  COURSE = 1,
  PRODUCT = 2,
}

export interface IFavorite {
  type: FAVORITE.COURSE | FAVORITE.PRODUCT;
  favorite: boolean;
}

export interface IRequestFavorite {
  id: number;
  body: IFavorite;
}

export type TTypePageFavorite =
  | COURSE_PAGE.COURSES
  | COURSE_PAGE.MY_COURSE
  | PRODUCT_PAGE.PRODUCTS
  | PRODUCT_PAGE.MY_PRODUCTS;

// Favorite end

// Header start
export interface IMenuHeader {
  pk: number;
  children: IMenuHeader[] | null;
  name: string;
  link: string | null;
  icon_class: string;
}
// Header end

export interface ITag {
  id: number;
  name: string;
  click_amount: number;
}

export interface IReview {
  pk: number;
  user: {
    pk: number;
    username: string;
    profile_name: string;
    avatar: null | string;
  };
  text: string;
  created_at: number;
  reviews?: number;
}

export interface IResponseReviews {
  count: number;
  reviews: IReview[];
}

export interface IChildrenReview {
  pk: number;
  user: {
    pk: number;
    username: string;
    profile_name: string;
    avatar: null | string;
  };
  text: string;
  created_at: number;
  parent: {
    pk: number;
    username: string;
    profile_name: string;
    avatar: null | string;
  };
}

export enum REVIEW_PAGE {
  BLOG = 'blog_blog',
  PRODUCT = 'shop_product',
  COURSE = 'courses_course',
  LESSON = 'courses_lesson',
}

export interface IReviewRequest {
  id: string;
  type: REVIEW_PAGE.BLOG | REVIEW_PAGE.COURSE | REVIEW_PAGE.PRODUCT | REVIEW_PAGE.LESSON;
}

export interface ICreateReviewRequest {
  body: {
    content_type: REVIEW_PAGE.BLOG | REVIEW_PAGE.COURSE | REVIEW_PAGE.PRODUCT | REVIEW_PAGE.LESSON;
    object_id: string;
    text: string;
    parent?: string;
  };
}

export interface ProductCardCurriculum {
  pk: number;
  title: string;
}
// Recommendation card products
export interface RecommendationProduct {
  pk: number;
  title: string;
  preview: string;
  price: number;
  discount_price: number;
  creator: {
    pk: number;
    full_name: string;
  };
  subject_title: {
    pk: number;
    name: string;
  };
  task_count: number;
  subjects: [];
  is_favorite: boolean;
  in_collection: boolean;
  ages: {
    pk: number;
    name: string;
  };
  product_card_block: ProductCardCurriculum[];
  is_purchased: boolean;
}

// Recommendation card products

// Recommendation card course
export interface RecommendationCourse {
  pk: number;
  title: string;
  preview: string;
  price: number;
  discount_price: number;
  creator: {
    pk: number;
    full_name: string;
  };
  lessons_amount: number;
  practice_amount: number;
  subject_title: {
    pk: number;
    name: string;
  };
  is_favorite: boolean;
  in_collection: boolean;
  ages: {
    pk: number;
    name: string;
  };
  product_card_block: ProductCardCurriculum[];
  is_purchased: boolean;
}

// Recommendation card course

// Search ages

export interface ISearchAges {
  id: number;
  name: string;
}

// Search ages

// Categories start

export interface IResponseCategories {
  result: ICategory[];
}

export interface ICategory {
  id: number;
  name: string;
  children: number[];
  parent: number;
}

// Categories start

export interface IResFavoriteWithStatus {
  data: {
    status: number;
  };
}

// Role

export enum USER_ROLE {
  ADMIN = 1,
  TEACHER = 2,
  STUDENT = 3,
  PARENT = 4,
}

// Role
