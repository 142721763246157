import React from 'react';

import { ArrowIcon } from '../../../../assets/svg/ArrowIcon';
import { ICarouselArrowBtn } from '../CarouselTypes';
import styles from './CarouselArrowButtons.module.scss';

export const CarouselArrowPrev: React.FC<ICarouselArrowBtn> = ({
  onClick,
  currentSlide,
  isInfinite = false,
}) => {
  const isDisabled = !isInfinite && currentSlide === 0;

  if (isDisabled) return null;

  return (
    <button
      className={styles.carouselArrowLeftBtn}
      onClick={onClick}
      aria-label="show the previous slide"
    >
      <ArrowIcon className={styles.carouselArrowLeftIcon} />
    </button>
  );
};
