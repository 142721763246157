import { FieldAttributes, useField } from 'formik';

import styles from './FormSelect.module.scss';

export const FormSelect: React.FC<FieldAttributes<any>> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const isShowError = meta.touched && meta.error;

  return (
    <label className={styles.formSelectLabel}>
      {label}
      <select className={styles.formSelect} {...field} {...props} />
      {isShowError ? <div className={styles.formSelectErrorText}>{meta.error}</div> : null}
    </label>
  );
};
