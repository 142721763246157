import cn from 'classnames';

import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { selectStudentAge, setStudentAge } from '../../../../redux/Auth';
import styles from './CreateAccountChildModal.module.scss';

export const selects = [
  { age: 4, range: '4-5 років' },
  { age: 5, range: '5-6 років' },
  { age: 6, range: '1 клас' },
];

export const Selects = () => {
  const dispatch = useTypedDispatch();
  const studentAge = useTypedSelector(selectStudentAge);

  const onSetCurrSelect = (selected: { age: number; range: string }) => () => {
    dispatch(setStudentAge(selected.age));
  };

  const clsnSelect = (selected: number) =>
    cn(styles.select, { [styles.selected]: studentAge === selected });

  return (
    <div className={styles.selects}>
      {selects.map((select, idx) => (
        <div key={idx} onClick={onSetCurrSelect(select)} className={clsnSelect(select.age)}>
          {select.range}
        </div>
      ))}
    </div>
  );
};
