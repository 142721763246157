import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const WhiteHeartIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="14" fill="none" {...props}>
      <path
        d="M8 14C7.91906 14 7.83816 13.9788 7.76566 13.9365C7.68691 13.8905 5.81594 12.7915 3.91814 11.1355C2.79332 10.154 1.89545 9.18057 1.24952 8.24221C0.413642 7.02796 -0.00670048 5.86 8.07525e-05 4.77074C0.00801824 3.50325 0.456736 2.31126 1.26367 1.41431C2.08423 0.502253 3.17929 0 4.3472 0C5.84398 0 7.21244 0.848259 8.00003 2.192C8.78763 0.84829 10.1561 0 11.6529 0C12.7562 0 13.809 0.453185 14.6172 1.27609C15.5042 2.17914 16.0082 3.4551 15.9999 4.77674C15.9931 5.86411 15.5649 7.0303 14.7272 8.24288C14.0792 9.18077 13.1826 10.1538 12.0622 11.135C10.1713 12.7908 8.31381 13.8898 8.23566 13.9358C8.16281 13.9786 8.08138 14 8 14Z"
        fill="#00394f"
      />
    </Icon>
  );
};
