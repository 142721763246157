import { TSort } from '../components/SortPanel';
import { IRequestFavorite, TTypePageFavorite } from './IShared';

export interface IResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  num_pages?: number;
  results: T;
}

export interface IProduct {
  pk: number;
  title: string;
  preview: string;
  price: number;
  discount_price: number | null;
  subject_title: {
    pk: number;
    name: string;
  };
  creator: {
    pk: number;
    full_name: string;
  };
  is_favorite: boolean;
  ages: {
    pk: number;
    name: string;
  };
  product_card_block: { pk: number; title: string }[];
  in_collection: boolean;
  is_purchased: boolean;
}

export interface IPopularProduct {
  pk: number;
  title: string;
  preview: string;
  subject_title: {
    pk: number;
    name: string;
  };
  ages: {
    pk: number;
    name: string;
  };
  subjects: { pk: number; name: string }[];
  task_count: number;
  preview_popular: string;
  product_card_block: { pk: number; title: string }[];
}

export interface ITopProduct {
  pk: number;
  title: string;
  preview: string;
  price: number;
  discount_price: number | null;
  subject_title: {
    pk: number;
    name: string;
  };
  creator: {
    pk: number;
    full_name: string;
  };
  is_favorite: boolean;
  ages: {
    pk: number;
    name: string;
  };
  product_card_block: [
    {
      pk: number;
      title: string;
    }
  ];
  subjects: [];
  task_count: number;
  is_product: boolean;
  is_purchased: boolean;
  in_collection: boolean;
}

export interface IRequestProducts {
  filter?: TSort | undefined;
  page?: number;
}

export enum PRODUCT_PAGE {
  MY_PRODUCTS = 'myProducts',
  PRODUCTS = 'products',
}

export interface IProductsRequestFavorite extends IRequestFavorite {
  typePage: TTypePageFavorite;
}

export interface IProductLessonShortContent {
  pk: 1;
  title: string;
  order: number;
  checkbox_text: null | string;
}

export interface ISingleProduct {
  pk: number;
  title: string;
  description: string | null;
  price: number;
  slug: string;
  discount_price: number;
  preview: string;
  download_archive: null | string;
  is_purchased: boolean;
  is_favorite: boolean;
  video: null | string;
  in_collection: boolean;
  full_text: string;
  lookups: number;
  download_count: number;
  video_title: null | string;
  video_description: null | string;
  ages: {
    pk: number;
    name: string;
  };
  lesson_themes: {
    pk: number;
    name: string;
  };
  attachments: [
    {
      pk: number;
      attachment_type: string;
      attachment: string;
    }
  ];
  tags: [
    {
      id: number;
      name: string;
      click_amount: number;
    }
  ];
  creator: {
    pk: number;
    full_name: string;
  };
  product_lesson_short_content: IProductLessonShortContent[];
  subjects: [
    {
      pk: number;
      name: string;
    }
  ];
  subject_title: {
    pk: number;
    name: string;
  };
  json_file: string | null;
}

export enum ProductProgressStatus {
  STARTED = 'STARTED',
  NOT_STARTED = 'NOT_STARTED',
}

export interface IProductProgress {
  data: string;
  status: ProductProgressStatus;
}
