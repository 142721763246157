import { useTranslation } from 'react-i18next';

import { DeleteIcon } from '../../../../../assets/svg/DeleteIcon';
import { NOTIFICATION_TYPES, useNotification } from '../../../../../hooks/useNotification';
import { useTypedDispatch } from '../../../../../hooks/useTypedDispatch';
import { useDeleteFromCollectionMutation } from '../../../../../services/collections';
import { ErrorNode } from '../../../../../utils/notifications';
import { Button } from '../../../../Button';
import { Modal } from '../../../Modal';
import { deleteModal } from '../../../ModalSlice';
import { CollectionModalLayout } from '../CollectionModalLayout';
import styles from './DeleteCollectionMaterialModal.module.scss';

interface IProps {
  collectionId: number;
  itemId: number;
}

export const DeleteCollectionMaterialModal: React.FC<IProps> = ({ collectionId, itemId }) => {
  const { t } = useTranslation();
  const [deleteFromCollection, resDeleteFromCollection] = useDeleteFromCollectionMutation();

  const dispatch = useTypedDispatch();

  const onClose = () => {
    dispatch(deleteModal());
  };

  const onDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    deleteFromCollection({ collectionId, itemId });
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resDeleteFromCollection.isSuccess,
    textSuccess: t('collections.modals.deleteMaterial.successNotificationText'),
    afterCreate: onClose,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resDeleteFromCollection.isError,
    error: resDeleteFromCollection.error as ErrorNode,
  });

  return (
    <Modal ariaLabel={t('collections.modals.deleteMaterial.title')}>
      <CollectionModalLayout
        title={t('collections.modals.deleteMaterial.title')}
        icon={<DeleteIcon className={styles.deleteCollectionMaterialIcon} />}
      >
        <p className={styles.deleteCollectionMaterialTxt}>
          {t('collections.modals.deleteMaterial.description')}
        </p>
        <div className={styles.deleteCollectionMaterialButtons}>
          <Button className={styles.deleteCollectionMaterialButton} onClick={onClose} isOrange>
            {t('collections.modals.cancelBtnText')}
          </Button>
          <Button className={styles.deleteCollectionMaterialButton} onClick={onDelete}>
            {t('collections.modals.confirmBtnText')}
          </Button>
        </div>
      </CollectionModalLayout>
    </Modal>
  );
};
