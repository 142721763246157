import { useState } from 'react';

import { BottomHeader } from './BottomHeader';
import { TopHeader } from './TopHeader';

export const Header = () => {
  const [isProfilePopupOpened, setIsProfilePopupOpened] = useState(false);

  return (
    <header>
      <TopHeader
        isProfilePopupOpened={isProfilePopupOpened}
        onOpenProfilePopup={setIsProfilePopupOpened}
      />
      <BottomHeader isProfilePopupOpened={isProfilePopupOpened} />
    </header>
  );
};
