import { useLocation, useNavigate } from 'react-router-dom';

function removeEmptyArrays(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key];
    }
  }
}

export const useSetSearchParamsNotSavedInHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setSearchParamsNotSavedInHistory = newParams => {
    removeEmptyArrays(newParams);

    // Создаем новый объект URLSearchParams без параметров текущего URL
    const newSearchParams = new URLSearchParams();

    // Перебираем новые параметры и добавляем их в новый объект
    for (const key in newParams) {
      const values = newParams[key];

      if (Array.isArray(values)) {
        values.forEach(value => {
          newSearchParams.append(key, value);
        });
      } else {
        newSearchParams.set(key, values);
      }
    }

    // Создаем новый URL на основе обновленных параметров
    const newUrl = `${location.pathname}?${newSearchParams.toString()}`;

    // Навигация без сохранения в истории
    navigate(newUrl, { replace: true });
  };

  return { setSearchParamsNotSavedInHistory };
};
