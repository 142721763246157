export enum GENDER {
  MALE = 1,
  FEMALE = 2,
  NOT_SELECTED = 3,
}

export interface IResUserData {
  result: {
    first_name: string | null;
    last_name: string | null;
    email: string;
    gender: GENDER;
    birthdate: string;
    phone: string;
    username: string;
    avatar: string | null;
    ref_link: string | null;
  };
}

export interface IReqUpdateUser {
  first_name?: string | null;
  last_name?: string | null;
  email?: string;
  gender?: number;
  birthdate?: string;
  phone?: string;
  username?: string;
  avatar?: File;
}

export interface IResUpdateUserData {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  gender: number;
  birthdate: string;
  phone: string;
  avatar: string;
  ref_link: string;
}

export interface IReqUpdatePassword {
  old_password: string;
  new_password: string;
}

export interface IPropsPersonalData {
  first_name: string | null;
  last_name: string | null;
  gender: number;
  birthdate: string | Date;
  username: string;
  avatar: string | null;
  ref_link?: string | null;
}

export interface IPropsPassword {
  old_password: string;
  new_password: string;
  confirmPassword: string;
}

export interface IPropsContact {
  email: string;
  phone: string;
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>Parent start<<<<<<<<<<<<<<<<<<<<<

export interface IChild {
  pk: number;
  first_name: string | null;
  last_name: string | null;
  gender: GENDER;
  birthdate: string;
  password: string;
  username: string;
}

export interface IReqBodyUpdateChild {
  first_name: string | null;
  last_name: string | null;
  email: string;
  gender: GENDER;
  birthdate: string;
  password: string;
  username: string;
}

export interface IReqUpdateChild {
  id: number;
  body: IReqBodyUpdateChild;
}

export interface IResCreateChild {
  user: {
    pk: number;
    username: string;
    full_name: string;
    avatar: string | null;
  };
}

export interface IReqCreateChild {
  first_name: string | null;
  last_name: string | null;
  gender: GENDER;
  birthdate: string;
  password: string;
  username: string;
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>Parent end<<<<<<<<<<<<<<<<<<<<<

export interface ISendDestroyRes {
  result: boolean;
  status: number;
}

export interface ISendDestroyLinkBody {
  email: string;
  role: string;
  destroy_id: number;
}
