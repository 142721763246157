import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const CloseIcon = (props: IIcon) => {
  return (
    <Icon width="16" height="16" fill="none" {...props}>
      <path
        d="M8.88393 8.00002L15.8169 1.06697C16.061 0.822881 16.061 0.427132 15.8169 0.18307C15.5729 -0.0609921 15.1771 -0.0610233 14.933 0.18307L7.99999 7.11612L1.06697 0.18307C0.822881 -0.0610233 0.427132 -0.0610233 0.18307 0.18307C-0.0609921 0.427163 -0.0610233 0.822912 0.18307 1.06697L7.11609 7.99999L0.18307 14.933C-0.0610233 15.1771 -0.0610233 15.5729 0.18307 15.8169C0.305101 15.939 0.465069 16 0.625038 16C0.785006 16 0.944944 15.939 1.06701 15.8169L7.99999 8.88393L14.933 15.8169C15.055 15.939 15.215 16 15.375 16C15.5349 16 15.6949 15.939 15.8169 15.8169C16.061 15.5729 16.061 15.1771 15.8169 14.933L8.88393 8.00002Z"
        fill="#00394f"
      />
    </Icon>
  );
};
