import cn from 'classnames';
import React from 'react';

import styles from './MainFooterCard.module.scss';

type Props = {
  title: string;
  children: React.ReactNode;
  className: string;
};

export const MainFooterCard: React.FC<Props> = ({ children, title, className }) => {
  return (
    <div className={className}>
      <div className={cn(styles.footerCard)}>
        <h4 className={styles.footerCardTitle}>{title}</h4>
        {children}
      </div>
    </div>
  );
};
