import { MaxWidthContainer } from '../MaxWidthContainer';
import styles from './Footer.module.scss';
import { MainFooter } from './MainFooter';
import { TopFooter } from './TopFooter';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <MaxWidthContainer>
        <TopFooter />
        <MainFooter />
      </MaxWidthContainer>
    </footer>
  );
};
