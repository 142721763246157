import React from 'react';

import { IMenuHeader } from '../../../../models/IShared';
import { MobileNavigationItem } from './MobileNavigationItem';
import styles from './MobileNavigationList.module.scss';

type Props = {
  list: IMenuHeader[];
  onCloseNavigation: () => void;
};

export const MobileNavigationList: React.FC<Props> = ({ list, onCloseNavigation }) => {
  return (
    <ul className={styles.list}>
      {list.map(item => {
        return (
          <MobileNavigationItem key={item.pk} item={item} onCloseNavigation={onCloseNavigation} />
        );
      })}
    </ul>
  );
};
