import { Theme, toast, ToastPosition } from 'react-toastify';

export const settingNotification = {
  position: 'top-center' as ToastPosition,
  autoClose: 3000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  theme: 'colored' as Theme,
};

export type ErrorNode = {
  data: {
    error: string;
    result?: boolean;
  };
  status: number;
};

export const handleError = (err: string) => toast.error(err);
export const handleInfo = (info: string) => toast.info(info);
export const handleSuccess = (success: string) => toast.success(success);
